import { Phone } from '../phone/phone';
import { IFacility, IInsurancePartner } from '@app/shell/models/facility/facility';
import { FacilityEmrInfo } from '@app/shell/models/facility/facility-common.model';
import { FacilitySettingType } from '@app/features/facility/model/facility-settings.model';

interface IFacilityCreate {
  address: string;
  city: string;
  corpId: number;
  id: number;
  name: string;
  phoneNumber?: Phone;
  state?: number | null;
  type?: number | null;
  zip: string;
  resourceUrl?: string | null;
  emrResourceUrl?: string;
  timezone?: number | null;
  codeBlueEnabled?: boolean;
  callMaskingEnabled?: boolean;
  teleMedicineEnabled?: boolean;
  permittedTitles: Array<string>;
  connectTriageAllowed?: boolean;
  medicareEligible: boolean;
  insurancePartners?: IInsurancePartner[];
  emrInfo?: FacilityEmrInfo;
  npi?: string;
  hospitalizationQuestionEnabled?: boolean;
  externalId?: string;
}

export class FacilityCreate implements IFacilityCreate {
  address = '';
  city = '';
  corpId!: number;
  id!: number;
  name = '';
  phoneNumber?: Phone;
  state?: number | null = null;
  type?: number | null = null;
  zip = '';
  resourceUrl?: string;
  emrResourceUrl?: string;
  timezone?: number | null = null;
  codeBlueEnabled?: boolean;
  callMaskingEnabled = true;
  teleMedicineEnabled = false;
  permittedTitles: Array<string> = [];
  connectTriageAllowed?: boolean;
  medicareEligible = false;
  insurancePartners?: IInsurancePartner[];
  emrInfo?: FacilityEmrInfo;
  notes = '';
  npi?: string;
  hospitalizationQuestionEnabled?: boolean;
  externalId?: string;

  constructor(values: Partial<IFacility> = {}) {
    this.setBatchedValues(values);
  }

  setBatchedValues(values: Partial<IFacility> = {}): void {
    this.address = values.address != undefined ? values.address : this.address;
    this.city = values.city != undefined ? values.city : this.city;
    this.corpId = values.corporation != undefined ? values.corporation.id : this.corpId;
    this.id = values.id != undefined ? values.id : this.id;
    this.name = values.name != undefined ? values.name : this.name;
    this.phoneNumber = values.phoneNumber != undefined ? new Phone(values.phoneNumber) : new Phone(this.phoneNumber);
    this.state = values.state != undefined ? values.state.id : this.state;
    this.type = values.type != undefined ? values.type.id : this.type;
    this.zip = values.zip != undefined ? values.zip : this.zip;
    this.resourceUrl = values.resourceUrl ?? this.resourceUrl;
    this.emrResourceUrl = values.emrResourceUrl ?? this.emrResourceUrl;
    this.timezone = values.timezone != undefined ? values.timezone.id : this.timezone;
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    this.codeBlueEnabled = !!values.codeBlueEnabled;
    this.callMaskingEnabled = values.callMaskingEnabled ?? this.callMaskingEnabled;
    this.teleMedicineEnabled =
      values.activeSettings?.includes(FacilitySettingType.TELEMEDICINE_ENABLED) ?? this.teleMedicineEnabled;
    this.permittedTitles = values.permittedTitles != undefined ? values.permittedTitles : [];
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    this.connectTriageAllowed = !!values.connectTriageAllowed;
    this.medicareEligible = values.medicareEligible !== undefined ? values.medicareEligible : this.medicareEligible;
    this.emrInfo = values.emrInfo;
    this.notes = values.notes ?? this.notes;
    this.npi = values.npi ?? this.npi;
    this.externalId = values.externalId?.trim() !== '' ? values.externalId?.trim() : null;
  }
}
