export type SimpleArrayInput = ReadonlyArray<string | number | boolean>;

export function arraysEquals(first: SimpleArrayInput, second: SimpleArrayInput): boolean {
  if (first.length !== second.length) {
    return false;
  }

  const s1 = new Set(first);
  if (second.some(e => !s1.has(e))) {
    return false;
  }

  const s2 = new Set(second);
  return !first.some(e => !s2.has(e));
}

export function convertEnumToValues<TEnum, TKeys extends string>(e: { [key in TKeys]: TEnum }): TEnum[] {
  const keys = Object.keys(e) as Array<TKeys>;
  return keys.map(key => e[key]);
}
