import { Injectable } from '@angular/core';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CurrentUser } from '@app/core/services/authentication/current-user';
import { ProfileInfo } from '@app/shell/models/employee/profile-info-impl';
import { newLogger } from '@app/core/services/logger/logger.service';
import { Observable } from 'rxjs';
import { AuthStorageKeys } from '@app/core/model/auth';

const routes = {
  login: () => `/auth/login`,
  logout: () => `/auth/logout`,
  getProfile: () => `/profile`,
  hasFollowUpAccess: () => `/profile/follow-up-access`,
};

const logger = newLogger('AuthenticationApiService');

export interface LoginContext {
  username: string;
  password: string;
}

export interface FollowUpAccessResponse {
  value: boolean;
}

export interface LogoutResponse {
  redirectToForeignHomeUrl: string;
}

export interface LoginResponse {
  value?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthenticationApiService {
  constructor(private httpClient: HttpClient, private authenticationService: AuthenticationService) {}

  logout(callLogoutApi = true): Observable<LogoutResponse | boolean> {
    return this.authenticationService.postLogoutAction();
  }

  /**
   * Authenticates the user.
   * @param context context The login parameters.
   * @return The user credentials.
   */
  login(context: LoginContext): Observable<CurrentUser | undefined> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/x-www-form-urlencoded'),
      withCredentials: true,
    };
    const body = new HttpParams().append('username', context.username).append('password', context.password);

    return this.httpClient.post<LoginResponse>(routes.login(), body.toString(), options).pipe(
      tap(response => {
        if (response.value != undefined) {
          localStorage.setItem(AuthStorageKeys.AUTH_TOKEN, response.value);
        }
      }),
      switchMap(() => {
        return this.authenticationService.postLoginAction();
      })
    );
  }

  samlLoginSucceeded(): Observable<CurrentUser | undefined> {
    return this.authenticationService.postLogoutAction().pipe(
      switchMap(() => {
        logger.info('Fetching profile for SAML user');
        return this.httpClient.get<ProfileInfo>(routes.getProfile()).pipe(
          switchMap(() => {
            return this.authenticationService.postLoginAction();
          })
        );
      })
    );
  }

  hasFollowUpAccess(): Observable<boolean> {
    return this.httpClient.get<FollowUpAccessResponse>(routes.hasFollowUpAccess()).pipe(map(value => value.value));
  }
}
