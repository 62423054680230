import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoComponent } from '@app/features/branding/components/logo/logo.component';
import { AngularCdkModule } from '@app/cdk/angular/angular.module';
import { SmallLogoComponent } from './components/small-logo/small-logo.component';

@NgModule({
  declarations: [LogoComponent, SmallLogoComponent],
  imports: [CommonModule, AngularCdkModule],
  exports: [LogoComponent, SmallLogoComponent],
})
export class BrandingModule {}
