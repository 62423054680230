import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PccLoginService {
  loginUrl(): string {
    return `/wsc/oauth2/pcc/login`;
  }

  logoutUrl(userId: number, corporationId: number): string {
    return `/wsc/oauth2/pcc/logout/${corporationId}`;
  }
}
