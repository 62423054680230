import { legacyDefined } from '@app/cdk/angular/utils/nullable.utils';

export class FacilityTypeModel {
  id!: number;
  name!: string;

  constructor(values: Partial<FacilityTypeModel> = {}) {
    this.setBatchedValues(values);
  }

  setBatchedValues(values: Partial<FacilityTypeModel> = {}) {
    this.id = legacyDefined(values.id) ? values.id! : this.id;
    this.name = legacyDefined(values.name) ? values.name! : this.name;
  }
}
