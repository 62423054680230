import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { fromEvent, Observable, Subscription, throwError, TimeoutError } from 'rxjs';
import { catchError, filter, finalize, first, mapTo, switchMap, tap, timeout } from 'rxjs/operators';
import { AuthenticationApiService } from '@app/core/api/authentication-api/authentication-api.service';
import { Router } from '@angular/router';
import { Injectable, Injector, OnDestroy } from '@angular/core';
import { CurrentUserService } from '@app/core/services/authentication/current-user.service';
import { ErrorInput, InfoService } from '@app/core/services/global-info-modal/info.service';
import { formatOauthKey } from '@app/modules/auth/pages/oauth-result/oauth-result.component';
import { PccLoginService } from '@app/features/emr-pcc/services/pcc-login.service';
import { EhrProvider } from '@app/features/ehr-commons/models/ehr-provider.model';
import { ContextHttpParams } from '@app/cdk/http/context-http-params';
import { EmrSystemErrorResponse } from '@app/core/interceptors/error-handler/access-denied-response.model';
import { mustDefined } from '@app/cdk/angular/utils/nullable.utils';
import { AuthStorageKeys } from '@app/core/model/auth';

function isAuthError(error?: { error?: ErrorInput } | Error): error is HttpErrorResponse {
  return error instanceof HttpErrorResponse && (error.status === 401 || error.status === 403);
}

function isEmrError(error?: ErrorInput): error is EmrSystemErrorResponse {
  return error != undefined && typeof error === 'object' && 'statusCode' in error;
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor, OnDestroy {
  private messageSub = Subscription.EMPTY;
  private storageEvent$?: Observable<StorageEvent> | null;

  constructor(private injector: Injector) {}

  ngOnDestroy(): void {
    this.messageSub.unsubscribe();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const context = req.params instanceof ContextHttpParams ? req.params.context : undefined;

    const authToken = localStorage.getItem(AuthStorageKeys.AUTH_TOKEN);
    const request =
      authToken !== null && !req.url.includes('api/auth/login')
        ? req.clone({ setHeaders: { Authorization: `Bearer ${authToken}` } })
        : req;

    return next.handle(request).pipe(
      tap(httpEvent => {
        if (httpEvent.type === HttpEventType.Sent) {
          return;
        }

        if (httpEvent instanceof HttpResponse && httpEvent.headers.has('Authorization')) {
          const token = httpEvent.headers.get('Authorization') ?? '';

          localStorage.setItem(AuthStorageKeys.AUTH_TOKEN, token);
        }
      }),
      catchError((error?: { error?: ErrorInput }) => {
        // TODO: Improve this (possibly reuse auth error object)
        if (!isAuthError(error) || req.url.includes('api/auth/login') || context?.bypassAuthError === true) {
          return throwError(error);
        }
        if (
          isEmrError(error.error) &&
          (error.error.statusCode === 401 || error.error.statusCode === 403) &&
          error.error.emrSystem === EhrProvider.PCC
        ) {
          return this.pccAuthAndTryAgain(req, next);
        }

        if (this.currentUserService.externalHomeUrl !== '' && error.status === 401) {
          void this.router.navigate(['/session-timeout'], {
            queryParams: { url: this.currentUserService.externalHomeUrl },
          });
        }
        return this.logoutUser(
          'Not authenticated (session expired)',
          !req.url.includes('api/auth/logout'),
          error,
          this.currentUserService.externalHomeUrl !== ''
        );
      })
    );
  }

  logoutUser(
    message: string,
    callLogout: boolean,
    error: unknown,
    navigateExternal: boolean
  ): Observable<HttpEvent<unknown>> {
    const router = this.router;
    const url = router.routerState.snapshot.url;

    const logout$ = this.authenticationApiService.logout(callLogout);
    return logout$.pipe(
      switchMap(() => {
        // TODO: router should not be here. It should be placed in global error handler. And we need to throw error here.
        if (!url.includes('/auth/') && !navigateExternal) {
          return router.navigate(['/auth/login'], {
            replaceUrl: true,
            queryParams: {
              return: url,
              reason: message,
            },
          });
        }
        return throwError(error);
      }),
      mapTo({} as HttpEvent<unknown>)
    );
  }

  get authenticationApiService(): AuthenticationApiService {
    return this.injector.get(AuthenticationApiService);
  }

  get router(): Router {
    return this.injector.get(Router);
  }

  get currentUserService(): CurrentUserService {
    return this.injector.get(CurrentUserService);
  }

  get infoService(): InfoService {
    return this.injector.get(InfoService);
  }

  get pccLoginService(): PccLoginService {
    return this.injector.get(PccLoginService);
  }

  private pccAuthAndTryAgain(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.currentUserService.user$.pipe(
      first(),
      switchMap(userOrNull => {
        const params = req.params;
        if (!(params instanceof ContextHttpParams)) {
          return throwError('Unauthorized in PointClickCare, please Log In PointClickCare and try again');
        }

        const corpId = params.context?.corporation;
        if (corpId == undefined) {
          return throwError('Unauthorized in PointClickCare, please Log In PointClickCare and try again');
        }
        const user = mustDefined(userOrNull);

        window.open(this.pccLoginService.loginUrl());
        if (this.storageEvent$ == undefined) {
          const key = formatOauthKey(user.id, corpId);
          this.storageEvent$ = fromEvent<StorageEvent>(window, 'storage').pipe(
            filter(
              (event: StorageEvent) => event.type === 'storage' && event.newValue != undefined && event.key === key
            ),
            first(),
            timeout(60000),
            finalize(() => {
              this.storageEvent$ = null;
            })
          );
        }
        return this.storageEvent$.pipe(
          switchMap(() => next.handle(req)),
          catchError(err => {
            if (err instanceof TimeoutError) {
              this.infoService.warn('Cannot perform request due PCC-authorization error, login time out');
            }
            return throwError(err);
          })
        );
      })
    );
  }
}
