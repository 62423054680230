export enum MenuElement {
  NURSE_DASHBOARD,
  NURSE_ENCOUNTER_CREATE_REGULAR_DIRECT_LINK,
  NURSE_ENCOUNTER_CREATE_CODE_BLUE_DIRECT_LINK,

  ENCOUNTER_CREATE_FACILITIES_PAGE,
  ENCOUNTER_CREATE_WIZARD_PAGE,

  CALL_REQUESTS_DASHBOARD, // must be common parametrized call-requests dash path
  PATIENT_MANAGER,

  DOCTOR_SCHEDULING,
  DOCTOR_SCHEDULING_BY_MONTH,
  DOCTOR_FOLLOW_UPS_DASHBOARD, // must be common parametrized call-requests dash path
  DOCTOR_ENCOUNTER_MANAGER, // must be common encounter manager path

  PROFILE_SETTINGS,

  ROUNDINGS,

  CALL_CENTER_DASHBOARD,

  FACILITY_ADMIN_FACILITY_MANAGER, // must be common facility manager path

  REPORTING, // must be common reporting path
  LIVE_METRICS,

  FOLLOW_UPS_DASHBOARD, // must be common parametrized call-requests dash path
  CCNC_ENCOUNTER_MANAGER,

  CCNC_SCHEDULING,
  CCNC_SCHEDULING_BY_MONTH,
  CCNC_REPORTING, // must be common reporting path

  CORP_EMPLOYEES_MANAGER,
  CORP_ADMIN_FACILITY_MANAGER, // must be common facility manager path

  SCHEDULING_ADMINISTRATION,
  ADMIN_ENCOUNTER_MANAGER, // must be common encounter manager path
  GLOBAL_EMPLOYEES_MANAGER,
  USERS_MANAGER,

  CORPORATION_MANAGER,
  FACILITY_MANAGER, // must be common facility manager path
  DOCTOR_MANAGER,
  GLOBAL_REPORTING, // must be common reporting path
  APPLICATION_SETTINGS,
}

export interface MenuElementDefinition {
  id: string;
  link: string[];
  title: string;
  key: MenuElement;
}

export interface MenuDescriptor {
  elements: readonly MenuElementDefinition[];
}
