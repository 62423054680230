import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable, of } from 'rxjs';
import { Changelog, ChangelogResponse, ChangelogType } from '@app/shell/models/version/changelog';
import { catchError, map } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { readApiDateTime, writeApiDateTime } from '@app/cdk/http/converters';

export const UNKNOWN: ChangelogResponse = {
  version: 'unknown',
  changelog: '',
  date: writeApiDateTime(DateTime.local(1970, 1, 1)),
};

function responseToChangelogValue(type: ChangelogType, response: ChangelogResponse): Changelog {
  return {
    type,
    date: readApiDateTime(response.date, true),
    text: response.changelog,
    version: response.version,
    versionExtended: response.versionExtended,
  };
}

const routes = {
  getChangelog: () => `/system/build-info`,
};

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  constructor(private httpClient: HttpClient) {}

  public getChangeLog(): Observable<readonly Changelog[]> {
    return forkJoin([this.getBackEndChangeLog(), this.getFrontEndChangeLog()]).pipe(
      map(([backend, frontend]) => {
        return [responseToChangelogValue('frontend', frontend), responseToChangelogValue('api-server', backend)];
      })
    );
  }

  public getFrontEndChangeLog(): Observable<ChangelogResponse> {
    return this.httpClient.get<ChangelogResponse>('/assets/changelog.json').pipe(catchError(() => of(UNKNOWN)));
  }

  public getBackEndChangeLog(): Observable<ChangelogResponse> {
    return this.httpClient.get<ChangelogResponse>(routes.getChangelog()).pipe(catchError(() => of(UNKNOWN)));
  }
}
