import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

interface Icon {
  name: string;
  url: string;
}

interface IconSet {
  name: string;
  icons: ReadonlyArray<Icon>;
}

const CONNECT_ICONS: IconSet = {
  name: 'connect',
  icons: [
    {
      name: 'call-patient',
      url: '/assets/icons/connect/call-patient.svg',
    },
    {
      name: 'mark-as-received',
      url: '/assets/icons/connect/mark-as-received.svg',
    },
    {
      name: 'telemedicine-portal',
      url: '/assets/icons/connect/telemedicine-portal.svg',
    },
    {
      name: 'escalate-to-md',
      url: '/assets/icons/connect/escalate-to-md.svg',
    },
    {
      name: 'open-ehr',
      url: '/assets/icons/connect/open-ehr.svg',
    },
    {
      name: 'change-assignment',
      url: '/assets/icons/connect/change-assignment.svg',
    },
    {
      name: 'base-vitals',
      url: '/assets/icons/connect/base-vitals.svg',
    },
    {
      name: 'ths-logo-small',
      url: '/assets/icons/connect/ths-logo-small.svg',
    },
    {
      name: 'ths-logo-extra-small',
      url: '/assets/icons/connect/ths-logo-extra-small.svg',
    },
  ],
};

const ICONS = [CONNECT_ICONS];

@NgModule({
  exports: [MatIconModule],
  imports: [CommonModule, MatIconModule],
})
export class IconsModule {
  constructor(refistry: MatIconRegistry, sanitizer: DomSanitizer) {
    ICONS.forEach(set => {
      set.icons.forEach(icon => {
        refistry.addSvgIconInNamespace(set.name, icon.name, sanitizer.bypassSecurityTrustResourceUrl(icon.url));
      });
    });
  }
}
