import { FacilityTypeModel } from '@app/modules/admin/models/facility-schedule/facility-type.model';
import {
  FacilityScheduleShiftModel,
  IFacilityShift,
} from '@app/modules/admin/models/facility-schedule/facility-schedule-shift.model';
import { MomentFormats } from '@app/shell/constants/moment-formats';
import { defaultIntervals } from '@app/modules/admin/models/facility-schedule/facility-schedule-interval.model';
import * as moment from 'moment';
import { FacilityScheduleDays } from '@app/shell/constants/facility-schedule-days';
import { FacilityScheduleShiftMode } from '@app/shell/constants/facility-schedule-shift-mode';

export interface IFacilitySchedule {
  id: number;
  type: FacilityTypeModel;
  startDate: string;
  endDate: string;
  shifts: Array<IFacilityShift>;
}

export class FacilityScheduleModel implements IFacilitySchedule {
  id: number = null;
  type: FacilityTypeModel;
  startDate: string = null;
  endDate: string = null;
  shifts: Array<FacilityScheduleShiftModel> = [];

  constructor(values: Partial<IFacilitySchedule> = {}) {
    this.id = values.id !== null && typeof values.id !== 'undefined' ? values.id : this.id;
    this.type = values.type ? new FacilityTypeModel(values.type) : new FacilityTypeModel(this.type);
    this.startDate = values.startDate ? moment.utc(values.startDate).format(MomentFormats.MOMENT_DATE) : this.startDate;
    this.endDate = values.endDate ? moment.utc(values.endDate).format(MomentFormats.MOMENT_DATE) : this.endDate;
    Object.keys(FacilityScheduleDays.mappedObject).forEach(key => {
      const defaultDay = FacilityScheduleDays.mappedObject[key];
      const valueShift =
        typeof values.shifts !== 'undefined' ? values.shifts.find(_fs => _fs.dayOfWeek.id === defaultDay.id) : null;
      const dayOfWeek = valueShift ? valueShift.dayOfWeek : defaultDay;
      const isWeekend =
        dayOfWeek.id === FacilityScheduleDays.SATURDAY.id || dayOfWeek.id === FacilityScheduleDays.SUNDAY.id;

      this.shifts.push(
        new FacilityScheduleShiftModel({
          dayOfWeek: dayOfWeek,
          type: valueShift
            ? valueShift.type
            : isWeekend
            ? FacilityScheduleShiftMode.MODE_24H.id
            : FacilityScheduleShiftMode.MODE_SELECTED_HOURS.id,
          intervals: valueShift ? valueShift.intervals : isWeekend ? [] : defaultIntervals,
        })
      );
    });
  }
}
