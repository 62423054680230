export enum ContextualPermission {
  USERS_CAN_LOGIN = 'USERS_CAN_LOGIN',
  USERS_HAVE_ACCESS_TO_REPORTS = 'USERS_HAVE_ACCESS_TO_REPORTS',
  USERS_HAVE_ACCESS_TO_LIVE_METRICS = 'USERS_HAVE_ACCESS_TO_LIVE_METRICS',
  USERS_HAVE_ACCESS_TO_ROUNDING_LIST = 'USERS_HAVE_ACCESS_TO_ROUNDING_LIST',
  USERS_HAVE_ACCESS_TO_ENCOUNTER_MANAGER = 'USERS_HAVE_ACCESS_TO_ENCOUNTER_MANAGER',
  USERS_HAVE_ACCESS_TO_RECENT_ENCOUNTERS = 'USERS_HAVE_ACCESS_TO_RECENT_ENCOUNTERS',

  USERS_CAN_VIEW_CORPORATIONS = 'USERS_CAN_VIEW_CORPORATIONS',
  USERS_CAN_VIEW_FACILITIES = 'USERS_CAN_VIEW_FACILITIES',

  USERS_CAN_CREATE_ENCOUNTER = 'USERS_CAN_CREATE_ENCOUNTER',
  USERS_CAN_CREATE_BACKDATED_ENCOUNTER = 'USERS_CAN_CREATE_BACKDATED_ENCOUNTER',
  USERS_CAN_CREATE_REGULAR_ENCOUNTER = 'USERS_CAN_CREATE_REGULAR_ENCOUNTER',
  USERS_CAN_CREATE_CODE_BLUE_ENCOUNTER = 'USERS_CAN_CREATE_CODE_BLUE_ENCOUNTER',
  USERS_CAN_CREATE_EXTERNAL_ENCOUNTER = 'USERS_CAN_CREATE_EXTERNAL_ENCOUNTER',

  USERS_CAN_REOPEN_COMPLETED_ENCOUNTER = 'USERS_CAN_REOPEN_COMPLETED_ENCOUNTER',
  USERS_CAN_EDIT_ARCHIVED_ENCOUNTER = 'USERS_CAN_EDIT_ARCHIVED_ENCOUNTER',
}

export enum ContextualPermissionMode {
  ALL = 'ALL',
  ASSIGNED_ONLY = 'ASSIGNED_ONLY',
  RELATED_ONLY = 'RELATED_ONLY',
}

// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
export type PermissionResolution<T extends ContextualPermission> =
  | {
      granted: false;
    }
  | {
      granted: true;
      modes: ReadonlySet<ContextualPermissionMode>;
    };

// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
export interface PermissionResolutionDetails<T extends ContextualPermission> {
  demoDetails: unknown;
}
