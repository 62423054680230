import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { catchError, delay, retryWhen, switchMap, timeout } from 'rxjs/operators';
import { of, Subscription, throwError } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { InfoService } from '@app/core/services/global-info-modal/info.service';
import { ajax, AjaxError } from 'rxjs/ajax';

@Component({
  templateUrl: './auto-restore.page.html',
  styleUrls: ['./auto-restore.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutoRestorePage implements OnInit, OnDestroy {
  target = '/';

  errored = false;
  message?: string;
  private subscription = Subscription.EMPTY;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private infoService: InfoService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    // use snapshot for check first return path
    this.target = decodeURIComponent(this.activatedRoute.snapshot.queryParamMap.get('location') ?? '/');
    this.message = this.activatedRoute.snapshot.queryParamMap.get('message') ?? undefined;

    this.subscription = ajax('/api/profile')
      .pipe(
        catchError(e => (typeof e === 'object' && 'status' in e ? of(e as AjaxError) : throwError(e))),
        switchMap(response => (response.status < 500 ? of('OK') : throwError('not success'))),
        retryWhen(errors => {
          return errors.pipe(delay(2000));
        }),
        timeout(1000 * 60 * 5),
        catchError((e: Error) => (e.name === 'EmptyError' ? of('OK') : throwError(e)))
      )
      .subscribe(
        () => this.returnToApp(),
        (error: Error) => {
          this.infoService.warn(error);
          this.errored = true;
          this.cd.markForCheck();
        }
      );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  returnToApp(): void {
    const target = this.target.includes('auto-restore') ? '/' : this.target;
    try {
      void this.router.navigateByUrl(target);
    } catch (e) {
      if (target !== '/') {
        void this.router.navigateByUrl('/');
      }
    }
  }
}
