import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IStyle } from '@app/shell/models/style/style';
import { IThemeColor } from '@app/shell/models/style/theme-color';
import { IStatusColor } from '@app/shell/models/style/status-color';
import { IValueResponse } from '@app/shell/models/common/value-response';

const routes = {
  style: (type: string) => `/customization/style?type=${type}`,
  uploadLogo: () => `/customization/logo`,
  getLogo: () => `/api/customization/logo`,
  themeColors: () => `/customization/theme-colors`,
  logoProperties: () => `/customization/logo/properties`,
  connectStatusColors: () => `/customization/connect-status-colors`,
  deleteLogo: () => `/customization/logo`,
};

function dataURItoBlob(dataURI: string): Blob {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const intArray = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    intArray[i] = byteString.charCodeAt(i);
  }
  return new Blob([arrayBuffer], { type: mimeString });
}

@Injectable({
  providedIn: 'root',
})
export class CustomizationService {
  constructor(private httpClient: HttpClient) {}

  private timeStamp = 0;

  public loadLogo(data: string): Observable<void> {
    const fileToUpload: File = new File([dataURItoBlob(data)], 'logo.png');
    const headers = new HttpHeaders().set('Content-Type', 'image/png');
    return this.httpClient
      .put<void>(routes.uploadLogo(), fileToUpload, { headers })
      .pipe(tap(() => (this.timeStamp = new Date().getTime())));
  }

  public getLogoUrl(): string {
    return `${routes.getLogo()}?${this.timeStamp}`;
  }

  public updateStyle(type: string, style: IStyle): Observable<IStyle> {
    return this.httpClient.put<IStyle>(routes.style(type), style);
  }

  public getStyle(type: string): Observable<IStyle> {
    return this.httpClient.get<IStyle>(routes.style(type));
  }

  public updateLogoProperties(style: Partial<IStyle>): Observable<IStyle> {
    return this.httpClient.put<IStyle>(routes.logoProperties(), style);
  }

  public deleteLogo(): Observable<void> {
    return this.httpClient
      .delete<void>(routes.deleteLogo(), {})
      .pipe(tap(() => (this.timeStamp = new Date().getTime())));
  }

  public getLogoProperties(): Observable<IStyle> {
    return this.httpClient.get<IStyle>(routes.logoProperties());
  }

  public getThemeColors(): Observable<Array<IThemeColor>> {
    return this.httpClient.get<Array<IThemeColor>>(routes.themeColors());
  }

  public updateThemeColor(themeColor: IThemeColor): Observable<IThemeColor> {
    return this.httpClient.put<IThemeColor>(routes.themeColors(), themeColor);
  }

  public getStatusColors(): Observable<IValueResponse<IStatusColor>> {
    return this.httpClient.get<IValueResponse<IStatusColor>>(routes.connectStatusColors());
  }

  public updateStatusColor(statusColor: IStatusColor): Observable<IStatusColor> {
    return this.httpClient.put<IStatusColor>(routes.connectStatusColors(), statusColor);
  }
}
