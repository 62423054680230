import { HttpParams } from '@angular/common/http';

export interface HttpContext {
  corporation?: number;
  convertErrorToViolation?: boolean;
  bypass404?: boolean;
  bypassAuthError?: boolean;
}

export interface ObjectSource {
  [param: string]: string | readonly string[];
}

/**
 * This params designed to pass some additional context to request for use it in interceptors.
 * For an example current corporation to work with PCC or bypass some errors from general processing
 */
export class ContextHttpParams extends HttpParams {
  /**
   * @param context Context parameters. Will not send to server ever
   * @param params Regular HTTP params to be passed to server
   */
  constructor(public readonly context?: HttpContext, params?: ObjectSource) {
    super({
      fromObject: params,
    });
  }
}
