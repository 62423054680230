import { State } from '../state/state';
import { Phone } from '../phone/phone';
import { Type } from './type';
import { CorporationShort } from '../corporation/corporation-short';
import { FacilityCreate } from './facility-create';
import { FacilityTimezoneModel } from '@app/shell/models/facility/facility-timezone.model';
import { FacilityEmrInfo } from '@app/shell/models/facility/facility-common.model';
import { FacilitySettingType } from '@app/features/facility/model/facility-settings.model';

export interface IInsurancePartner {
  id: number;
  facility: number;
  additionalNpi?: string;
}

export interface IFacility {
  address: string;
  city: string;
  corporation?: CorporationShort;
  id: number;
  name: string;
  phoneNumber?: Phone;
  state?: State;
  type?: Type;
  zip: string;
  resourceUrl?: string;
  emrResourceUrl?: string;
  timezone?: FacilityTimezoneModel;
  codeBlueEnabled?: boolean;
  callMaskingEnabled?: boolean;
  permittedTitles: Array<string>;
  connectTriageAllowed?: boolean;
  isBanned?: boolean;
  medicareEligible: boolean;
  emrInfo?: FacilityEmrInfo;
  insurancePartners?: IInsurancePartner[];
  notes: string;
  npi?: string;
  activeSettings: readonly FacilitySettingType[];
  externalId?: string;
}

export class Facility implements IFacility {
  address = '';
  city = '';
  corporation?: CorporationShort;
  id!: number;
  name = '';
  phoneNumber?: Phone;
  state?: State;
  type?: Type;
  zip = '';
  resourceUrl?: string;
  emrResourceUrl?: string;
  timezone?: FacilityTimezoneModel;
  codeBlueEnabled?: boolean;
  callMaskingEnabled = true;
  teleMedicineEnabled = false;
  permittedTitles: Array<string> = [];
  connectTriageAllowed?: boolean;
  isBanned?: boolean;
  medicareEligible = false;
  emrInfo?: FacilityEmrInfo;
  insurancePartners?: IInsurancePartner[];
  notes = '';
  npi?: string;
  activeSettings: readonly FacilitySettingType[] = [];
  externalId?: string;

  constructor(values: Partial<IFacility> = {}) {
    this.setBatchedValues(values);
  }

  setBatchedValues(values: Partial<IFacility> = {}): void {
    this.address = values.address != undefined ? values.address : this.address;
    this.city = values.city != undefined ? values.city : this.city;
    this.corporation =
      values.corporation != undefined
        ? new CorporationShort(values.corporation)
        : new CorporationShort(this.corporation);
    this.id = values.id != undefined ? values.id : this.id;
    this.name = values.name != undefined ? values.name : this.name;
    this.phoneNumber = values.phoneNumber != undefined ? new Phone(values.phoneNumber) : new Phone(this.phoneNumber);
    this.state = values.state != undefined ? new State(values.state) : new State(this.state);
    this.type = values.type != undefined ? new Type(values.type) : new Type(this.type);
    this.zip = values.zip != undefined ? values.zip : this.zip;
    this.resourceUrl = values.resourceUrl ?? this.resourceUrl;
    this.emrResourceUrl = values.emrResourceUrl ?? this.emrResourceUrl;
    this.timezone =
      values.timezone != undefined
        ? new FacilityTimezoneModel(values.timezone)
        : new FacilityTimezoneModel(this.timezone);
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    this.codeBlueEnabled = !!values.codeBlueEnabled;
    this.callMaskingEnabled = values.callMaskingEnabled ?? this.callMaskingEnabled;
    this.teleMedicineEnabled =
      values.activeSettings?.includes(FacilitySettingType.TELEMEDICINE_ENABLED) ?? this.teleMedicineEnabled;
    this.permittedTitles = values.permittedTitles != undefined ? values.permittedTitles : [];
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    this.connectTriageAllowed = !!values.connectTriageAllowed;
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    this.isBanned = !!values.isBanned;
    this.medicareEligible = values.medicareEligible !== undefined ? values.medicareEligible : this.medicareEligible;
    this.emrInfo = values.emrInfo;
    this.notes = values.notes ?? this.notes;
    this.npi = values.npi ?? this.npi;
    this.activeSettings = values.activeSettings ?? this.activeSettings;
    this.externalId = values.externalId?.trim() !== '' ? values.externalId?.trim() : null;
  }

  modelToCreate(): FacilityCreate {
    return new FacilityCreate(this);
  }
}
