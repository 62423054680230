<div class="session-timeout-container">
  <div class="session-timeout-box">
    <div class="container">
      <mat-card class="col-6 mx-auto">
        <mat-card-header>
          <h2>Information</h2>
        </mat-card-header>
        <mat-card-content>
          <p>Your session has been expired and you'll be redirected to the authentication provider page</p>
        </mat-card-content>
        <mat-card-actions>
          <button mat-flat-button color="primary" (click)="onOK()">OK</button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</div>
