import { Pipe, PipeTransform } from '@angular/core';
import { EhrProvider } from '@app/features/ehr-commons/models/ehr-provider.model';

export function transformEhrProvider(value: EhrProvider): string;
export function transformEhrProvider(value: EhrProvider | undefined): string | undefined;
export function transformEhrProvider(value: EhrProvider | undefined): string | undefined {
  if (value == undefined) {
    return undefined;
  }
  switch (value) {
    case EhrProvider.PCC:
      return 'PointClickCare';
    default:
      return undefined;
  }
}

@Pipe({
  name: 'ehrProvider',
})
export class EhrProviderPipe implements PipeTransform {
  transform(value: EhrProvider | undefined): string | undefined {
    return transformEhrProvider(value);
  }
}
