<div class="d-flex justify-content-between align-items-center">
  <h2 class="mb-0" mat-dialog-title>{{ data.title }}</h2>
  <button
    mat-button
    matSuffix
    mat-icon-button
    [mat-dialog-close]="true"
    aria-label="Clear"
    class="close-button"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="opacity-05 mb-3">{{ date }}</div>
<mat-dialog-content>
  <div
    class="alert alert-danger global-modal-wrapper"
    [ngClass]="{
      alert: true,
      'global-modal-wrapper': true,
      'alert-danger': data.status === 500,
      'alert-warning': data.status === 400 || data.status === 403
    }"
    role="alert"
  >
    <p *ngIf="data.status && data.showStatus">
      <span class="font-weight-bold">Status</span>: {{ this.data.status }}
    </p>
    <p *ngIf="data.statusText">
      <span class="font-weight-bold">Status Text</span>: {{ data.statusText }}
    </p>
    <p *ngIf="data.url"><span class="font-weight-bold">URL</span>: {{ data.url }}</p>
    <p *ngIf="data.errorMessage">
      <span class="font-weight-bold">Message</span>: {{ data.errorMessage }}
    </p>
    <p *ngIf="data.messageWithoutLabel">{{ data.messageWithoutLabel }}</p>
  </div>
</mat-dialog-content>
