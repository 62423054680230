import { HttpParams } from '@angular/common/http';
import * as moment from 'moment';
import { PageEvent } from '@app/cdk/table/datasource/datasource/pagination/pagination.model';
import { SortEvent } from '@app/cdk/table/datasource/datasource/sort/sort.model';
import { MomentFormats } from '@app/shell/constants/moment-formats';
import { isDevMode } from '@angular/core';
import { Filter } from '@app/cdk/table/datasource/datasource/filtration/filtration.model';
import { newLogger } from '@app/core/services/logger/logger.service';
import { DateTime } from 'luxon';

const logger = newLogger('converters');

export type ApiDateInput = Date | moment.Moment | DateTime;

export type ConverterInput = string | boolean | ApiDateInput | number | null | undefined;

export type ApiParams<T extends Filter> = Required<Record<keyof T, string | string[]>>;

interface ParametersMap {
  [p: string]: string | string[];
}

/**
 * Creates HttpParams object using given input and filtering all
 * <ul>
 *   <li> null/undefined properties
 *   <li> empty strings
 *   <li> empty arrays
 * </ul>
 * all given properties will be converted to it's string counterpart
 * @param rawObject object to use as source for building HttpParams
 * @deprecated recommended to explicit call below functions with specification of concrete type.
 */
export function toHttpParams(rawObject: { [p: string]: ConverterInput | ConverterInput[] }): HttpParams {
  let params = new HttpParams();
  Object.keys(rawObject)
    .filter(key => rawObject[key] != undefined || ('length' in rawObject && rawObject.length !== 0))
    .forEach(key => (params = params.append(key, String(rawObject[key]))));

  return params;
}

export function writeApiString(value: string[]): string[];
export function writeApiString(value: string[] | undefined): string[] | string;
export function writeApiString(value: string | undefined): string;
export function writeApiString(value: string | string[] | undefined | unknown): string | string[] {
  if (Array.isArray(value)) {
    return value.map(writeApiString);
  }
  if (value == undefined) {
    return '';
  }
  if (typeof value === 'string') {
    return value.trim();
  }
  return String(value);
}

function tryParse(value: unknown): string {
  const type = typeof value;
  if (type === 'number') {
    return (value as number).toString(10);
  }
  if (type === 'string') {
    const parsedValue = parseInt(value as string, 10);
    if (!isNaN(parsedValue)) {
      return parsedValue.toString(10);
    }
  }

  logger.warn('Not a number: ', value);
  return '';
}

export function writeApiInteger(value: readonly number[]): string[];
export function writeApiInteger(value: readonly number[] | undefined): string[] | string;
export function writeApiInteger(value: number | undefined | null): string;
export function writeApiInteger(value: number | readonly number[] | undefined | null): string | string[];
export function writeApiInteger(value: number | readonly number[] | undefined | null | unknown): string | string[] {
  if (Array.isArray(value)) {
    return value.map((num: number) => writeApiInteger(num));
  }
  if (value == undefined) {
    return '';
  }
  if (typeof value === 'number') {
    return Math.round(value).toString(10);
  }
  return tryParse(value);
}

export function writeApiDecimal(value: number[], fraction?: number): string[];
export function writeApiDecimal(value: number | undefined, fraction?: number): string;
export function writeApiDecimal(value: number | number[] | undefined | unknown, fraction = 4): string | string[] {
  if (Array.isArray(value)) {
    return value.map(writeApiDecimal);
  }
  if (value == undefined) {
    return '';
  }
  if (typeof value === 'number') {
    return value.toFixed(fraction);
  }
  if (typeof value === 'string') {
    return parseFloat(value).toFixed(fraction);
  }

  logger.warn('Unknown type', typeof value);
  return parseFloat(String(value)).toFixed(fraction);
}

export function writeApiBoolean(value: boolean[]): string[];
export function writeApiBoolean(value: boolean | undefined): string;
export function writeApiBoolean(value: boolean | boolean[] | undefined | unknown): string | string[] {
  if (Array.isArray(value)) {
    return value.map(writeApiBoolean);
  }
  if (value == undefined) {
    return '';
  }
  if (typeof value === 'boolean') {
    return value.toString();
  }
  return Boolean(value).toString();
}

export function writeApiDate(value: ApiDateInput[], json?: true): string[];
export function writeApiDate(value: ApiDateInput | undefined): string;
export function writeApiDate(value: ApiDateInput | undefined, json: true): string | undefined;
export function writeApiDate(
  value: ApiDateInput | ApiDateInput[] | undefined,
  json = false
): string | string[] | undefined {
  if (Array.isArray(value)) {
    return value.map(v => writeApiDate(v), json);
  }
  if (value == undefined) {
    return json ? undefined : '';
  }
  if (value instanceof Date || moment.isDate(value)) {
    return moment(value).format(MomentFormats.ISO_DATE);
  }
  if (DateTime.isDateTime(value)) {
    return value.startOf('second').toISODate();
  }
  if (moment.isMoment(value)) {
    return value.format(MomentFormats.ISO_DATE);
  }

  return moment(value).format(MomentFormats.ISO_DATE);
}

export function writeApiUtcDate(value: ApiDateInput | undefined, query: boolean): number | string;
export function writeApiUtcDate(
  value: ApiDateInput | ApiDateInput[] | string | undefined,
  query: boolean
): string | number | (string | number)[] | undefined {
  if (Array.isArray(value)) {
    return value.map(date => writeApiUtcDate(date, query));
  }
  if (value == undefined) {
    return undefined;
  }
  if (value instanceof Date || moment.isMoment(value)) {
    const unixTime = moment(value)
      .utc(true) // this is done to save the current time!
      .valueOf();
    return query ? writeApiInteger(unixTime) : unixTime;
  }

  logger.warn('cannot parse value', value, 'fallback to default moment parser');
  if (isDevMode()) {
    throw new Error('Invalid input format passed into `writeApiUtcDate`');
  } else {
    const momentValue = moment(value, MomentFormats.ISO_DATE, true);
    if (momentValue.isValid()) {
      return query ? writeApiInteger(momentValue.valueOf()) : momentValue.valueOf();
    }
    return query ? '' : undefined;
  }
}

export function readApiDate(input: string): moment.Moment;
export function readApiDate(input: string, modern: true): DateTime;
export function readApiDate(input: string | undefined): moment.Moment | undefined;
export function readApiDate(input: string | undefined, modern: true): DateTime | undefined;
export function readApiDate(input: string | undefined, modern = false): moment.Moment | DateTime | undefined {
  if (input == undefined || input.trim() === '') {
    return undefined;
  }

  let value = input;
  if (input.includes('•')) {
    value = input.replace('••••', '0001');
  } else if (input.includes('*')) {
    value = input.replace('****', '0001');
  }

  if (modern) {
    const iso = DateTime.fromISO(value);
    if (iso.isValid) {
      return iso;
    }

    const display = DateTime.fromFormat(value, MomentFormats.MOMENT_DATE);
    if (display.isValid) {
      return display;
    }
  } else {
    const iso = moment(value, MomentFormats.ISO_DATE, true);
    if (iso.isValid()) {
      return iso;
    }

    const display = moment(value, MomentFormats.MOMENT_DATE, true);
    if (display.isValid()) {
      return display;
    }
  }

  logger.warn('cannot parse value', input, 'fallback to default moment parser');
  if (isDevMode()) {
    throw Error(`cannot parse date ${value}`);
  }
  return moment(value);
}

export type ApiDateTimeView = string | number;

function normalizeDateTimeInput(input: ApiDateTimeView): ApiDateTimeView {
  if (typeof input === 'number') {
    return input;
  }

  if (input.includes('•')) {
    return input.replace('••••', '0001');
  } else if (input.includes('*')) {
    return input.replace('****', '0001');
  }

  return input;
}

function readDateTimeModern(input: ApiDateTimeView): DateTime | undefined {
  if (typeof input === 'number') {
    return DateTime.fromMillis(input);
  }
  const iso = DateTime.fromISO(input);
  if (iso.isValid) {
    return iso;
  }

  const display = DateTime.fromFormat(input, MomentFormats.MOMENT_DATE);
  if (display.isValid) {
    return display;
  }

  // Yep, another try to parse inconsistent date. Changelog time returned with space instead of T.
  const isoWithSpace = DateTime.fromISO(input.replace(' ', 'T'));
  if (isoWithSpace.isValid) {
    return isoWithSpace;
  }

  return undefined;
}

function readDateTimeLegacy(input: ApiDateTimeView): moment.Moment | undefined {
  if (typeof input === 'number') {
    return moment(input, true);
  }

  const iso = moment(input, moment.ISO_8601, true);
  if (iso.isValid()) {
    return iso;
  }

  const display = moment(input, MomentFormats.MOMENT_DATE, true);
  if (display.isValid()) {
    return display;
  }

  return undefined;
}

export function readApiDateTime(input: ApiDateTimeView): moment.Moment;
export function readApiDateTime(input: ApiDateTimeView, modern: true): DateTime;
export function readApiDateTime(input: ApiDateTimeView | undefined): moment.Moment | undefined;
export function readApiDateTime(input: ApiDateTimeView | undefined, modern: true): DateTime | undefined;
export function readApiDateTime(
  input: ApiDateTimeView | undefined,
  modern = false
): moment.Moment | DateTime | undefined {
  if (input == undefined || (typeof input == 'string' && input.trim() === '') || input === 0) {
    return undefined;
  }

  const value = normalizeDateTimeInput(input);

  if (modern) {
    const res = readDateTimeModern(input);
    if (res != undefined) {
      return res;
    }
  } else {
    const res = readDateTimeLegacy(input);
    if (res != undefined) {
      return res;
    }
  }

  logger.warn('cannot parse value', input, 'fallback to default moment parser');
  if (isDevMode()) {
    throw Error(`cannot parse date ${input} (${value})`);
  }
  return moment(value);
}

export function writeApiDateTime(value: ApiDateInput[]): string[];
export function writeApiDateTime(value: ApiDateInput | undefined): string;
export function writeApiDateTime(value: ApiDateInput | ApiDateInput[] | undefined): string | string[] {
  if (Array.isArray(value)) {
    return value.map(writeApiDateTime);
  }
  if (value == undefined) {
    return '';
  }
  if (value instanceof Date || moment.isDate(value)) {
    return moment(value).toISOString();
  }
  if (DateTime.isDateTime(value)) {
    return value.startOf('second').toUTC().toISO({ suppressMilliseconds: true });
  }
  if (moment.isMoment(value)) {
    return value.toISOString();
  }

  return moment(value).toISOString();
}

export function momentToLuxon(date: moment.Moment): DateTime {
  return readApiDateTime(writeApiDateTime(date), true);
}

export function writeApiPage(event: PageEvent | undefined): ParametersMap {
  if (event == undefined) {
    return {};
  }

  const offset = event.size * event.page;

  return {
    offset: writeApiInteger(offset),
    limit: writeApiInteger(event.size),
  };
}

export function writeApiSort(event: SortEvent | undefined): ParametersMap {
  if (event == undefined) {
    return {};
  }

  return {
    sortBy: writeApiString(event.field),
    order: writeApiString(event.direction),
  };
}

export function writeApiNestedInteger<T, K extends keyof T>(value: T[] | undefined, key: K): string[];
export function writeApiNestedInteger<T, K extends keyof T>(value: T | undefined, key: K): string;
export function writeApiNestedInteger<T, K extends keyof T>(value: T | T[] | undefined, key: K): string | string[] {
  if (Array.isArray(value)) {
    return value.map(v => writeApiNestedInteger(v, key));
  }
  if (value == undefined) {
    return '';
  }
  return writeApiInteger((value[key] as unknown) as number);
}
