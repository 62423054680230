import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailComponent } from '@app/features/contacts/components/email/email.component';
import { LockerModule } from '@app/cdk/locker/locker.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AngularCdkModule } from '@app/cdk/angular/angular.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [EmailComponent],
  exports: [EmailComponent],
  imports: [CommonModule, LockerModule, MatTooltipModule, AngularCdkModule, MatButtonModule, MatIconModule],
})
export class ContactsModule {}
