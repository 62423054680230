import { SafeHtml } from '@angular/platform-browser';

export interface ConfirmDialogExtendedSettings {
  title: string;
  message: string;
  yesText: string;
  noText: string;
  showCancel: boolean;
  width: string;
  disableFixedWidth: boolean;
  checkText?: SafeHtml;
}

export class ConfirmDialogSettings implements Omit<ConfirmDialogExtendedSettings, 'width' | 'disableFixedWidth'> {
  title: string;
  message: string;
  yesText: string;
  noText: string;
  showCancel: boolean;
  checkText?: SafeHtml;

  constructor(data: Partial<ConfirmDialogSettings> = {}) {
    this.title = data.title ?? 'Confirmation';
    this.message = data.message ?? '';
    this.yesText = data.yesText ?? 'Yes';
    this.noText = data.noText ?? 'No';
    this.showCancel = data.showCancel ?? true;
    this.checkText = data.checkText;
  }
}
