import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FxSpaceComponent } from './fx-space/fx-space.component';
import { TextIfElsePipe } from './pipes/text-if-else.pipe';
import { SwipeLinksContainerComponent } from './swipe-links-container/swipe-links-container.component';
import { BackgroundButtonDirective } from './background-button/background-button.directive';
import { PlainToolbarComponent } from './plain-toolbar/plain-toolbar.component';
import { PlainToolbarGroupComponent } from './plain-toolbar/plain-toolbar-group/plain-toolbar-group.component';
import { PlainToolbarGroupItemGrowDirective } from './plain-toolbar/plain-toolbar-group-item-grow.directive';

@NgModule({
  declarations: [
    FxSpaceComponent,
    TextIfElsePipe,
    SwipeLinksContainerComponent,
    BackgroundButtonDirective,
    PlainToolbarComponent,
    PlainToolbarGroupComponent,
    PlainToolbarGroupItemGrowDirective,
  ],
  exports: [
    FxSpaceComponent,
    TextIfElsePipe,
    SwipeLinksContainerComponent,
    BackgroundButtonDirective,
    PlainToolbarComponent,
    PlainToolbarGroupComponent,
    PlainToolbarGroupItemGrowDirective,
  ],
  imports: [CommonModule],
})
export class LayoutModule {}
