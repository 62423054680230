import { Inject, Injectable, InjectionToken, Optional, Provider } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

class InnerInterceptorHandler implements HttpHandler {
  constructor(private next: HttpHandler, private interceptor: HttpInterceptor) {}

  handle(request: HttpRequest<unknown>): Observable<HttpEvent<unknown>> {
    return this.interceptor.intercept(request, this.next);
  }
}

/**
 * Allows to override default dynamic interceptors that can be disabled with the HttpService extension.
 * Except for very specific needs, you should better configure these interceptors directly in the constructor below
 * for better readability.
 *
 * For static interceptors that should always be enabled (like ApiPrefixInterceptor), use the standard
 * HTTP_INTERCEPTORS token.
 */
export const HTTP_DYNAMIC_INTERCEPTORS = new InjectionToken<HttpInterceptor>('HTTP_DYNAMIC_INTERCEPTORS');

function createDelegatingHandler(interceptors: HttpInterceptor[], rootHandler: HttpHandler): HttpHandler {
  return interceptors.reduceRight((next, interceptor) => new InnerInterceptorHandler(next, interceptor), rootHandler);
}

/**
 * Extends HttpClient with per request configuration using dynamic interceptors.
 */
@Injectable()
export class HttpService extends HttpClient {
  constructor(
    httpHandler: HttpHandler,
    @Optional() @Inject(HTTP_DYNAMIC_INTERCEPTORS) interceptors: HttpInterceptor[] = []
  ) {
    super(createDelegatingHandler(interceptors, httpHandler));
  }
}

export const CUSTOM_HTTP_CLIENT_PROVIDER: Provider = {
  provide: HttpClient,
  useClass: HttpService,
};
