import { Injectable } from '@angular/core';
import { MenuElement, MenuElementDefinition } from '@app/core/services/menu/menu-descriptor.model';

const DEFINITIONS: Record<MenuElement, Omit<MenuElementDefinition, 'key'>> = {
  [MenuElement.NURSE_DASHBOARD]: {
    id: 'qa-nurse-dashboard-menu',
    link: ['/nurse'],
    title: 'Nurse Dashboard',
  },
  [MenuElement.NURSE_ENCOUNTER_CREATE_REGULAR_DIRECT_LINK]: {
    id: 'qa-ths-page-request-menu',
    link: ['/encounter-create/regular'],
    title: 'THS Page Request',
  },
  [MenuElement.NURSE_ENCOUNTER_CREATE_CODE_BLUE_DIRECT_LINK]: {
    id: 'qa-code-blue-emergency-menu',
    link: ['/encounter-create/code-blue'],
    title: 'Code Blue Emergency',
  },
  [MenuElement.ENCOUNTER_CREATE_FACILITIES_PAGE]: {
    id: 'qa-new-call-request-menu',
    link: ['/encounter-create/facilities'],
    title: 'New Call Request',
  },
  [MenuElement.ENCOUNTER_CREATE_WIZARD_PAGE]: {
    id: 'qa-new-call-request-menu',
    link: ['/encounter-create'],
    title: 'New Call Request',
  },
  [MenuElement.CALL_REQUESTS_DASHBOARD]: {
    id: 'qa-call-requests-menu',
    link: ['/call-requests/tabs'],
    title: 'Call Requests',
  },
  [MenuElement.PATIENT_MANAGER]: {
    id: 'qa-patient-manager-menu',
    link: ['/patients'],
    title: 'Patient Manager',
  },
  [MenuElement.DOCTOR_SCHEDULING]: {
    id: 'qa-scheduling-menu',
    link: ['/md/scheduling/schedule'],
    title: 'Scheduling',
  },
  [MenuElement.DOCTOR_SCHEDULING_BY_MONTH]: {
    id: 'qa-monthly-scheduling-menu',
    link: ['/md/scheduling/monthly-schedule'],
    title: 'Monthly Schedule',
  },
  [MenuElement.DOCTOR_FOLLOW_UPS_DASHBOARD]: {
    id: 'qa-follow-ups-menu',
    link: ['/md/follow-ups'],
    title: 'Follow Ups',
  },
  [MenuElement.DOCTOR_ENCOUNTER_MANAGER]: {
    id: 'qa-encounter-manager-menu',
    link: ['/md/encounter-manager'],
    title: 'Encounter Manager',
  },
  [MenuElement.PROFILE_SETTINGS]: {
    id: 'qa-profile-menu',
    link: ['/profile'],
    title: 'Profile',
  },
  [MenuElement.ROUNDINGS]: {
    id: 'qa-rounding-menu',
    link: ['/rounding'],
    title: 'Rounding',
  },
  [MenuElement.CALL_CENTER_DASHBOARD]: {
    id: 'qa-facility-search-menu',
    link: ['/call-center-representative/facility'],
    title: 'Facility Search',
  },
  [MenuElement.FACILITY_ADMIN_FACILITY_MANAGER]: {
    id: 'qa-facility-manager-menu',
    link: ['/facility-admin/facility'],
    title: 'Facility Manager',
  },
  [MenuElement.REPORTING]: {
    id: 'qa-reporting-menu',
    link: ['/reports'],
    title: 'Reporting',
  },
  [MenuElement.LIVE_METRICS]: {
    id: 'qa-live-metrics-menu',
    link: ['/live-metrics'],
    title: 'Live Metrics',
  },
  [MenuElement.FOLLOW_UPS_DASHBOARD]: {
    id: 'qa-follow-ups-menu',
    link: ['/call-requests/tabs'],
    title: 'Follow Ups',
  },
  [MenuElement.CCNC_ENCOUNTER_MANAGER]: {
    id: 'qa-encounter-manager-menu',
    link: ['/encounter-manager/encounters'],
    title: 'Encounter Manager',
  },
  [MenuElement.CCNC_SCHEDULING]: {
    id: 'qa-scheduling-menu',
    link: ['/encounter-manager/scheduling/schedule'],
    title: 'Scheduling',
  },
  [MenuElement.CCNC_SCHEDULING_BY_MONTH]: {
    id: 'qa-monthly-scheduling-menu',
    link: ['/encounter-manager/scheduling/monthly-schedule'],
    title: 'Monthly Schedule',
  },
  [MenuElement.CCNC_REPORTING]: {
    id: 'qa-reporting-menu',
    link: ['/encounter-manager/reporting'],
    title: 'Reporting',
  },
  [MenuElement.CORP_EMPLOYEES_MANAGER]: {
    id: 'qa-corporation-employees-menu',
    link: ['/corporation-admin/corporation-employees'],
    title: 'Corporation Employees',
  },
  [MenuElement.CORP_ADMIN_FACILITY_MANAGER]: {
    id: 'qa-facilities-menu',
    link: ['/corporation-admin/facilities'],
    title: 'Facilities',
  },
  [MenuElement.SCHEDULING_ADMINISTRATION]: {
    id: 'qa-scheduling-menu',
    link: ['/admin/scheduling'],
    title: 'Scheduling',
  },
  [MenuElement.ADMIN_ENCOUNTER_MANAGER]: {
    id: 'qa-encounter-manager-menu',
    link: ['/admin/encounter-manager'],
    title: 'Encounter Manager',
  },
  [MenuElement.GLOBAL_EMPLOYEES_MANAGER]: {
    id: 'qa-employee-manager-menu',
    link: ['/admin/employee'],
    title: 'Employee Manager',
  },
  [MenuElement.USERS_MANAGER]: {
    id: 'qa-user-manager-menu',
    link: ['/users'],
    title: 'User Manager',
  },
  [MenuElement.CORPORATION_MANAGER]: {
    id: 'qa-corporation-manager-menu',
    link: ['/admin/corporation'],
    title: 'Corporation Manager',
  },
  [MenuElement.FACILITY_MANAGER]: {
    id: 'qa-facility-manager-menu',
    link: ['/admin/facility'],
    title: 'Facility Manager',
  },
  [MenuElement.DOCTOR_MANAGER]: {
    id: 'qa-doctor-manager-menu',
    link: ['/admin/md'],
    title: 'Doctor Manager',
  },
  [MenuElement.GLOBAL_REPORTING]: {
    id: 'qa-reporting-menu',
    link: ['/admin/reporting'],
    title: 'Reporting',
  },
  [MenuElement.APPLICATION_SETTINGS]: {
    id: 'qa-settings-menu',
    link: ['/admin/settings'],
    title: 'Settings',
  },
};

@Injectable({
  providedIn: 'root',
})
export class MenuDefinitionService {
  constructor() {}

  definedMenuElements(elements: readonly MenuElement[]): MenuElementDefinition[] {
    return elements.map(e => ({ key: e, ...DEFINITIONS[e] }));
  }
}
