export interface IFacilityTimezone {
  accountingDst: boolean;
  id: number;
  zone: string;
  iana: string;
}

export class FacilityTimezoneModel implements IFacilityTimezone {
  accountingDst = true;
  id: number;
  zone: string;
  iana: string;

  constructor(values: Partial<IFacilityTimezone> = {}) {
    this.accountingDst = values.accountingDst != undefined ? values.accountingDst : this.accountingDst;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    this.id = values.id != undefined || values.id === 0 ? values.id : this.id;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.zone = values.zone != undefined ? values.zone : this.zone;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.iana = values.iana != undefined ? values.iana : this.iana;
  }
}
