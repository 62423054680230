import { legacyDefined } from '@app/cdk/angular/utils/nullable.utils';

/**
 * @deprecated replace with FacilityType (not replaced in 1.41.1 but needed to replace in large release)
 */
export class Type {
  id!: number;
  name = '';

  constructor(values: Partial<Type> = {}) {
    this.id = legacyDefined(values.id) ? values.id : this.id;
    this.name = legacyDefined(values.name) ? values.name : this.name;
  }
}
