import { Directive, EventEmitter, HostBinding, HostListener, Input, OnChanges, Output } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { TypedChanges } from '@app/cdk/angular/model/changes-typing.model';
import { newLogger } from '@app/core/services/logger/logger.service';

const logger = newLogger('ImgFallbackDirective');

@Directive({
  selector: 'img[default]',
})
export class ImgFallbackDirective implements OnChanges {
  @Input()
  @HostBinding('src')
  src?: string | SafeUrl;

  @Input() default?: string | SafeUrl;

  @Output() loaded = new EventEmitter<void>();

  @HostListener('error', ['$event'])
  onError(event: Error): void {
    logger.info(event);
    this.src = this.default;
  }

  @HostListener('load')
  onLoad(): void {
    logger.info('Image loaded');
    this.loaded.emit();
  }

  ngOnChanges(changes: TypedChanges<this>): void {
    if (changes.src != undefined && changes.src.currentValue == undefined) {
      this.src = this.default;
    }
  }
}
