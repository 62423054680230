import { NgModule, Optional, Provider, SkipSelf } from '@angular/core';
import { AuthenticationApiService } from './api/authentication-api/authentication-api.service';
import { ApiPrefixInterceptor } from './interceptors/api-prefix/api-prefix.interceptor';
import { AuthInterceptor } from './interceptors/auth/auth.interceptor';
import { ErrorHandlerInterceptor } from './interceptors/error-handler/error-handler.interceptor';
import { ErrorFormatterService } from './services/error-formatter/error-formatter.service';
import { InfoService } from './services/global-info-modal/info.service';
import { MenuService } from './services/menu/menu.service';
import { ModalConfirmModule } from '@app/shell/shared/modal-confirm/modal-confirm.module';
import { InfoDialogComponent } from './components/info-dialog/info-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { WEBSOCKET_SERVICE_PROVIDER } from '@app/core/services/websocket/websocket.service';
import { CsrfAutoRepeatInterceptor } from '@app/core/interceptors/error-handler/csrf-auto-repeat.interceptor';
import { NotificationContainerComponent } from './services/global-info-modal/notification-container/notification-container.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { IconsModule } from '@app/icons.module';
import { CommonModule } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AutoRestorePage } from './pages/auto-restore/auto-restore.page';
import { RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { UpdateAvailableToastComponent } from '@app/core/components/update-available-toast/update-available-toast.component';
import { ApplicationUpdatedToastComponent } from '@app/core/components/application-updated-toast/application-updated-toast.component';
import { ApplicationUnrecoverableComponent } from './components/application-unrecoverable/application-unrecoverable.component';
import { CUSTOM_HTTP_CLIENT_PROVIDER, HTTP_DYNAMIC_INTERCEPTORS } from '@app/core/services/http/http.service';

/**
 * Using HTTP_DYNAMIC_INTERCEPTORS instead of default HTTP_INTERCEPTORS for have capability to control the order of interceptors across default Xsrf interceptor.
 * If providing just HTTP_INTERCEPTORS: CsrfAutoRepeatInterceptor will fails because it will try to repeat before default Xsrf set new header
 */
export const INTERCEPTORS: Provider[] = [
  // ORDER IS IMPORTANT!!!
  { provide: HTTP_DYNAMIC_INTERCEPTORS, useClass: ApiPrefixInterceptor, multi: true },
  { provide: HTTP_DYNAMIC_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true },
  { provide: HTTP_DYNAMIC_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_DYNAMIC_INTERCEPTORS, useClass: CsrfAutoRepeatInterceptor, multi: true },
];

@NgModule({
  imports: [
    ModalConfirmModule,
    MatDialogModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    IconsModule,
    CommonModule,
    MatProgressBarModule,
    RouterModule,
    MatCardModule,
  ],
  declarations: [
    InfoDialogComponent,
    NotificationContainerComponent,
    AutoRestorePage,
    UpdateAvailableToastComponent,
    ApplicationUpdatedToastComponent,
    ApplicationUnrecoverableComponent,
  ],
  providers: [
    AuthenticationApiService,
    ApiPrefixInterceptor,
    AuthInterceptor,
    ErrorHandlerInterceptor,
    ErrorFormatterService,
    CsrfAutoRepeatInterceptor,
    InfoService,
    MenuService,
    WEBSOCKET_SERVICE_PROVIDER,
    CUSTOM_HTTP_CLIENT_PROVIDER,
    INTERCEPTORS,
  ],
  exports: [UpdateAvailableToastComponent, ApplicationUpdatedToastComponent],
  entryComponents: [InfoDialogComponent, NotificationContainerComponent],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    if (parentModule != undefined) {
      throw new Error(`CoreModule has already been loaded. Import CoreModule in the AppModule only.`);
    }
  }
}
