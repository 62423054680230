import { ReportGenerationStatus } from '@app/features/reports/models/report-generation-status';
import { PatientEmrSyncDetails } from '@app/features/encounter/models/patient-emr-sync';
import { EmrFacilitySyncStatus } from '@app/features/patients/models/patient-search.model';
import { WsRoundingUpdateMode } from '@app/modules/rounding/models/ws-rounding-mode';
import { ConnectStatus } from '@app/features/encounter-details/models/connect-status.model';
import { OutcomeStatus } from '@app/shell/constants/outcome-status';

export enum WsResponseMessageType {
  CONNECT_ASSIGNMENT = 'CONNECT_ASSIGNED',
  DASHBOARD_STATUS_CHANGE = 'NURSE_DASHBOARD_CONNECT_STATUS_CHANGED',
  REPORT_GENERATED = 'REPORT_GENERATED',
  NEW_VACATION_REQUEST = 'NEW_VACATION_REQUEST',
  PATIENT_DETAILS_SYNCED = 'PATIENT_DETAILS_SYNCED',
  PATIENT_CONTACT_DETAILS_SYNCED = 'PATIENT_CONTACT_DETAILS_SYNCED',
  EMR_FACILITY_SYNCED = 'EMR_FACILITY_SYNCED',
  ROUNDING_UPDATED = 'ROUNDING_UPDATED',
  TELEMEDICINE_STATE_UPDATED = 'TELEMEDICINE_STATE_UPDATED',
  CALL_VIDEO_STATUS_UPDATED = 'CALL_VIDEO_STATUS_UPDATED',
}

export enum WsRequestMessageType {
  KeepALive = 'KEEP_ALIVE',
  Subscribe = 'SUBSCRIBE',
  Unsubscribe = 'UNSUBSCRIBE',
}

export interface WsMessage<P> {
  type: WsResponseMessageType | WsRequestMessageType;
  payload?: P;
}

export interface NurseDashboardUpdateView {
  connectId: number;
  oldStatus: ConnectStatus;
  newStatus: ConnectStatus;
  outcome: OutcomeStatus;
}

export interface ReportGeneratedView {
  id: number;
  fileName: string;
  generationStatus: ReportGenerationStatus;
}

export interface ConnectAssignedView {
  ids?: number[];
}

export interface PatientEmrDetailsSyncStatusView {
  patientId: number;
  type: PatientEmrSyncDetails;
}

export interface EmrFacilitySyncView {
  isFound: boolean;
  syncStatus: EmrFacilitySyncStatus;
  syncFailedReason?: string;
  facilityId: number;
  time: string;
}

export interface RoundingUpdateView extends RoundingShortUpdateView {
  roundingId: number;
  facilityId?: number;
}

export interface RoundingShortUpdateView {
  roundingDate?: string;
  modes: WsRoundingUpdateMode[];
}
