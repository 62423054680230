/* eslint-disable */
import {
  ConnectableObservable,
  MonoTypeOperatorFunction,
  Observable,
  Operator,
  Subscriber,
  Subscription,
  TeardownLogic,
} from 'rxjs';

export function delayedRefCount<T>(): MonoTypeOperatorFunction<T> {
  return function delayCountOperatorFunction(source: ConnectableObservable<T>): Observable<T> {
    return source.lift(new DelayedRefCountOperator(source));
  } as MonoTypeOperatorFunction<T>;
}

class DelayedRefCountSubscriber<T> extends Subscriber<T> {
  private connection?: Subscription;

  constructor(destination: Subscriber<T>, private connectable?: ConnectableObservable<T>) {
    super(destination);
  }

  protected _unsubscribe(): void {
    const { connectable } = this;
    if (!connectable) {
      this.connection = undefined;
      return;
    }

    this.connectable = undefined;
    const refCount = (<any>connectable)._refCount;
    if (refCount <= 0) {
      this.connection = undefined;
      return;
    }

    (<any>connectable)._refCount = refCount - 1;
    const refCountAfterChange = (<any>connectable)._refCount;
    if (refCount > 1) {
      this.connection = undefined;
      return;
    }

    const { connection } = this;
    const sharedConnection = (<any>connectable)._connection;
    this.connection = undefined;

    if (sharedConnection != undefined && (!connection || sharedConnection === connection)) {
      setTimeout(() => {
        const refCountAfterTimeout = (<any>connectable)._refCount;
        if (refCountAfterTimeout === refCountAfterChange) {
          sharedConnection.unsubscribe();
        }
      }, 500);
    }
  }
}

class DelayedRefCountOperator<T> implements Operator<T, T> {
  constructor(private connectable: ConnectableObservable<T>) {}
  call(subscriber: Subscriber<T>, source: any): TeardownLogic {
    const { connectable } = this;
    (<any>connectable)._refCount++;

    const refCounter = new DelayedRefCountSubscriber(subscriber, connectable);
    const subscription = source.subscribe(refCounter);

    if (refCounter.closed) {
    } else {
      (<any>refCounter).connection = connectable.connect();
    }

    return subscription;
  }
}
