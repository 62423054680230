import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { NotFoundComponent } from './core/pages/not-found/not-found.component';
import { ForbiddenComponent } from './core/pages/forbidden/forbidden.component';
import { AuthResolveGuard } from '@app/core/guards/auth/auth-resolve.guard';
import { AutoRestorePage } from '@app/core/pages/auto-restore/auto-restore.page';
import { SessionTimeOutComponent } from "./core/pages/session-timeout/session-timeout.component";

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./shell/shell.module').then(m => m.ShellModule),
    canActivate: [AuthResolveGuard],
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
  },
  { path: 'forbidden', component: ForbiddenComponent },
  { path: 'not-found', component: NotFoundComponent },
  { path: 'auto-restore', component: AutoRestorePage },
  { path: 'session-timeout', component: SessionTimeOutComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      onSameUrlNavigation: 'reload',
      paramsInheritanceStrategy: 'always',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
