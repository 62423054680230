/**
 * Class with helpers methods
 */
export class Helpers {
  /**
   * Transform formatted with mask phone number string into number
   */
  public static getRawPhoneNumber(formattedString: string | undefined): string {
    return formattedString?.replace(/[\s\(\)-]/g, '') ?? '';
  }
}
