import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { GroupingReportSetting } from '@app/features/profile/models/grouping-report-settings';
import { catchError, map } from 'rxjs/operators';
import {
  AnonymousCorporationProfile,
  CorporationProfileInfo,
  ProfileCorporationAuthInfo,
} from '@app/features/profile/models/profile-corporation-auth-info.model';
import { EhrProvider } from '@app/features/ehr-commons/models/ehr-provider.model';
import { ValueResponse } from '@app/shell/models/common/value-object-response';
import { FacilityStatsReportUserSettingsInput } from '@app/shell/models/reports/facility-stats-report-user-settings';
import { UserStatsReportFacilityView } from '@app/shell/models/reports/user-stats-report-facility-view';
import { StatisticsReportsFrequency } from '@app/shell/constants/statistics-reports-frequency';
import { writeApiBoolean, writeApiDateTime, writeApiInteger } from '@app/cdk/http/converters';
import { DateTime } from 'luxon';
import { ConditionCheck } from '@app/shared/models/condition-check.model';
import { ProfileInfo } from '@app/shell/models/employee/profile-info-impl';
import { ContextHttpParams } from '@app/cdk/http/context-http-params';
import { CollectionResponse } from '@app/shell/models/common/collection-response';
import { AuthenticationOption } from '@app/modules/auth/models/authentication-option';

const routes = {
  profile: () => `/profile`,
  signature: () => `${routes.profile()}/signature`,
  doctorTitle: () => `${routes.profile()}/doctor-title`,
  corporationAuth: (system: EhrProvider, corpId: number) =>
    `${routes.profile()}/auth/${system.toLowerCase()}/${corpId}/info`,
  groupingSettings: () => '/profile/statistics-reports/facilities/settings/grouping',
  consolidatedReports: () => '/profile/statistics-reports/facilities/settings/grouping/consolidated',
  individualReports: () => '/profile/statistics-reports/facilities/settings/grouping/individual',
  currentUserReportFacilities: () => `/profile/statistics-reports/facilities/settings`,
  corporationFrequencies: () => `/profile/statistics-reports/corporations/settings/frequencies`,
  checkEncountersCanBeAssigned: () => `${routes.profile()}/encounters/can-be-assigned`,
  supportedAuthMethods: () => `/profile/supported-auth-methods`,
};

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private readonly httpClient: HttpClient) {}

  /**
   * Current user profile info
   */
  getProfile(): Observable<ProfileInfo> {
    return this.httpClient.get<ProfileInfo>(routes.profile(), {
      params: new ContextHttpParams({
        bypassAuthError: true,
      }),
    });
  }

  getSupportedAuthMethods(): Observable<AuthenticationOption[]> {
    return this.httpClient
      .get<CollectionResponse<AuthenticationOption>>(routes.supportedAuthMethods())
      .pipe(map(items => items.items));
  }

  public updateSignature(signature: File): Observable<void> {
    const headers = new HttpHeaders().set('Content-Type', 'image/png');
    return this.httpClient.put<void>(routes.signature(), signature, { headers });
  }

  public getSignature(): Observable<Blob> {
    return this.httpClient.get(routes.signature(), {
      params: new ContextHttpParams({
        bypass404: true,
      }),
      responseType: 'blob',
    });
  }

  public getPeriodicalReportGrouping(): Observable<GroupingReportSetting | undefined> {
    return this.httpClient.get<GroupingReportSetting>(routes.groupingSettings());
  }

  public enableConsolidatedReports(): Observable<void> {
    return this.httpClient.put<void>(routes.consolidatedReports(), {});
  }

  public disableConsolidatedReports(): Observable<void> {
    return this.httpClient.delete<void>(routes.consolidatedReports());
  }

  public enableIndividualReports(): Observable<void> {
    return this.httpClient.put<void>(routes.individualReports(), {});
  }

  public disableIndividualReports(): Observable<void> {
    return this.httpClient.delete<void>(routes.individualReports());
  }

  public getCorporationAuthInfo(corpId: number, system: EhrProvider): Observable<ProfileCorporationAuthInfo> {
    return this.httpClient
      .get<ValueResponse<CorporationProfileInfo | undefined>>(routes.corporationAuth(system, corpId))
      .pipe(
        map(value => {
          if (value.value == undefined) {
            return {
              authenticated: false,
            } as AnonymousCorporationProfile;
          }
          return {
            authenticated: true,
            info: value.value,
          };
        }),
        catchError(() => {
          return of({
            authenticated: false,
          } as AnonymousCorporationProfile);
        })
      );
  }

  public setReportFacilities(reportSettings: Array<FacilityStatsReportUserSettingsInput>): Observable<void> {
    return this.httpClient
      .put<void>(routes.currentUserReportFacilities(), {
        settings: reportSettings,
      })
      .pipe(catchError(error => throwError(error)));
  }

  public getReportFacilities(): Observable<UserStatsReportFacilityView> {
    return this.httpClient
      .get<UserStatsReportFacilityView>(routes.currentUserReportFacilities())
      .pipe(catchError(error => throwError(error)));
  }

  public getCorporationReportFrequencies(): Observable<StatisticsReportsFrequency[]> {
    return this.httpClient
      .get<StatisticsReportsFrequency[]>(routes.corporationFrequencies())
      .pipe(catchError(error => throwError(error)));
  }

  public setCorporationReportFrequencies(corpFrequencies: StatisticsReportsFrequency[]): Observable<void> {
    return this.httpClient
      .put<void>(routes.corporationFrequencies(), corpFrequencies)
      .pipe(catchError(error => throwError(error)));
  }

  public checkEncountersCanBeAssigned(
    facilityId: number,
    codeBlue: boolean,
    effectiveTime: DateTime | undefined
  ): Observable<ConditionCheck> {
    return this.httpClient.get<ConditionCheck>(routes.checkEncountersCanBeAssigned(), {
      params: new HttpParams({
        fromObject: {
          facilityId: writeApiInteger(facilityId),
          effectiveTime: writeApiDateTime(effectiveTime),
          codeBlue: writeApiBoolean(codeBlue),
        },
      }),
    });
  }
}
