<div class="toast-body">
  <div class="toast-content">
    <div
      *ngIf="message != undefined"
      role="alertdialog"
      class="default-text"
      aria-live="polite"
      [attr.aria-label]="message"
    >
      {{ message }}
    </div>
    <div #outlet></div>
  </div>

  <button *ngIf="options.closeButton" mat-icon-button (click)="remove()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-progress-bar
  *ngIf="options.progressBar && width > 0"
  class="progress-bar"
  [value]="width"
></mat-progress-bar>
