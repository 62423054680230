import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  NOTIFICATION_DATA,
  NotificationInstanceRef,
  NotificationRef,
} from '@app/core/services/global-info-modal/notification-container/notification-container.model';

export interface ApplicationUnrecoverableAction {
  type: 'update' | 'sub';
}

@Component({
  selector: 'app-application-unrecoverable',
  templateUrl: './application-unrecoverable.component.html',
  styleUrls: ['./application-unrecoverable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationUnrecoverableComponent implements NotificationInstanceRef<ApplicationUnrecoverableAction> {
  constructor(
    readonly ref: NotificationRef<ApplicationUnrecoverableAction>,
    @Inject(NOTIFICATION_DATA) readonly reason: string
  ) {}

  handleUpdateClick(): void {
    this.ref.action({ type: 'update' }, true);
  }
}
