import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasPermissionDirective } from '@app/cdk/permission/directives/has-permission.directive';
import { HasFeatureDirective } from './directives/has-feature.directive';

@NgModule({
  declarations: [HasPermissionDirective, HasFeatureDirective],
  exports: [HasPermissionDirective, HasFeatureDirective],
  imports: [CommonModule],
})
export class PermissionModule {}
