import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegionLockerComponent } from './region-locker/region-locker.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBar, MatProgressBarModule } from '@angular/material/progress-bar';
import { LockerSpawnPointDirective } from './region-locker/locker-spawn-point.directive';
import { DialogLockerDirective } from './region-locker/dialog-locker.directive';
import { CardLockerDirective } from '@app/cdk/locker/region-locker/card-locker.directive';

@NgModule({
  declarations: [RegionLockerComponent, LockerSpawnPointDirective, DialogLockerDirective, CardLockerDirective],
  exports: [RegionLockerComponent, LockerSpawnPointDirective, DialogLockerDirective, CardLockerDirective],
  imports: [CommonModule, MatProgressSpinnerModule, MatProgressBarModule],
  entryComponents: [MatProgressBar],
})
export class LockerModule {}
