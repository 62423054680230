import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// suppress short import for use normal fs paths in angular tools
// noinspection ES6PreferShortImport
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import localeUs from '@angular/common/locales/es-US';
import { registerLocaleData } from '@angular/common';
import { Logger } from '@app/core/services/logger/logger.service';

registerLocaleData(localeUs);

if (environment.production) {
  enableProdMode();
  Logger.enableProductionMode();
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.log(err));
});
