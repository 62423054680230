import { Pipe, PipeTransform } from '@angular/core';
import { TimeZone } from '@app/shell/models/time.zone';
import { DateTime } from 'luxon';
import * as moment from 'moment';
import { newLogger } from '@app/core/services/logger/logger.service';

const logger = newLogger('ShortDateTimePipe');

const FORMAT = 'MM/dd/yyyy h:mm a';

export type DatePipeInput = string | moment.Moment | Date | number | DateTime;

export function transformDatePipeInput(inputOrMoment: DatePipeInput): DateTime;
export function transformDatePipeInput(inputOrMoment: DatePipeInput | undefined): DateTime | undefined;
export function transformDatePipeInput(inputOrMoment: DatePipeInput | undefined): DateTime | undefined {
  if (inputOrMoment == undefined) {
    return undefined;
  }
  if (DateTime.isDateTime(inputOrMoment)) {
    return inputOrMoment;
  } else if (moment.isMoment(inputOrMoment)) {
    logger.warn('Consider to replace momentjs with luxon');
    return DateTime.fromISO(inputOrMoment.toISOString());
  } else if (inputOrMoment instanceof Date) {
    logger.warn('Consider to replace native date with luxon');
    return DateTime.fromISO(inputOrMoment.toISOString());
  } else if (typeof inputOrMoment === 'number') {
    logger.warn('Consider to replace timestamps with iso string');
    return DateTime.fromMillis(inputOrMoment, { zone: 'UTC' });
  } else {
    let value = inputOrMoment;
    if (inputOrMoment.includes('•')) {
      value = inputOrMoment.replace('••••', '0001');
    } else if (inputOrMoment.includes('*')) {
      value = inputOrMoment.replace('****', '0001');
    }
    const dateOrNotDate = DateTime.fromISO(value);
    return dateOrNotDate.isValid ? dateOrNotDate : DateTime.fromFormat(value, 'MM/dd/yyyy');
  }
}

@Pipe({
  name: 'shortDateTime',
})
export class ShortDateTimePipe implements PipeTransform {
  transform(
    inputOrMoment: DatePipeInput | undefined,
    timeZone?: TimeZone,
    forceHideTimeZone?: boolean
  ): string | undefined {
    if (inputOrMoment == undefined) {
      return undefined;
    }

    const input = transformDatePipeInput(inputOrMoment);

    if (timeZone == undefined) {
      return input.toFormat(FORMAT);
    }

    const formatted = input.setZone(timeZone.value).toFormat(FORMAT);
    if (forceHideTimeZone === true) {
      return formatted;
    }
    return `${formatted} ${timeZone.name}`;
  }
}
