<ng-progress
  id="navigation-progress-bar"
  [spinner]="notMobile$ | async"
  spinnerPosition="left"
  [thick]="isMobile$ | async"
  [debounceTime]="150"
>
</ng-progress>

<div class="app-content">
  <router-outlet></router-outlet>
</div>
