import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LicenseAgreementWarningComponent } from '@app/modules/agreement/license-agreement-warning/license-agreement-warning.component';
import { SectionsModule } from '@app/cdk/sections/sections.module';

@NgModule({
  declarations: [LicenseAgreementWarningComponent],
  imports: [CommonModule, SectionsModule],
  exports: [LicenseAgreementWarningComponent],
})
export class AgreementModule {}
