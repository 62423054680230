/**
 * Simple logger system with the possibility of registering custom outputs.
 *
 * 4 different log levels are provided, with corresponding methods:
 * - debug   : for debug information
 * - info    : for informative status of the application (success, ...)
 * - warning : for non-critical errors that do not prevent normal application behavior
 * - error   : for critical errors that prevent normal application behavior
 *
 * Example usage:
 * ```
 * import { Logger } from 'app/core/logger.service';
 *
 * const log = new Logger('myFile');
 * ...
 * log.debug('something happened');
 * ```
 *
 * To disable debug and info logs in production, add this snippet to your root component:
 * ```
 * export class AppComponent implements OnInit {
 *   ngOnInit() {
 *     if (environment.production) {
 *       Logger.enableProductionMode();
 *     }
 *     ...
 *   }
 * }
 *
 * If you want to process logs through other outputs than console, you can add LogOutput functions to Logger.outputs.
 */

/**
 * The possible log levels.
 * LogLevel.Off is never emitted and only used with Logger.level property to disable logs.
 */
export enum LogLevel {
  Off,
  Error,
  Warning,
  Info,
  Debug,
}

export const levelText: Record<LogLevel, string> = {
  [LogLevel.Off]: '',
  [LogLevel.Error]: 'E',
  [LogLevel.Warning]: 'W',
  [LogLevel.Info]: 'I',
  [LogLevel.Debug]: 'D',
};

/**
 * Log output handler function.
 */
export type LogOutput = (source: string, level: LogLevel, ...objects: unknown[]) => void;

export class Logger {
  /**
   * Current logging level.
   * Set it to LogLevel.Off to disable logs completely.
   */
  static level = LogLevel.Debug;

  /**
   * Additional log outputs.
   */
  static outputs: LogOutput[] = [];

  constructor(private source: string) {}

  /**
   * Enables production mode.
   * Sets logging level to LogLevel.Warning.
   */
  static enableProductionMode(): void {
    Logger.level = LogLevel.Info;
  }

  /**
   * Logs messages or objects  with the debug level.
   * Works the same as console.log().
   */
  debug(...objects: unknown[]): void {
    // eslint-disable-next-line no-console
    this.log(console.log, LogLevel.Debug, objects);
  }

  /**
   * Logs messages or objects  with the info level.
   * Works the same as console.log().
   */
  info(...objects: unknown[]): void {
    // eslint-disable-next-line no-console
    this.log(console.info, LogLevel.Info, objects);
  }

  /**
   * Logs messages or objects  with the warning level.
   * Works the same as console.log().
   */
  warn(...objects: unknown[]): void {
    // eslint-disable-next-line no-console
    this.log(console.warn, LogLevel.Warning, objects);
  }

  /**
   * Logs messages or objects  with the error level.
   * Works the same as console.log().
   */
  error(...objects: unknown[]): void {
    // eslint-disable-next-line no-console
    this.log(console.error, LogLevel.Error, objects);
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  private log(func: Function, level: LogLevel, objects: unknown[]): void {
    if (0 < level && level <= Logger.level) {
      func.call(console, levelText[level], '[', this.source, ']', ...objects);
      Logger.outputs.forEach(output => output(this.source, level, ...objects));
    }
  }
}

export function newLogger(source: string): Logger {
  return new Logger(source);
}
