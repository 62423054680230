import { Inject, Injectable, Injector, OnDestroy } from '@angular/core';
import { SwUpdate, UnrecoverableStateEvent, UpdateActivatedEvent, UpdateAvailableEvent } from '@angular/service-worker';
import { Platform } from '@angular/cdk/platform';
import { newLogger } from '@app/core/services/logger/logger.service';
import { filter, switchMap } from 'rxjs/operators';
import {
  UpdateActivated,
  updateActivatedFromEvent,
  UpdateAvailable,
  updateAvailableFromEvent,
} from '@app/shell/components/version-update-checker/version-update.model';
import { ChangeLogModalComponent } from '@app/shared/components/changelog-modal/changelog-modal.component';
import {
  ApplicationUpdatedAction,
  ApplicationUpdatedToastComponent,
} from '@app/core/components/application-updated-toast/application-updated-toast.component';
import { InfoService } from '@app/core/services/global-info-modal/info.service';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import {
  UpdateAvailableAction,
  UpdateAvailableToastComponent,
} from '@app/core/components/update-available-toast/update-available-toast.component';
import { DOCUMENT } from '@angular/common';
import {
  ApplicationUnrecoverableAction,
  ApplicationUnrecoverableComponent,
} from '@app/core/components/application-unrecoverable/application-unrecoverable.component';

const logger = newLogger('PwaService');

@Injectable({
  providedIn: 'root',
})
export class PwaService implements OnDestroy {
  readonly updateAvailable$ = new ReplaySubject<boolean>(1);

  private activatedSub = Subscription.EMPTY;
  private unrecoverableSub = Subscription.EMPTY;
  private availableSub = Subscription.EMPTY;

  private updatedManually = false;

  constructor(
    private readonly swUpdate: SwUpdate,
    private readonly platform: Platform,
    private readonly dialogService: MatDialog,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly injector: Injector
  ) {}

  init(): Promise<void> {
    if (!this.platform.isBrowser) {
      logger.info("Not Browser, PWA won't activated");
      return Promise.resolve();
    }

    if (!this.swUpdate.isEnabled) {
      logger.info("SW unavailable, PWA won't activated");
      return Promise.resolve();
    }

    logger.info('SW available, listen PWA events');

    this.activatedSub = this.listenActivatedEvents();
    this.unrecoverableSub = this.listenUnrecoverableEvents();
    this.availableSub = this.listenUpdateAvailableEvents();

    return Promise.resolve();
  }

  ngOnDestroy(): void {
    this.activatedSub.unsubscribe();
    this.unrecoverableSub.unsubscribe();
    this.availableSub.unsubscribe();
  }

  activateUpdate(): void {
    this.activateUpdateInternal();
  }

  private listenActivatedEvents(): Subscription {
    return this.swUpdate.activated
      .pipe(
        filter(() => !this.updatedManually),
        switchMap(activated => this.notifyAboutUpdated(activated)),
        filter(event => event.type === 'show-changelog')
      )
      .subscribe(() =>
        this.dialogService.open(ChangeLogModalComponent, {
          width: '700px',
        })
      );
  }

  private notifyAboutUpdated(activated: UpdateActivatedEvent): Observable<ApplicationUpdatedAction> {
    logger.info('New version activated', activated);
    return this.infoService.info<ApplicationUpdatedAction, UpdateActivated>(
      ApplicationUpdatedToastComponent,
      updateActivatedFromEvent(activated),
      {
        disableTimeOut: true,
        closeButton: false,
      }
    );
  }

  private listenUnrecoverableEvents(): Subscription {
    return this.swUpdate.unrecoverable
      .pipe(
        switchMap(unrecoverable => this.notifyAboutUnrecoverable(unrecoverable)),
        filter(event => event.type === 'update')
      )
      .subscribe(() => {
        this.document.location.reload();
      });
  }

  private notifyAboutUnrecoverable(unrecoverable: UnrecoverableStateEvent): Observable<ApplicationUnrecoverableAction> {
    logger.warn('Detected Unrecoverable Version', unrecoverable);
    return this.infoService.notice(ApplicationUnrecoverableComponent, unrecoverable.reason, {
      disableTimeOut: true,
      closeButton: false,
    });
  }

  private listenUpdateAvailableEvents(): Subscription {
    return this.swUpdate.available
      .pipe(
        switchMap(available => this.notifyAboutUpdateAvailable(available)),
        filter(event => event.type === 'activate')
      )
      .subscribe(() => this.activateUpdateInternal());
  }

  private notifyAboutUpdateAvailable(available: UpdateAvailableEvent): Observable<UpdateAvailableAction> {
    logger.info('Update found', available);
    this.updateAvailable$.next(true);

    return this.infoService.info<UpdateAvailableAction, UpdateAvailable>(
      UpdateAvailableToastComponent,
      updateAvailableFromEvent(available),
      {
        disableTimeOut: true,
        closeButton: false,
      }
    );
  }

  private activateUpdateInternal(): void {
    this.updatedManually = true;
    return void this.swUpdate.activateUpdate().then(() => this.document.location.reload());
  }

  private get infoService(): InfoService {
    return this.injector.get(InfoService);
  }
}
