import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GlobalInfoModalComponent } from '@app/shared/components/global-info-modal/global-info-modal.component';
import { IGlobalInfoModalData } from '@app/core/services/global-info-modal/global-info-modal-data';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ModalConfirmComponent } from '@app/shell/shared/modal-confirm/modal-confirm.component';
import {
  ConfirmDialogExtendedSettings,
  ConfirmDialogSettings,
} from '@app/shell/models/confirmation/confirm-dialog-settings';
import { ComponentType } from '@angular/cdk/overlay';
import {
  AccessDeniedResponse,
  EmrSystemErrorResponse,
} from '@app/core/interceptors/error-handler/access-denied-response.model';
import { transformEhrProvider } from '@app/features/ehr-commons/pipes/ehr-provider.pipe';
import { NotificationContainerComponent } from '@app/core/services/global-info-modal/notification-container/notification-container.component';
import { ToastrService } from 'ngx-toastr';
import { newLogger } from '@app/core/services/logger/logger.service';
import { ViolationError } from '@app/cdk/http/errors';
import { GLOBAL_ERRORS_REGISTRY } from '@app/core/services/error-formatter/error-formatter.service';
import { EntityName } from '@app/core/services/global-info-modal/entity-name.enum';
import { Http503MayBeAutoRestoreError } from '@app/core/interceptors/error-handler/error-handler.interceptor';
import { IndividualConfig } from 'ngx-toastr/toastr/toastr-config';
import { NotificationInstanceRef } from '@app/core/services/global-info-modal/notification-container/notification-container.model';

const logger = newLogger('InfoService');

const ERROR_BY_CODE_MAP = new Map(GLOBAL_ERRORS_REGISTRY.map(error => [error.errorCode, error]));
const ERROR_BY_KEY_MAP = new Map(GLOBAL_ERRORS_REGISTRY.map(error => [error.error, error]));

export type ErrorInput =
  | string
  | AccessDeniedResponse
  | EmrSystemErrorResponse
  | ViolationError
  | Http503MayBeAutoRestoreError
  | Error;

function transformError(code: number): string {
  switch (code) {
    case 401:
      return 'Unauthorized';
    case 403:
      return 'Forbidden';
    case 404:
      return 'Not found';
    case 400:
      return 'Bad request';
    case 500:
      return 'Internal server';
    default:
      return `${code}`;
  }
}

function isEmrError(error: Exclude<ErrorInput, string>): error is EmrSystemErrorResponse {
  return 'statusCode' in error && 'emrSystem' in error;
}

function isViolationError(error: Exclude<ErrorInput, string>): error is ViolationError {
  return 'response' in error && typeof error.response === 'object' && 'violations' in error.response;
}

function hasMessage(error: Exclude<ErrorInput, string>): error is EmrSystemErrorResponse | Error {
  return 'message' in error;
}

function getViolationMessage(v: { error: string; errorCode: string; description: string }): string {
  const byKey = ERROR_BY_KEY_MAP.get(v.error);
  if (byKey != undefined) {
    return byKey.description;
  }
  const byCode = ERROR_BY_CODE_MAP.get(v.errorCode);
  if (byCode != undefined) {
    return byCode.description;
  }
  return v.description;
}

function getMessage(input: ErrorInput | undefined): string | string[] {
  if (input == undefined) {
    return 'Unknown error';
  }
  if (typeof input === 'string') {
    return input;
  }
  if (typeof input === 'object') {
    if (isEmrError(input)) {
      return `${transformEhrProvider(input.emrSystem)} returned ${transformError(input.statusCode)} error`;
    }
    if (isViolationError(input)) {
      const violations = input.response.violations;
      return violations?.map(v => getViolationMessage(v)) ?? [];
    }
    if (hasMessage(input)) {
      return input.message;
    }
  }
  return String(input);
}

type ActionInput = EntityName | string;

type ToastType = 'info' | 'warning' | 'error' | 'success';
const DEFAULT_CONFIG: Record<ToastType, Partial<IndividualConfig>> = {
  info: {
    toastComponent: NotificationContainerComponent,
    progressBar: true,
    timeOut: 5000,
    extendedTimeOut: 1000,
    closeButton: true,
    tapToDismiss: false,
  },
  success: {
    toastComponent: NotificationContainerComponent,
    progressBar: true,
    timeOut: 5000,
    extendedTimeOut: 1000,
    closeButton: true,
    tapToDismiss: false,
  },
  warning: {
    toastComponent: NotificationContainerComponent,
    progressBar: true,
    timeOut: 5000,
    extendedTimeOut: 1000,
    closeButton: true,
    tapToDismiss: false,
  },
  error: {
    toastComponent: NotificationContainerComponent,
    disableTimeOut: true,
    closeButton: true,
    tapToDismiss: false,
  },
};

@Injectable({
  providedIn: 'root',
})
export class InfoService {
  readonly actions = {
    custom: (name: ActionInput, action: string): void =>
      void this.notification(`${name} has been successfully ${action}`),
    updated: (name: ActionInput): void => this.actions.custom(name, 'updated'),
    deleted: (name: ActionInput): void => this.actions.custom(name, 'deleted'),
    created: (name: ActionInput): void => this.actions.custom(name, 'created'),

    switched: (name: ActionInput, enabled: boolean): void =>
      this.actions.custom(name, 'switched ' + (enabled ? 'on' : 'off')),
  };

  constructor(private dialog: MatDialog, private readonly toastr: ToastrService) {}

  /**
   * Open global modal with error details.
   */
  openDialog(data: IGlobalInfoModalData, dataDev?: IGlobalInfoModalData): void {
    let showData = data;
    if (dataDev != undefined && !environment.production) {
      showData = dataDev;
      showData.showStatus = true;
    }
    this.dialog.open(GlobalInfoModalComponent, {
      width: '600px',
      disableClose: true,
      data: showData,
    });
  }

  confirm(config: Partial<ConfirmDialogExtendedSettings>): Observable<boolean | undefined> {
    const dialogRef = this.dialog.open<ModalConfirmComponent, ConfirmDialogSettings, boolean>(ModalConfirmComponent, {
      width: config.disableFixedWidth === true ? config.width : '550px',
      disableClose: true,
      data: new ConfirmDialogSettings(config),
    });

    return dialogRef.afterClosed();
  }

  notification<A, D = void, T extends NotificationInstanceRef<A> = NotificationInstanceRef<A>>(
    message: ComponentType<T>,
    data?: D,
    config?: Partial<IndividualConfig>
  ): Observable<A>;
  notification(message: string, config?: Partial<IndividualConfig>): void;
  notification<A, D, T extends NotificationInstanceRef<A>>(
    message: string | ComponentType<T>,
    data?: D,
    config?: Partial<IndividualConfig>
  ): Observable<A> | void {
    if (typeof message === 'string') {
      return this.show('success', message, config);
    }
    return this.show('success', message, data, config);
  }

  /**
   * Shows warning for specified input.
   *
   * This method works automatically with {@link ViolationError} errors
   * from backend. To map specific violation to a human-readable message,
   * you need to add an entry to {@link ErrorFormatterService}.
   */
  warn(input: ErrorInput | undefined, config?: Partial<IndividualConfig>): void {
    logger.error(input);

    if (input != undefined && typeof input === 'object' && 'autoRestore' in input) {
      return;
    }

    const message = getMessage(input);

    if (!Array.isArray(message)) {
      this.show('error', message, config);
      return;
    }

    message.forEach(msg => this.show('error', msg, config));
  }

  notice<A, D = void, T extends NotificationInstanceRef<A> = NotificationInstanceRef<A>>(
    message: ComponentType<T>,
    data?: D,
    config?: Partial<IndividualConfig>
  ): Observable<A>;
  notice(message: string, config?: Partial<IndividualConfig>): void;
  notice<A, D, T extends NotificationInstanceRef<A>>(
    message: string | ComponentType<T>,
    data?: D,
    config?: Partial<IndividualConfig>
  ): Observable<A> | void {
    if (typeof message === 'string') {
      return this.show('warning', message, config);
    }
    return this.show('warning', message, data, config);
  }

  info<A, D = void, T extends NotificationInstanceRef<A> = NotificationInstanceRef<A>>(
    message: ComponentType<T>,
    data?: D,
    config?: Partial<IndividualConfig>
  ): Observable<A>;
  info(message: string, config?: Partial<IndividualConfig>): void;
  info<A, D, T extends NotificationInstanceRef<A>>(
    message: string | ComponentType<T>,
    data?: D,
    config?: Partial<IndividualConfig>
  ): Observable<A> | void {
    if (typeof message === 'string') {
      return this.show('info', message, config);
    }
    return this.show('info', message, data, config);
  }

  handleError(handler: (error: ErrorInput) => boolean | undefined | void = () => false): (error: ErrorInput) => void {
    return (error: ErrorInput) => {
      if (handler(error) === true) {
        return;
      }
      this.warn(error);
    };
  }

  private show<A, D, T extends NotificationInstanceRef<A>>(
    type: ToastType,
    message: ComponentType<T>,
    data: D,
    config?: Partial<IndividualConfig>
  ): Observable<A>;
  private show(type: ToastType, message: string, config?: Partial<IndividualConfig>): void;
  private show<A, D, T extends NotificationInstanceRef<A>>(
    type: ToastType,
    message: string | ComponentType<T>,
    data: D,
    config: Partial<IndividualConfig> = {}
  ): Observable<A> | void {
    const msg = typeof message === 'string' ? message : undefined;
    const toast = this.toastr[type](msg, undefined, {
      ...DEFAULT_CONFIG[type],
      ...config,
    });

    if (typeof message !== 'string') {
      const componentInstance = toast.toastRef.componentInstance as NotificationContainerComponent<A, D>;
      componentInstance.render(message, data);
      return componentInstance.actions$;
    }
  }
}
