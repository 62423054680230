import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { WebsocketService } from '@app/core/services/websocket/websocket.service';
import { CurrentUserService } from '@app/core/services/authentication/current-user.service';
import { CurrentUser, LoginSource } from '@app/core/services/authentication/current-user';
import { newLogger } from '@app/core/services/logger/logger.service';

const logger = newLogger('AuthenticationService');

/**
 * Provides a base for authentication workflow.
 * The Credentials interface as well as login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(private readonly websocketService: WebsocketService, private readonly currentUser: CurrentUserService) {}

  init(): Promise<CurrentUser | undefined> {
    logger.info('Init Authentication Service');
    return this.currentUser._refresh(LoginSource.SESSION).toPromise();
  }

  refresh(): Observable<CurrentUser | undefined> {
    logger.info('Refresh Authentication Within Session');
    return this.currentUser._refresh(LoginSource.SESSION);
  }

  postLoginAction(): Observable<CurrentUser | undefined> {
    logger.info('Post Login Actions');
    return this.currentUser._refresh(LoginSource.LOGIN);
  }

  /**
   * Logs out the user and clear credentials.
   * @return true if the user was logged out successfully.
   */
  postLogoutAction(): Observable<boolean> {
    logger.info('Post Logout Actions');
    this.websocketService.closeSession();
    this.currentUser._cleanup();
    return of(true);
  }

  /**
   * Checks if user role equals any of the specified roles.
   * @deprecated Use version from {@link CurrentUser} instead.
   * @param roles Roles
   */
  public isUserRoleAnyOf(roles: Array<number>): boolean {
    return this.currentUser._currentUser$.getValue()?.user?.hasAnyRole(...roles) ?? false;
  }
}
