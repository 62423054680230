import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ObjectiveComponent } from './components/objective/objective.component';
import { DurationFieldComponent } from './components/duration-field/duration-field.component';
import { SubjectiveComponent } from './components/subjective-presentational/subjective.component';
import { AssessmentComponent } from './components/assessment/assessment.component';
import { AssessmentDiagnosisComponent } from './components/assessment-diagnosis/assessment-diagnosis.component';
import { EsignComponent } from './components/esign/esign.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TlhFormsModule } from '@app/cdk/tlh-forms/tlh-forms.module';
import { LayoutModule } from '@app/cdk/layout/layout.module';
import { BillingFieldComponent } from './components/billing-field/billing-field.component';
import { SoapAutoFillFailureReasonPipe } from './pipes/soap-auto-fill-failure-reason.pipe';
import { AngularCdkModule } from '@app/cdk/angular/angular.module';
import { ConsultationTypeComponent } from '@app/features/soap/components/consultation-type/consultation-type.component';
import { ConsultationTypeFormComponent } from './components/consultation-type-form/consultation-type-form.component';

@NgModule({
  declarations: [
    ObjectiveComponent,
    SubjectiveComponent,
    DurationFieldComponent,
    AssessmentComponent,
    AssessmentDiagnosisComponent,
    EsignComponent,
    BillingFieldComponent,
    SoapAutoFillFailureReasonPipe,
    ConsultationTypeComponent,
    ConsultationTypeFormComponent,
  ],
  exports: [
    ObjectiveComponent,
    SubjectiveComponent,
    DurationFieldComponent,
    AssessmentComponent,
    EsignComponent,
    AssessmentDiagnosisComponent,
    BillingFieldComponent,
    SoapAutoFillFailureReasonPipe,
    ConsultationTypeComponent,
    ConsultationTypeFormComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    MatSelectModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    TlhFormsModule,
    LayoutModule,
    AngularCdkModule,
  ],
})
export class SoapModule {}
