import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IcdSearchDialog } from './components/icd-search/icd-search.dialog';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  declarations: [IcdSearchDialog],
  exports: [IcdSearchDialog],
  entryComponents: [IcdSearchDialog],
  imports: [
    CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatListModule,
    MatTableModule,
    MatButtonModule,
    InfiniteScrollModule,
  ],
})
export class IcdModule {}
