import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalDirective } from './directive/portal.directive';
import { PortalHostDirective } from './directive/portal-host.directive';
import { PortalModule } from '@angular/cdk/portal';

@NgModule({
  declarations: [PortalDirective, PortalHostDirective],
  exports: [PortalModule, PortalDirective, PortalHostDirective],
  imports: [CommonModule, PortalModule],
})
export class AppPortalsModule {}
