import { legacyDefined } from '@app/cdk/angular/utils/nullable.utils';

export interface IState {
  id: number;
  name: string;
  fullName: string;
}

export class State implements IState {
  id!: number;
  name = '';
  fullName = '';

  constructor(values: Partial<IState> = {}) {
    this.id = legacyDefined(values.id) ? values.id! : this.id;
    this.name = legacyDefined(values.name) ? values.name! : this.name;
    this.fullName = legacyDefined(values.fullName) ? values.fullName! : this.fullName;
  }
}
