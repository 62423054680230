import { Inject, Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable, of } from 'rxjs';
import { IStyle } from '@app/shell/models/style/style';
import { StyleTypes } from '@app/shell/constants/style-types';
import { catchError, finalize, tap } from 'rxjs/operators';
import { IThemeColor } from '@app/shell/models/style/theme-color';
import { IStatusColor } from '@app/shell/models/style/status-color';
import {
  CONNECT_STATUS_TYPE_COLOR_CSS_CALL_REQUESTS_OPACITY,
  CONNECT_STATUS_TYPE_COLOR_CSS_ENCOUNTER_MANAGER_OPACITY,
  ConnectStatusColorTypes,
  ConnectStatusTypeColorCssVariable,
} from '@app/shell/constants/connect-status-color-types';
import { IValueResponse } from '@app/shell/models/common/value-response';
import { legacyDefined } from '@app/cdk/angular/utils/nullable.utils';
import { Platform } from '@angular/cdk/platform';
import { DEFAULT_STYLES, generatePaletteFromColor, hexToRgb, setPaletteVariables } from './customization.utils';
import { newLogger } from '@app/core/services/logger/logger.service';
import { DOCUMENT } from '@angular/common';
import { convertEnumToValues } from '@app/cdk/angular/utils/data.utils';

const logger = newLogger('PreloadCustomizationService');

const routes = {
  style: (type: string) => `/customization/style?type=${type}`,
  logoProperties: () => `/customization/logo/properties`,
  themeColors: () => `/customization/theme-colors`,
  connectStatusColors: () => `/customization/connect-status-colors`,
};

function setThemeColors(document: Document, themeColors: Array<IThemeColor>): void {
  themeColors.forEach(themeColor => {
    const palette = generatePaletteFromColor(themeColor.value);
    setPaletteVariables(document, themeColor.type.toLowerCase(), palette);
    document.documentElement.style.setProperty(`--${themeColor.type.toLowerCase()}-color-var`, themeColor.value);
  });
}

function setMainStyles(document: Document, style: IStyle): void {
  if (style.backgroundColor != undefined) {
    document.body.style.setProperty('--background-color-var', style.backgroundColor);
  }
  if (style.fontName != undefined) {
    document.body.style.setProperty('--main-font-family-var', style.fontName);
  }
  if (style.fontColor != undefined) {
    document.body.style.setProperty('--main-font-color-var', style.fontColor);
  }
  const rgbValue = hexToRgb(style.fontColor);
  if (rgbValue != undefined) {
    let styleValue = `rgba(${rgbValue.r}, ${rgbValue.g}, ${rgbValue.b}, 0.54)`;
    document.body.style.setProperty('--secondary-font-color-var', styleValue);
    styleValue = `rgba(${rgbValue.r}, ${rgbValue.g}, ${rgbValue.b}, 0.12)`;
    document.body.style.setProperty('--divider-font-color-var', styleValue);
    styleValue = `rgba(${rgbValue.r}, ${rgbValue.g}, ${rgbValue.b}, 0.42)`;
    document.body.style.setProperty('--underline-font-color-var', styleValue);
  }
}

function setLeftPanelStyles(document: Document, style: IStyle): void {
  if (style.backgroundColor != undefined) {
    document.documentElement.style.setProperty('--left-bar-color-var', style.backgroundColor);
  }
  if (style.fontName != undefined) {
    document.documentElement.style.setProperty('--left-bar-font-family-var', style.fontName);
  }
  if (style.fontColor != undefined) {
    document.documentElement.style.setProperty('--left-bar-font-color-var', style.fontColor);
  }
}

function setTopPanelStyles(document: Document, style: IStyle): void {
  if (style.backgroundColor != undefined) {
    document.documentElement.style.setProperty('--top-bar-color-var', style.backgroundColor);
  }
  if (style.fontName != undefined) {
    document.documentElement.style.setProperty('--top-bar-font-family-var', style.fontName);
  }
  if (style.fontColor != undefined) {
    document.documentElement.style.setProperty('--top-bar-font-color-var', style.fontColor);
  }
}

function setDisabledStyles(document: Document, style: IStyle): void {
  if (style.fontName != undefined) {
    document.documentElement.style.setProperty('--disabled-font-family-var', style.fontName);
  }
  if (style.fontColor != undefined) {
    document.documentElement.style.setProperty('--disabled-font-color-var', style.fontColor);
  }

  const fontColor = hexToRgb(style.fontColor);
  const bgColor = hexToRgb(style.backgroundColor);

  if (fontColor != undefined) {
    const controlFontColor = `rgba(${fontColor.r}, ${fontColor.g}, ${fontColor.b}, 0.5)`;
    document.documentElement.style.setProperty('--disabled-control-font-color-var', controlFontColor);
  }

  if (bgColor != undefined) {
    const controlBgColor = `rgba(${bgColor.r}, ${bgColor.g}, ${bgColor.b}, 0.12)`;
    const selectedControlBgColor = `rgba(${bgColor.r}, ${bgColor.g}, ${bgColor.b}, 0.16)`;
    document.documentElement.style.setProperty('--disabled-control-background-color-var', controlBgColor);
    document.documentElement.style.setProperty('--selected-control-background-color-var', selectedControlBgColor);
  }
}

function setLogoProperties(document: Document, style: IStyle): void {
  if (style.backgroundColor != undefined) {
    document.documentElement.style.setProperty('--logo-background-color-var', style.backgroundColor);
  }
}

function setStatusColors(document: Document, statusColors: Array<IStatusColor>): void {
  statusColors.forEach(statusColor => {
    let color = statusColor.color;
    const statusTypeName = convertEnumToValues(ConnectStatusColorTypes).find(
      variable => variable[0] === statusColor.status
    );
    const encounterManagerOpacity =
      statusTypeName == undefined ? undefined : CONNECT_STATUS_TYPE_COLOR_CSS_ENCOUNTER_MANAGER_OPACITY[statusTypeName];
    const rgbValue = hexToRgb(color);
    if (encounterManagerOpacity != undefined && rgbValue != undefined) {
      color = `rgba(${rgbValue.r}, ${rgbValue.g}, ${rgbValue.b}, ${encounterManagerOpacity})`;
    }

    if (statusTypeName != undefined) {
      const cssProperty = ConnectStatusTypeColorCssVariable[statusTypeName];
      document.documentElement.style.setProperty(
        `--encounter-manager-status-${cssProperty}-background-color-var`,
        color
      );
    }

    const callRequestsOpacity =
      statusTypeName == undefined ? undefined : CONNECT_STATUS_TYPE_COLOR_CSS_CALL_REQUESTS_OPACITY[statusTypeName];

    if (callRequestsOpacity != undefined && rgbValue != undefined) {
      color = `rgba(${rgbValue.r}, ${rgbValue.g}, ${rgbValue.b}, ${callRequestsOpacity})`;
    }

    if (statusTypeName != undefined) {
      const cssProperty = ConnectStatusTypeColorCssVariable[statusTypeName];
      document.documentElement.style.setProperty(`--call-requests-status-${cssProperty}-background-color-var`, color);
    }
  });
}

@Injectable({
  providedIn: 'root',
})
export class PreloadCustomizationService {
  constructor(private inj: Injector, private platform: Platform, @Inject(DOCUMENT) private document: Document) {}

  public getStyle(type: string): Observable<IStyle> {
    const httpClient = this.inj.get(HttpClient);
    return httpClient.get<IStyle>(routes.style(type));
  }

  public getLogoProperties(): Observable<IStyle> {
    const httpClient = this.inj.get(HttpClient);
    return httpClient.get<IStyle>(routes.logoProperties());
  }

  public getThemeColors(): Observable<Array<IThemeColor>> {
    const httpClient = this.inj.get(HttpClient);
    return httpClient.get<Array<IThemeColor>>(routes.themeColors());
  }

  public getStatusColors(): Observable<IValueResponse<IStatusColor>> {
    const httpClient = this.inj.get(HttpClient);
    return httpClient.get<IValueResponse<IStatusColor>>(routes.connectStatusColors());
  }

  public setStyles(styles: Array<IStyle | undefined | null>): void {
    const mainStyles = styles.find(style => legacyDefined(style) && style.type === StyleTypes.MAIN);
    if (mainStyles != undefined) {
      this.loadFont(mainStyles);
      setMainStyles(this.document, mainStyles);
    }
    const leftPanelStyles = styles.find(style => style != undefined && style.type === StyleTypes.LEFT_PANEL);
    if (leftPanelStyles != undefined) {
      this.loadFont(leftPanelStyles);
      setLeftPanelStyles(this.document, leftPanelStyles);
    }
    const topPanelStyles = styles.find(style => style != undefined && style.type === StyleTypes.TOP_PANEL);
    if (topPanelStyles != undefined) {
      this.loadFont(topPanelStyles);
      setTopPanelStyles(this.document, topPanelStyles);
    }
    const disabledStyles = styles.find(style => style != undefined && style.type === StyleTypes.DISABLED_CONTROLS);
    if (disabledStyles != undefined) {
      this.loadFont(disabledStyles);
      setDisabledStyles(this.document, disabledStyles);
    }
  }

  public loadStylesOnAppStart(): Promise<boolean> {
    if (!this.platform.isBrowser) {
      // By some reason any try to set style from JS during SSR leads to
      // ERROR TypeError: Cannot read property 'type' of undefined
      // at TokenStream.LA (C:\prs\tel\src\callcenter-frontend\dist\telehealth\server\main.js:1:171089)
      // at TokenStream.advance (C:\prs\tel\src\callcenter-frontend\dist\telehealth\server\main.js:1:169743)
      // at Parser._readDeclarations (C:\prs\tel\src\callcenter-frontend\dist\telehealth\server\main.js:1:203930)
      // at Parser.parseStyleAttribute (C:\prs\tel\src\callcenter-frontend\dist\telehealth\server\main.js:1:205519)
      // at parseStyles (C:\prs\tel\src\callcenter-frontend\dist\telehealth\server\main.js:1:463523)
      // at CSSStyleDeclaration.value (C:\prs\tel\src\callcenter-frontend\dist\telehealth\server\main.js:1:465251)
      // at setMainStyles (C:\prs\tel\src\callcenter-frontend\dist\telehealth\server\main.js:1:2854604)
      // Until it fixed do not set initial styles during SSR.
      return Promise.resolve(true);
    }

    return new Promise(resolve => {
      const getStyles$ = forkJoin(
        Object.values(StyleTypes).map(type => this.getStyle(type).pipe(catchError(() => of(DEFAULT_STYLES[type]))))
      ).pipe(
        tap(response => {
          this.setStyles(response);
        })
      );

      const getLogoProperties$ = this.getLogoProperties().pipe(
        tap(logoProperties => setLogoProperties(this.document, logoProperties)),
        catchError(() => of(null))
      );

      const getThemeColors$ = this.getThemeColors().pipe(
        tap(themeColors => setThemeColors(this.document, themeColors)),
        catchError(() => of(null))
      );

      const getStatusColors$ = this.getStatusColors().pipe(
        tap(statusColors => setStatusColors(this.document, statusColors.value)),
        catchError(() => of(null))
      );

      const loadAllStyles$ = forkJoin([getStyles$, getLogoProperties$, getThemeColors$, getStatusColors$]);

      loadAllStyles$.pipe(finalize(() => resolve(true))).subscribe();
    });
  }

  private loadFont(style: IStyle): void {
    if (!this.platform.isBrowser || style.fontName == undefined) {
      return;
    }
    const fontName = style.fontName;
    import('webfontloader')
      .then(fontLoader => {
        fontLoader.load({
          google: {
            families: [fontName],
          },
        });
      })
      .catch(error => logger.error('Cannot load font', fontName, error));
  }
}
