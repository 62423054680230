import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpXsrfTokenExtractor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@src/environments/environment';
import { Platform } from '@angular/cdk/platform';

function generateID(): string {
  return Math.random().toString(36).substring(2, 7);
}

function getSessionId(): string {
  const sessionID = sessionStorage.getItem('sessionID');
  if (sessionID == undefined || sessionID.length < 1 || sessionID === 'undefined') {
    sessionStorage.setItem('sessionID', generateID());
  }
  return sessionStorage.getItem('sessionID') as string;
}

/**
 * Prefixes all requests with `environment.serverUrl`.
 */
@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {
  instanceID = generateID();

  constructor(private readonly platform: Platform, private tokenService: HttpXsrfTokenExtractor) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const sessionHeader = this.platform.isBrowser ? `${getSessionId()}-${this.instanceID}` : `ssr-${this.instanceID}`;
    if (request.url.startsWith('/assets')) {
      return next.handle(request);
    }

    const token = this.tokenService.getToken();

    // Be careful not to overwrite an existing header of the same name.
    if (token !== null && !request.headers.has('X-XSRF-Token')) {
      request = request.clone({ headers: request.headers.set('X-XSRF-Token', token) });
    }

    request = request.clone({
      url: environment.serverUrl + request.url,
      setHeaders: {
        'X-App-Sess-ID': sessionHeader,
      },
    });

    return next.handle(request);
  }
}
