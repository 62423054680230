import { OverlayModule } from '@angular/cdk/overlay';
import { StepperOptions, STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationError, Router, RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ViolationError } from '@app/cdk/http/errors';
import { AuthenticationService } from '@app/core/services/authentication/authentication.service';
import { ErrorInput, InfoService } from '@app/core/services/global-info-modal/info.service';
import { SystemWideNavigationService } from '@app/core/services/navigation/system-wide-navigation.service';
import { PwaService } from '@app/core/services/pwa/pwa.service';
import { RouteCheckPointService } from '@app/core/services/route/route-check-point.service';
import { IconsModule } from '@app/icons.module';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressRouterModule } from 'ngx-progressbar/router';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { AuthGuard } from './core/guards/auth/auth.guard';
import { ForbiddenComponent } from './core/pages/forbidden/forbidden.component';
import { NotFoundComponent } from './core/pages/not-found/not-found.component';
import { SessionTimeOutComponent } from './core/pages/session-timeout/session-timeout.component';
import { SharedModule } from './shared/shared.module';
import { PreloadCustomizationService } from './shell/services/customization/preload-customization.service';

export function customizationServiceFactory(service: PreloadCustomizationService): () => Promise<boolean> {
  return () => service.loadStylesOnAppStart();
}

export function pwaServiceFactory(service: PwaService): () => Promise<boolean> {
  return () => service.init().then(() => true);
}

export function authenticationServiceFactory(service: AuthenticationService): () => Promise<boolean> {
  return () => service.init().then(() => true);
}

@NgModule({
  declarations: [AppComponent, NotFoundComponent, ForbiddenComponent, SessionTimeOutComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'wsc' }),
    FormsModule,
    HttpClientModule,
    HttpClientXsrfModule,
    CoreModule,
    SharedModule,
    AppRoutingModule,
    NgProgressRouterModule.withConfig({
      id: 'navigation-progress-bar',
    }),
    NgProgressModule,
    IconsModule,
    ToastrModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    RouterModule,
    OverlayModule,
  ],
  providers: [
    AuthGuard,
    PreloadCustomizationService,
    {
      provide: APP_INITIALIZER,
      useFactory: customizationServiceFactory,
      deps: [PreloadCustomizationService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: pwaServiceFactory,
      deps: [PwaService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: authenticationServiceFactory,
      deps: [AuthenticationService],
      multi: true,
    },
    // used to create a RouteCheckPointService instance
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => Promise.resolve(),
      deps: [RouteCheckPointService],
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'en-US' },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false } as StepperOptions,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private readonly router: Router,
    private readonly infoService: InfoService,
    private readonly systemWideNavigation: SystemWideNavigationService
  ) {
    this.handleUncaughtNavigationErrors();
  }

  private handleUncaughtNavigationErrors(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationError) {
        const error = event.error as ErrorInput | Error;
        if (error instanceof ViolationError) {
          const hasAccessDeniedError = error.response.violations?.some(v => v.errorCode.endsWith('08')) ?? false;
          if (hasAccessDeniedError) {
            setTimeout(() => {
              this.systemWideNavigation.navigateToForbidden();
            });
          }
        } else if (
          typeof error === 'object' &&
          'message' in error &&
          error.message.includes('Cannot match any routes')
        ) {
          this.systemWideNavigation.navigateToNotFound();
        } else {
          this.infoService.warn(event.error);
        }
      }
    });
  }
}
