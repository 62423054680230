import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-session-timeout',
  templateUrl: './session-timeout.component.html',
  styleUrls: ['./session-timeout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionTimeOutComponent {
  constructor(private route: ActivatedRoute) {}

  onOK(): void {
    window.open(this.route.snapshot.queryParamMap.get('url'), "_self");
  }
}
