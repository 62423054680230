import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IGlobalInfoModalData } from '@app/core/services/global-info-modal/global-info-modal-data';
import * as moment from 'moment-timezone';
import { MomentFormats } from '@app/shell/constants/moment-formats';

@Component({
  selector: 'app-global-info-modal',
  templateUrl: './global-info-modal.component.html',
  styleUrls: ['./global-info-modal.component.scss'],
})
export class GlobalInfoModalComponent implements OnInit {
  public date = moment.utc().tz(moment.tz.guess()).format(MomentFormats.MOMENT_FULL_DATE_UTC);

  constructor(
    public dialogRef: MatDialogRef<GlobalInfoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IGlobalInfoModalData
  ) {}

  ngOnInit() {}
}
