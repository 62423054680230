import * as tinycolor from 'tinycolor2';
import { legacyDefined } from '@app/cdk/angular/utils/nullable.utils';
import { IStyle } from '@app/shell/models/style/style';
import { StyleTypes } from '@app/shell/constants/style-types';

const DEFAULT_FONT = 'Didact Gothic';
export const DEFAULT_STYLES: Record<StyleTypes, IStyle> = {
  [StyleTypes.MAIN]: {
    type: StyleTypes.MAIN,
    fontName: DEFAULT_FONT,
    fontColor: '#58595B',
    backgroundColor: '#FFFFFF',
  },
  [StyleTypes.TOP_PANEL]: {
    type: StyleTypes.TOP_PANEL,
    fontName: DEFAULT_FONT,
    fontColor: '#EAF8FA',
    backgroundColor: '#0084A5',
  },
  [StyleTypes.LEFT_PANEL]: {
    type: StyleTypes.LEFT_PANEL,
    fontName: DEFAULT_FONT,
    fontColor: '#FFFFFF',
    backgroundColor: '#321459',
  },
  [StyleTypes.DISABLED_CONTROLS]: {
    type: StyleTypes.DISABLED_CONTROLS,
    fontName: DEFAULT_FONT,
    fontColor: '#000000',
    backgroundColor: '#D3D3D3',
  },
};

export interface PaletteColor {
  name: string;
  hex: string;
  contrast: string;
}

export interface Palette {
  50: PaletteColor;
  100: PaletteColor;
  200: PaletteColor;
  300: PaletteColor;
  400: PaletteColor;
  500: PaletteColor;
  600: PaletteColor;
  700: PaletteColor;
  800: PaletteColor;
  900: PaletteColor;
  A100: PaletteColor;
  A200: PaletteColor;
  A400: PaletteColor;
  A700: PaletteColor;
}

export function hexToRgb(hex: string | undefined): tinycolor.ColorFormats.RGB | null {
  if (hex == undefined) {
    return null;
  }
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return legacyDefined(result)
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

function multiply(rgb1: tinycolor.ColorFormats.RGB, rgb2: tinycolor.ColorFormats.RGB): tinycolor.Instance {
  rgb1.b = Math.floor((rgb1.b * rgb2.b) / 255);
  rgb1.g = Math.floor((rgb1.g * rgb2.g) / 255);
  rgb1.r = Math.floor((rgb1.r * rgb2.r) / 255);
  return tinycolor(`rgb ${rgb1.r} ${rgb1.g} ${rgb1.b}`);
}

function getColorObject(value: tinycolor.Instance, name: string): PaletteColor {
  const color = tinycolor(value);
  return {
    name: name,
    hex: color.toHexString(),
    contrast: color.isLight() ? '#000000' : '#ffffff',
  };
}

export function generatePaletteFromColor(hex: string): Palette {
  const baseLight = tinycolor('#ffffff');
  const baseDark = multiply(tinycolor(hex).toRgb(), tinycolor(hex).toRgb());
  const baseTriad = tinycolor(hex).tetrad();

  return {
    50: getColorObject(tinycolor.mix(baseLight, hex, 12), '50'),
    100: getColorObject(tinycolor.mix(baseLight, hex, 30), '100'),
    200: getColorObject(tinycolor.mix(baseLight, hex, 50), '200'),
    300: getColorObject(tinycolor.mix(baseLight, hex, 70), '300'),
    400: getColorObject(tinycolor.mix(baseLight, hex, 85), '400'),
    500: getColorObject(tinycolor.mix(baseLight, hex, 100), '500'),
    600: getColorObject(tinycolor.mix(baseDark, hex, 87), '600'),
    700: getColorObject(tinycolor.mix(baseDark, hex, 70), '700'),
    800: getColorObject(tinycolor.mix(baseDark, hex, 54), '800'),
    900: getColorObject(tinycolor.mix(baseDark, hex, 25), '900'),
    A100: getColorObject(tinycolor.mix(baseDark, baseTriad[3], 15).saturate(80).lighten(65), 'A100'),
    A200: getColorObject(tinycolor.mix(baseDark, baseTriad[3], 15).saturate(80).lighten(55), 'A200'),
    A400: getColorObject(tinycolor.mix(baseDark, baseTriad[3], 15).saturate(100).lighten(45), 'A400'),
    A700: getColorObject(tinycolor.mix(baseDark, baseTriad[3], 15).saturate(100).lighten(40), 'A700'),
  };
}

export function setPaletteVariables(document: Document, type: string, palette: Palette): void {
  document.documentElement.style.setProperty(`--${type}-color-var-50`, palette[50].hex);
  document.documentElement.style.setProperty(`--${type}-color-var-50-contrast`, palette[50].contrast);
  for (let i = 100; i <= 900; i += 100) {
    const key = i as keyof Palette;
    document.documentElement.style.setProperty(`--${type}-color-var-${i}`, palette[key].hex);
    document.documentElement.style.setProperty(`--${type}-color-var-${i}-contrast`, palette[key].contrast);
  }
  document.documentElement.style.setProperty(`--${type}-color-var-A100`, palette['A100'].hex);
  document.documentElement.style.setProperty(`--${type}-color-var-A200`, palette['A200'].hex);
  document.documentElement.style.setProperty(`--${type}-color-var-A400`, palette['A400'].hex);
  document.documentElement.style.setProperty(`--${type}-color-var-A700`, palette['A700'].hex);
  document.documentElement.style.setProperty(`--${type}-color-var-A100-contrast`, palette['A100'].contrast);
  document.documentElement.style.setProperty(`--${type}-color-var-A200-contrast`, palette['A200'].contrast);
  document.documentElement.style.setProperty(`--${type}-color-var-A400-contrast`, palette['A400'].contrast);
  document.documentElement.style.setProperty(`--${type}-color-var-A700-contrast`, palette['A700'].contrast);
}
