import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map, switchMap, throttleTime } from 'rxjs/operators';
import { ErrorFormatterService } from '../../services/error-formatter/error-formatter.service';
import { InfoService } from '../../services/global-info-modal/info.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { fromPromise } from 'rxjs/internal-compatibility';
import { ViolationsResponse } from '@app/cdk/http/errors';
import { ContextHttpParams, HttpContext } from '@app/cdk/http/context-http-params';
import { UnsupportedMediaTypeResponse } from '@app/core/interceptors/error-handler/unsupported-media-type-response';

const CUSTOM_MAINTENANCE_PAGE_HEADER = 'x-amz-meta-maintenancepage';

export interface Http503MayBeAutoRestoreError {
  autoRestore?: boolean;
  reasonDescription?: string;
}

interface NestedResponseError {
  message?: string;
  stacktrace?: string;
}

/**
 * Whether parameter is JSON passed as {@link Blob}.
 */
function isJsonBlob(error: unknown): error is Blob {
  return error instanceof Blob && error.type === 'application/json';
}

/**
 * Adds a default error handler to all requests.
 */
@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  private readonly awaitNavigation$ = new Subject<{ path: string; description?: string }>();

  constructor(private readonly injector: Injector) {
    this.awaitNavigation$.pipe(throttleTime(2000)).subscribe(param => {
      const router = this.injector.get<Router>(Router);
      void router.navigate(['auto-restore'], {
        queryParams: {
          message: param.description,
          location: encodeURIComponent(param.path),
        },
      });
    });
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const context = request.params instanceof ContextHttpParams ? request.params.context : undefined;

    const errorFormatterService = this.injector.get(ErrorFormatterService);

    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          switch (error.status) {
            case 401:
            case 403:
              return this.handleForbidden(error);
            case 400:
              return this.handleBadRequest(error, request, errorFormatterService);
            case 404:
              return this.handleNotFound(context, error);
            case 415:
              return ErrorHandlerInterceptor.handle415UnsupportedMediaType(error as UnsupportedMediaTypeResponse);
            case 500:
            case 502:
              return this.handle502(error);
            case 503:
              return this.handle503(error);
            default:
              throw this.rethrowJsonError(error);
          }
        } else {
          return throwError(error);
        }
      })
    );
  }

  private handleForbidden(error: HttpErrorResponse): Observable<HttpEvent<unknown>> {
    try {
      const res = error.error as NestedResponseError | undefined;
      if (res == undefined) {
        return throwError(JSON.stringify(error));
      }
      return throwError(res);
    } catch (e) {
      return throwError(JSON.stringify(error));
    }
  }

  private handle502(error: HttpErrorResponse): Observable<HttpEvent<unknown>> {
    if (typeof error.error === 'string' && /Redis\S*Exception/.test(error.error)) {
      // for some reasons backend handled redis error after headers sent
      // so redis exception added to already sent body in default servlet handler
      // match it explicitly and simulate auto restoration
      return this.handleAutoRestoreError({
        autoRestore: true,
        reasonDescription: 'Waiting for cache connection',
      });
    }

    const nestedError = error.error as NestedResponseError | undefined;
    this.injector.get(InfoService).openDialog(
      {
        title: 'Error',
        status: error.status,
        messageWithoutLabel: 'Something went wrong. Please go back to the previous page and try again.',
      },
      {
        title: 'Error',
        status: error.status,
        statusText: error.statusText,
        url: error.url ?? undefined,
        errorMessage: nestedError?.message,
      }
    );
    if (nestedError?.stacktrace != undefined) {
      let endOfLineIndex = nestedError.stacktrace.indexOf('\n');
      endOfLineIndex = nestedError.stacktrace.indexOf('\n', endOfLineIndex + 1);
      if (endOfLineIndex > -1) {
        nestedError.stacktrace = nestedError.stacktrace.substring(0, endOfLineIndex);
      }
    }
    throw JSON.stringify(error);
  }

  private handle503(error: HttpErrorResponse): Observable<HttpEvent<unknown>> {
    const isMaintenancePageUp = error.headers.get(CUSTOM_MAINTENANCE_PAGE_HEADER);
    if (isMaintenancePageUp === 'true') {
      window.location.reload();
      throw new Error('Maintenance page is up.');
    }

    const maybeAutoRestore = error.error as Http503MayBeAutoRestoreError;
    if (maybeAutoRestore.autoRestore === true) {
      return this.handleAutoRestoreError(maybeAutoRestore);
    }
    throw this.rethrowJsonError(error);
  }

  private handleNotFound(context: HttpContext | undefined, error: HttpErrorResponse): Observable<HttpEvent<unknown>> {
    if (context?.bypass404 === true) {
      return throwError(error);
    }
    throw new Error('Requested operation not available.');
  }

  private handleBadRequest(
    error: HttpErrorResponse,
    request: HttpRequest<unknown>,
    errorFormatterService: ErrorFormatterService
  ): Observable<HttpEvent<unknown>> {
    // Just throw formatted error for output in form or global modal.
    if (isJsonBlob(error.error)) {
      return this.handleViolationsAsBlob(error.error, 400, request, errorFormatterService);
    }

    const errors = errorFormatterService.getFormattedErrors(error, request);
    if (Array.isArray(errors)) {
      throw errors.join('\n');
    }
    return throwError(errors);
  }

  private static handle415UnsupportedMediaType(error: UnsupportedMediaTypeResponse): Observable<HttpEvent<unknown>> {
    const supportedTypes: string = error.error.supportedTypes.toString();
    throw new Error(`Given file type is unsupported. Supported types: ${supportedTypes}`);
  }

  private handleViolationsAsBlob(
    error: Blob,
    status: number,
    request: HttpRequest<unknown>,
    errorFormatterService: ErrorFormatterService
  ): Observable<never> {
    return fromPromise(error.text()).pipe(
      map(text => {
        const violations = JSON.parse(text) as ViolationsResponse;
        return { status, error: violations } as HttpErrorResponse;
      }),
      switchMap(violations => {
        const errors = errorFormatterService.getFormattedErrors(violations, request);
        if (Array.isArray(errors)) {
          throw errors.join('\n');
        }

        return throwError(errors);
      })
    );
  }

  private handleAutoRestoreError(maybeAutoRestore: Http503MayBeAutoRestoreError): Observable<HttpEvent<unknown>> {
    const location = this.injector.get<Location>(Location);
    const path = location.path(true);
    this.awaitNavigation$.next({
      path,
      description: maybeAutoRestore.reasonDescription,
    });
    return throwError(maybeAutoRestore);
  }

  /**
   * Legacy method provides error internals. Must be reviewed in future.
   */
  private rethrowJsonError(error: HttpErrorResponse | undefined): string {
    const nestedError = error?.error as NestedResponseError | undefined;
    if (nestedError?.stacktrace != undefined) {
      let endOfLineIndex: number = nestedError.stacktrace.indexOf('\n');
      endOfLineIndex = nestedError.stacktrace.indexOf('\n', endOfLineIndex + 1);
      if (endOfLineIndex > -1) {
        nestedError.stacktrace = nestedError.stacktrace.substring(0, endOfLineIndex);
      }
    }
    return JSON.stringify(error);
  }
}
