<h2 mat-dialog-title class="qa-modal-title">{{ data.title }}</h2>

<div mat-dialog-content>
  <p [innerHTML]="data.message"></p>
  <p *ngIf="data.checkText != undefined">
    <mat-checkbox class="qa-confirm-checkbox" [(ngModel)]="approved">
      <span [innerHTML]="data.checkText"></span>
    </mat-checkbox>
  </p>
</div>

<div mat-dialog-actions class="d-flex justify-content-center mb-n3">
  <button
    mat-flat-button
    [disabled]="!approved"
    class="confirm-button qa-yes"
    color="primary"
    (click)="closeDialog(true)"
  >
    {{ data.yesText }}
  </button>

  <button
    *ngIf="data.showCancel"
    mat-flat-button
    color="basic"
    (click)="closeDialog()"
    class="mr-3 qa-no"
  >
    {{ data.noText }}
  </button>
</div>
