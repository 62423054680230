import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  NOTIFICATION_DATA,
  NotificationInstanceRef,
  NotificationRef,
} from '@app/core/services/global-info-modal/notification-container/notification-container.model';
import { UpdateAvailable } from '@app/shell/components/version-update-checker/version-update.model';

export interface UpdateAvailableAction {
  type: 'dismiss' | 'activate';
}

@Component({
  selector: 'app-update-available-toast',
  templateUrl: './update-available-toast.component.html',
  styleUrls: ['./update-available-toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateAvailableToastComponent implements NotificationInstanceRef<UpdateAvailableAction> {
  constructor(
    readonly ref: NotificationRef<UpdateAvailableAction>,
    @Inject(NOTIFICATION_DATA) readonly data: UpdateAvailable
  ) {}

  handleActivateClick(): void {
    this.ref.action({ type: 'activate' }, true);
  }

  handlePostponeClick(): void {
    this.ref.action({ type: 'dismiss' }, true);
  }
}
