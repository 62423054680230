import { NgModule } from '@angular/core';
import { ModalConfirmComponent } from '@app/shell/shared/modal-confirm/modal-confirm.component';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatDialogModule, MatCheckboxModule, FormsModule],
  exports: [ModalConfirmComponent],
  declarations: [ModalConfirmComponent],
  providers: [],
  entryComponents: [ModalConfirmComponent],
})
export class ModalConfirmModule {}
