import { Injectable } from '@angular/core';
import { CurrentUser } from '@app/core/services/authentication/current-user';
import { MenuElement } from '@app/core/services/menu/menu-descriptor.model';

const DEFAULT_ORDER: MenuElement[] = [
  MenuElement.CALL_CENTER_DASHBOARD,
  MenuElement.SCHEDULING_ADMINISTRATION,
  MenuElement.CALL_REQUESTS_DASHBOARD,
  MenuElement.DOCTOR_FOLLOW_UPS_DASHBOARD,
  MenuElement.FOLLOW_UPS_DASHBOARD,
  MenuElement.ENCOUNTER_CREATE_FACILITIES_PAGE,
  MenuElement.CCNC_ENCOUNTER_MANAGER,
  MenuElement.ADMIN_ENCOUNTER_MANAGER,
  MenuElement.DOCTOR_ENCOUNTER_MANAGER,
  MenuElement.PATIENT_MANAGER,
  MenuElement.GLOBAL_EMPLOYEES_MANAGER,
  MenuElement.USERS_MANAGER,
  MenuElement.CORPORATION_MANAGER,
  MenuElement.FACILITY_MANAGER,
  MenuElement.DOCTOR_MANAGER,
  MenuElement.ROUNDINGS,
  MenuElement.DOCTOR_SCHEDULING,
  MenuElement.DOCTOR_SCHEDULING_BY_MONTH,
  MenuElement.CCNC_SCHEDULING,
  MenuElement.CCNC_SCHEDULING_BY_MONTH,
  MenuElement.LIVE_METRICS,
  MenuElement.GLOBAL_REPORTING,
  MenuElement.CCNC_REPORTING,
  MenuElement.APPLICATION_SETTINGS,
  MenuElement.PROFILE_SETTINGS,
];

const CORP_ORDER: MenuElement[] = [
  MenuElement.NURSE_DASHBOARD,
  MenuElement.NURSE_ENCOUNTER_CREATE_REGULAR_DIRECT_LINK,
  MenuElement.NURSE_ENCOUNTER_CREATE_CODE_BLUE_DIRECT_LINK,
  MenuElement.REPORTING,
  MenuElement.GLOBAL_REPORTING, // for adjust sort
  MenuElement.CCNC_REPORTING, // for adjust sort
  MenuElement.LIVE_METRICS,
  MenuElement.ROUNDINGS,
  MenuElement.ENCOUNTER_CREATE_WIZARD_PAGE,
  MenuElement.CORP_EMPLOYEES_MANAGER,
  MenuElement.CORPORATION_MANAGER, // for adjust sort
  MenuElement.CORP_ADMIN_FACILITY_MANAGER,
  MenuElement.FACILITY_ADMIN_FACILITY_MANAGER,
  MenuElement.FACILITY_MANAGER, // for adjust sort
  MenuElement.PROFILE_SETTINGS,
];

function getOrderList(user: CurrentUser): MenuElement[][] {
  if (user.calculatedCorporations.length > 0 || user.calculatedFacilities.length > 0) {
    return [CORP_ORDER, DEFAULT_ORDER];
  } else {
    return [DEFAULT_ORDER];
  }
}

/**
 * Easy merge alg with save original list orders.
 * Not so optimal on large arrays but it used only with short arrays and only at user initialisation phase.
 */
function mergeOrders(orders: MenuElement[][]): MenuElement[] {
  const addedSet = new Set<MenuElement>();
  const result: MenuElement[] = [];

  orders.forEach(orderArray => {
    orderArray.forEach((item, idx) => {
      if (addedSet.has(item)) {
        return;
      }

      addedSet.add(item);

      if (idx === 0) {
        result.push(item);
        return;
      }

      const resultIdx = result.indexOf(orderArray[idx - 1]);
      result.splice(resultIdx + 1, 0, item);
    });
  });

  return result;
}

@Injectable({
  providedIn: 'root',
})
export class MenuOrderService {
  constructor() {}

  sortMenuElements(user: CurrentUser, originSet: ReadonlySet<MenuElement>): readonly MenuElement[] {
    const orders = getOrderList(user);
    const order = mergeOrders(orders);
    const sorted = order.filter(e => originSet.has(e));
    const sortedSet = new Set(sorted);
    const unsorted = Array.from(originSet.values()).filter(e => !sortedSet.has(e));

    return [...sorted, ...unsorted];
  }
}
