import { Injectable } from '@angular/core';
import { Permission, permissionMap } from '@app/cdk/permission/models/permission';
import { map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CurrentUserService } from '@app/core/services/authentication/current-user.service';
import { Feature } from '@app/cdk/permission/models/feature';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor(private currentUserService: CurrentUserService) {}

  public hasFeature(feature: Feature): Observable<boolean> {
    return this.currentUserService.user$.pipe(
      map(user => user?.features ?? []),
      map(features => features.includes(feature)),
      shareReplay(1)
    );
  }

  public anyPermission(...permissions: Permission[]): Observable<boolean> {
    return this.currentUserService.user$.pipe(
      map(user => user?.roles ?? []),
      map(roles => {
        const grantedPermission = permissions.find(permission => {
          const permissionRoles = permissionMap[permission];
          return roles.find(role => permissionRoles.has(role)) !== undefined;
        });
        return grantedPermission !== undefined;
      }),
      shareReplay(1)
    );
  }
}
