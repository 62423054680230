import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AngularCdkModule } from '@app/cdk/angular/angular.module';
import { MatRadioModule } from '@angular/material/radio';
import { EncounterInsurancePartnersComponent } from '@app/features/encounter/components/encounter-insurance-partners/encounter-insurance-partners.component';
import { TlhFormsModule } from '@app/cdk/tlh-forms/tlh-forms.module';
import { EncounterInsurancePartnerYesNoSelectionComponent } from '@app/features/encounter/components/encounter-insurance-partner-yes-no-selection/encounter-insurance-partner-yes-no-selection.component';
import { ConnectConfirmedToggleComponent } from './components/connect-confirmed-toggle/connect-confirmed-toggle.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { EncounterStatusBackgroundDirective } from './directives/encounter-status-background.directive';
import { EncounterReassignmentDialog } from '@app/features/encounter/dialogs/encounter-reassignment/encounter-reassignment.dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { HasConnectAssignmentPipe, LastConnectAssignmentNamePipe } from './pipes/last-connect-assignment-name.pipe';
import { VideoConsentConfirmDialog } from '@app/features/encounter/dialogs/video-consent-confirm/video-consent-confirm-dialog.component';
import { EncounterEditingReasonDialog } from './dialogs/encounter-editing-reason-dialog/encounter-editing-reason-dialog';
import { SectionsModule } from '@app/cdk/sections/sections.module';
import { MatIconModule } from '@angular/material/icon';
import { LockerModule } from '@app/cdk/locker/locker.module';

@NgModule({
  declarations: [
    EncounterInsurancePartnersComponent,
    EncounterInsurancePartnerYesNoSelectionComponent,
    ConnectConfirmedToggleComponent,
    EncounterStatusBackgroundDirective,
    EncounterReassignmentDialog,
    LastConnectAssignmentNamePipe,
    HasConnectAssignmentPipe,
    VideoConsentConfirmDialog,
    EncounterEditingReasonDialog,
  ],
  exports: [
    EncounterInsurancePartnersComponent,
    EncounterInsurancePartnerYesNoSelectionComponent,
    ConnectConfirmedToggleComponent,
    EncounterStatusBackgroundDirective,
    EncounterReassignmentDialog,
    LastConnectAssignmentNamePipe,
    HasConnectAssignmentPipe,
  ],
  entryComponents: [EncounterReassignmentDialog, VideoConsentConfirmDialog],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    AngularCdkModule,
    MatRadioModule,
    TlhFormsModule,
    MatButtonToggleModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatSelectModule,
    MatButtonModule,
    SectionsModule,
    MatIconModule,
    LockerModule,
  ],
})
export class EncounterModule {}
