import { Injectable } from '@angular/core';
import { asyncScheduler, forkJoin, Observable, of, ReplaySubject } from 'rxjs';
import { AuthenticationApiService } from '@app/core/api/authentication-api/authentication-api.service';
import { debounceTime, distinctUntilChanged, map, observeOn, shareReplay, switchMap } from 'rxjs/operators';
import { PermissionService } from '@app/cdk/permission/services/permission.service';
import { FacilityService } from '@app/shell/services/facility/facility.service';
import { CurrentUserService } from '@app/core/services/authentication/current-user.service';
import { Role } from '@app/cdk/permission/models/permission';
import { ContextualPermission } from '@app/core/services/authentication/contextual-permission.model';
import { MenuDescriptor, MenuElement } from '@app/core/services/menu/menu-descriptor.model';
import { CurrentUser } from '@app/core/services/authentication/current-user';
import { MenuDefinitionService } from '@app/core/services/menu/menu-definition.service';
import { MenuOrderService } from '@app/core/services/menu/menu-order.service';

function computeCallCenterSpecificMenuItems(user: CurrentUser): Observable<ReadonlySet<MenuElement>> {
  if (!user.hasAnyRole(Role.CC_REP)) {
    return of(new Set<MenuElement>());
  }
  const result = new Set<MenuElement>();
  result.add(MenuElement.CALL_CENTER_DASHBOARD);
  return of(result);
}

function computeFacilityAdminSpecificMenuItems(user: CurrentUser): Observable<ReadonlySet<MenuElement>> {
  if (!user.hasAnyRole(Role.FACILITY_DOCTOR, Role.FACILITY_ADMIN, Role.FACILITY_MASTER_ADMIN)) {
    return of(new Set<MenuElement>());
  }
  const result = new Set<MenuElement>();
  result.add(MenuElement.FACILITY_ADMIN_FACILITY_MANAGER);
  result.add(MenuElement.PROFILE_SETTINGS);
  return of(result);
}

function computeNurseCoordinatorSpecificMenuItems(user: CurrentUser): Observable<ReadonlySet<MenuElement>> {
  if (!user.hasAnyRole(Role.ENCOUNTER_MANAGER)) {
    return of(new Set<MenuElement>());
  }
  const result = new Set<MenuElement>();
  result.add(MenuElement.FOLLOW_UPS_DASHBOARD);
  result.add(MenuElement.ENCOUNTER_CREATE_FACILITIES_PAGE);
  result.add(MenuElement.CCNC_ENCOUNTER_MANAGER);
  result.add(MenuElement.PATIENT_MANAGER);
  result.add(MenuElement.CCNC_SCHEDULING);
  result.add(MenuElement.CCNC_SCHEDULING_BY_MONTH);
  result.add(MenuElement.ROUNDINGS);
  result.add(MenuElement.LIVE_METRICS);
  result.add(MenuElement.CCNC_REPORTING);
  result.add(MenuElement.PROFILE_SETTINGS);
  return of(result);
}

function computeCorpAdminSpecificMenuItems(user: CurrentUser): Observable<ReadonlySet<MenuElement>> {
  if (!user.hasAnyRole(Role.CORPORATION_ADMIN, Role.CORP_MASTER_ADMIN)) {
    return of(new Set<MenuElement>());
  }
  const result = new Set<MenuElement>();
  result.add(MenuElement.PROFILE_SETTINGS);

  if (user.hasAnyRole(Role.CORP_MASTER_ADMIN)) {
    result.add(MenuElement.CORP_EMPLOYEES_MANAGER);
    result.add(MenuElement.CORP_ADMIN_FACILITY_MANAGER);
  }

  return of(result);
}

function computeGlobalAdminSpecificMenuItems(user: CurrentUser): Observable<ReadonlySet<MenuElement>> {
  if (!user.hasAnyRole(Role.TELEHEALTH_ADMIN, Role.SUB_ADMIN, Role.TECH_SUPPORT)) {
    return of(new Set<MenuElement>());
  }
  const result = new Set<MenuElement>();
  result.add(MenuElement.SCHEDULING_ADMINISTRATION);
  result.add(MenuElement.ADMIN_ENCOUNTER_MANAGER);
  result.add(MenuElement.PATIENT_MANAGER);
  result.add(MenuElement.CORPORATION_MANAGER);
  result.add(MenuElement.FACILITY_MANAGER);
  result.add(MenuElement.DOCTOR_MANAGER);
  result.add(MenuElement.ROUNDINGS);
  result.add(MenuElement.LIVE_METRICS);
  result.add(MenuElement.GLOBAL_REPORTING);
  result.add(MenuElement.APPLICATION_SETTINGS);
  result.add(MenuElement.PROFILE_SETTINGS);

  if (user.hasAnyRole(Role.TELEHEALTH_ADMIN, Role.TECH_SUPPORT)) {
    result.add(MenuElement.GLOBAL_EMPLOYEES_MANAGER);
    result.add(MenuElement.USERS_MANAGER);
  }

  return of(result);
}

function computeExternalDoctorSpecificMenuItems(user: CurrentUser): Observable<ReadonlySet<MenuElement>> {
  if (!user.hasAnyRole(Role.EXTERNAL_DOCTOR)) {
    return of(new Set<MenuElement>());
  }
  const result = new Set<MenuElement>();
  result.add(MenuElement.CALL_REQUESTS_DASHBOARD);
  result.add(MenuElement.PROFILE_SETTINGS);
  // ??
  // result.add(MenuElement.DOCTOR_FOLLOW_UPS_DASHBOARD);
  return of(result);
}

function mergeToOneSet(res: ReadonlySet<MenuElement>[]): ReadonlySet<MenuElement> {
  const sourceArray = res.reduce((acc, next) => {
    return [...acc, ...next];
  }, [] as MenuElement[]);
  return new Set(sourceArray);
}

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private readonly _initialized$ = new ReplaySubject<void>(1);
  public readonly initialized$ = this._initialized$.pipe(debounceTime(100), distinctUntilChanged(), shareReplay(1));
  public readonly menu$: Observable<MenuDescriptor>;

  constructor(
    private authApiService: AuthenticationApiService,
    private permissionService: PermissionService,
    private facilityService: FacilityService,
    private currentUserService: CurrentUserService,
    private menuDefinitionService: MenuDefinitionService,
    private menuOrderService: MenuOrderService
  ) {
    this.menu$ = this.currentUserService.user$.pipe(
      debounceTime(50),
      observeOn(asyncScheduler),
      switchMap(user => this.computeMenuItems(user)),
      shareReplay(1)
    );
  }

  notifyMenuInitialized(): void {
    this._initialized$.next();
  }

  private computeMenuItems(user: CurrentUser | undefined): Observable<MenuDescriptor> {
    if (user == undefined) {
      return of({
        elements: [],
      });
    }

    return forkJoin([
      this.computeNurseSpecificMenuItems(user),
      this.computeFacilityNurseSpecificMenuItems(user),
      this.computeInternalDoctorSpecificMenuItems(user),
      computeExternalDoctorSpecificMenuItems(user),
      computeCallCenterSpecificMenuItems(user),
      computeFacilityAdminSpecificMenuItems(user),
      computeNurseCoordinatorSpecificMenuItems(user),
      computeCorpAdminSpecificMenuItems(user),
      computeGlobalAdminSpecificMenuItems(user),
    ]).pipe(
      map(res => mergeToOneSet(res)),
      map(roleBasedSet => this.computePermissionBasedAccess(user, roleBasedSet)),
      map(menuElementsSet => this.menuOrderService.sortMenuElements(user, menuElementsSet)),
      map(menuElements => this.menuDefinitionService.definedMenuElements(menuElements)),
      map(menuElements => {
        return {
          elements: menuElements,
        };
      })
    );
  }

  private computeNurseSpecificMenuItems(user: CurrentUser): Observable<ReadonlySet<MenuElement>> {
    if (!user.hasAnyRole(Role.NURSE)) {
      return of(new Set<MenuElement>());
    }
    return this.facilityService.getInfoForNurse().pipe(
      map(nurseInfo => {
        const result = new Set<MenuElement>();
        result.add(MenuElement.NURSE_DASHBOARD);
        result.add(MenuElement.NURSE_ENCOUNTER_CREATE_REGULAR_DIRECT_LINK);
        if (nurseInfo.codeBlueEnabled) {
          result.add(MenuElement.NURSE_ENCOUNTER_CREATE_CODE_BLUE_DIRECT_LINK);
        }
        return result;
      })
    );
  }

  private computeFacilityNurseSpecificMenuItems(user: CurrentUser): Observable<ReadonlySet<MenuElement>> {
    if (!user.hasAnyRole(Role.FACILITY_NURSE)) {
      return of(new Set<MenuElement>());
    }

    return this.facilityService.getUserFacilities().pipe(
      map(facilities => {
        const result = new Set<MenuElement>();
        result.add(MenuElement.NURSE_DASHBOARD);
        result.add(MenuElement.NURSE_ENCOUNTER_CREATE_REGULAR_DIRECT_LINK);
        if (facilities.some(f => f.codeBlueEnabled)) {
          result.add(MenuElement.NURSE_ENCOUNTER_CREATE_CODE_BLUE_DIRECT_LINK);
        }
        return result;
      })
    );
  }

  private computeInternalDoctorSpecificMenuItems(user: CurrentUser): Observable<ReadonlySet<MenuElement>> {
    if (!user.hasAnyRole(Role.INTERNAL_DOCTOR)) {
      return of(new Set<MenuElement>());
    }
    return this.authApiService.hasFollowUpAccess().pipe(
      map(hasFollowUpAccess => {
        const result = new Set<MenuElement>();
        result.add(MenuElement.CALL_REQUESTS_DASHBOARD);
        result.add(MenuElement.ENCOUNTER_CREATE_FACILITIES_PAGE);
        result.add(MenuElement.DOCTOR_ENCOUNTER_MANAGER);
        result.add(MenuElement.DOCTOR_SCHEDULING);
        result.add(MenuElement.DOCTOR_SCHEDULING_BY_MONTH);
        result.add(MenuElement.PROFILE_SETTINGS);
        result.add(MenuElement.PATIENT_MANAGER);

        if (hasFollowUpAccess) {
          result.add(MenuElement.DOCTOR_FOLLOW_UPS_DASHBOARD);
        }

        return result;
      })
    );
  }

  private computePermissionBasedAccess(
    user: CurrentUser,
    roleBasedSet: ReadonlySet<MenuElement>
  ): ReadonlySet<MenuElement> {
    const permissionBasedSet = new Set<MenuElement>();

    if (
      user.resolvePermission(ContextualPermission.USERS_CAN_CREATE_ENCOUNTER).granted &&
      !user.hasAnyRole(Role.NURSE, Role.FACILITY_NURSE, Role.INTERNAL_DOCTOR, Role.CC_REP, Role.ENCOUNTER_MANAGER)
    ) {
      // except nurse because they have own wizard entrypoint,
      // internal doctors and ccnc have access through facilities page,
      // cc rep have access through facility search
      permissionBasedSet.add(MenuElement.ENCOUNTER_CREATE_WIZARD_PAGE);
    }

    if (user.resolvePermission(ContextualPermission.USERS_HAVE_ACCESS_TO_ROUNDING_LIST).granted) {
      permissionBasedSet.add(MenuElement.ROUNDINGS);
    }

    if (
      user.resolvePermission(ContextualPermission.USERS_HAVE_ACCESS_TO_REPORTS).granted &&
      !user.hasAnyRole(Role.ENCOUNTER_MANAGER, Role.TELEHEALTH_ADMIN, Role.SUB_ADMIN, Role.TECH_SUPPORT)
    ) {
      // except ccnc & admins because they have own reporting entrypoint
      permissionBasedSet.add(MenuElement.REPORTING);
    }

    if (user.resolvePermission(ContextualPermission.USERS_HAVE_ACCESS_TO_LIVE_METRICS).granted) {
      permissionBasedSet.add(MenuElement.LIVE_METRICS);
    }

    if (user.resolvePermission(ContextualPermission.USERS_HAVE_ACCESS_TO_ENCOUNTER_MANAGER).granted) {
      if (user.hasRole(Role.MD)) {
        permissionBasedSet.add(MenuElement.DOCTOR_ENCOUNTER_MANAGER);
      } else if (user.hasRole(Role.ENCOUNTER_MANAGER)) {
        permissionBasedSet.add(MenuElement.CCNC_ENCOUNTER_MANAGER);
      } else {
        permissionBasedSet.add(MenuElement.ADMIN_ENCOUNTER_MANAGER);
      }
    }

    return new Set([...roleBasedSet, ...permissionBasedSet]);
  }
}
