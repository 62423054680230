import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Facility, IFacility } from '../../models/facility/facility';
import { FacilityFilter, FacilityListConfig, IFacilityListConfig } from '../../configs/facility/facility-list-config';
import { FacilityCreate } from '../../models/facility/facility-create';
import { FacilityNurseInfo, IProfileFacility } from '@app/shell/models/facility/facility-nurse-info';
import { FacilityScheduleRawModel } from '@app/modules/admin/models/facility-schedule/facility-schedule-raw.model';
import {
  FacilityScheduleModel,
  IFacilitySchedule,
} from '@app/modules/admin/models/facility-schedule/facility-schedule.model';
import { FacilityTimezoneModel, IFacilityTimezone } from '@app/shell/models/facility/facility-timezone.model';
import { IValueResponse } from '@app/shell/models/common/value-response';
import { DoctorShort } from '@app/shell/models/employee/doctor-short';
import { FacilitySuggestion } from '@app/shell/models/facility/facility-suggestion';
import * as _ from 'lodash';
import { SearchConfig } from '@app/shell/configs/search/search-config';
import { HasName } from '@app/cdk/models/has-name';
import { FullNamedModel } from '@app/cdk/models/full-named-model';
import { CollectionResponse } from '@app/shell/models/common/collection-response';
import { ActiveInsurancePartnerView } from '@app/modules/admin/models/insurance-partner/active-insurance-partner.model';
import { InsurancePartnerView } from '@app/modules/admin/models/insurance-partner/insurance-partner.model';
import { LegacyPage, Page } from '@app/cdk/models/data/page.model';
import {
  writeApiBoolean,
  writeApiDateTime,
  writeApiInteger,
  writeApiPage,
  writeApiSort,
  writeApiString,
} from '@app/cdk/http/converters';
import { ListLoadEvent, ListLoadFunctionResult } from '@app/cdk/table/datasource/datasource/list-datasource.model';
import { Filter } from '@app/cdk/table/datasource/datasource/filtration/filtration.model';
import { ValueResponse } from '@app/shell/models/common/value-object-response';
import { DateTime } from 'luxon';
import { transformFullName } from '@app/cdk/angular/pipes/initials.pipe';
import { IRosterPatient, RosterSearchConfig } from '@app/modules/encounter-create/models/insurance-roster.model';
import {
  IRosterPccMatchConfirm,
  IRosterPccMatchRequest,
  IRosterPccMatchResult,
} from '@app/modules/encounter-create/models/lhp-match-step.model';

export interface IFacilityScheduleListResponse {
  value: Array<IFacilitySchedule>;
}

export interface IFacilityScheduleCreateResponse {
  value: number;
}

export interface IFacilityConnectAvailaibleResponse {
  value: boolean;
}

export interface IFacilityTimeZonesResponse {
  value: Array<IFacilityTimezone>;
}

const routes = {
  list: () => '/facilities',
  suggestion: (config: Partial<IFacilityListConfig>) =>
    `/facilities/autocomplete?` +
    `${_.toPairs(config)
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      .map(pair => `${pair[0]}=${pair[1] == undefined ? '' : pair[1]}`)
      .join('&')}`,
  autocomplete: () => '/facilities/autocomplete',
  create: () => '/facilities',
  update: (id: number) => `/facilities/${id}`,
  get: (id: number) => `/facilities/${id}`,
  getTimeZones: () => '/facilities/timezones',
  getProfileFacilities: () => '/profile/facilities',
  getSimpleFacility: (id: number) => `/facilities/simple/${id}`,
  schedule: (facilityId: number) => `/facilities/${facilityId}/schedules`,
  scheduleId: (scheduleId: number) => `/facilities/schedules/${scheduleId}`,
  connectAvailable: (facilityId: number) => `${routes.schedule(facilityId)}/connect-can-be-created`,
  getBannedDoctors: (facilityId: number) => `/facilities/${facilityId}/banned-doctors`,
  banDoctor: (facilityId: number, doctorId: number) => `/facilities/${facilityId}/banned-doctors/${doctorId}`,
  unbanDoctor: (facilityId: number, doctorId: number) => `/facilities/${facilityId}/banned-doctors/${doctorId}`,
  pcp: (facilityId: number, config: SearchConfig) =>
    `/facilities/${facilityId}/primary-care-physicians?` +
    `${_.toPairs(config)
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      .map(pair => `${pair[0]}=${pair[1]}`)
      .join('&')}`,
  insurancePartners: (facilityId: number) => `/facilities/${facilityId}/insurance-partners`,
  getExternalPcpSuggestions: (facilityId: number) => `/facilities/${facilityId}/external-pcp-suggestions`,
  getRosterPatients: (facilityId: number, partnerId: number) =>
    `${routes.insurancePartners(facilityId)}/${partnerId}/patients`,
  matchRosterPcc: (facilityId: number, partnerId: number, mrn: string) =>
    `${routes.getRosterPatients(facilityId, partnerId)}/${mrn}/match/PCC`,
  confirmPccMatch: (facilityId: number, partnerId: number, mrn: string, emrPatientId: string) =>
    `${routes.getRosterPatients(facilityId, partnerId)}/${mrn}/match/PCC/${emrPatientId}`,
};

@Injectable({
  providedIn: 'root',
})
export class FacilityService {
  constructor(private httpClient: HttpClient) {}

  /**
   * @deprecated use getFacilities
   */
  getList(config: FacilityListConfig): Observable<LegacyPage<IFacility, Facility>> {
    return this.httpClient
      .get<LegacyPage<IFacility, Facility>>(routes.list(), {
        params: new HttpParams({
          fromObject: {
            limit: typeof config.limit === 'string' ? config.limit : writeApiInteger(config.limit),
            offset: writeApiInteger(config.offset),
            order: config.order,
            search: writeApiString(config.search),
            sortBy: config.sortBy,
            city: config.city,
            corpId: writeApiInteger(config.corpId),
            facilityTypes: writeApiInteger(config.facilityTypes),
            states: writeApiInteger(config.states),
            bannedByDoctor: writeApiInteger(config.bannedByDoctor),
            permittedTitles: config.permittedTitles,
            categories: config.categories,
            metaType: writeApiString(config.metaType),
          },
        }),
      })
      .pipe(
        map(body => {
          body.objects = [];
          body.items.forEach(item => {
            body.objects?.push(new Facility(item));
          });
          return body;
        })
      );
  }

  getRosterPatients(request: RosterSearchConfig): Observable<IRosterPatient[]> {
    const { facilityId, partnerId, search } = request;

    return this.httpClient.get<IRosterPatient[]>(routes.getRosterPatients(facilityId, partnerId), {
      params: new HttpParams({
        fromObject: {
          search: writeApiString(search),
        },
      }),
    });
  }

  matchRosterPatient(request: IRosterPccMatchRequest): Observable<IRosterPccMatchResult> {
    const { facilityId, partnerId, mrn } = request;

    return this.httpClient.get<IRosterPccMatchResult>(routes.matchRosterPcc(facilityId, partnerId, mrn));
  }

  confirmPccMatch(request: IRosterPccMatchConfirm): Observable<IRosterPccMatchResult> {
    const { facilityId, partnerId, mrn, emrPatientId, matchType } = request;

    return this.httpClient.post<IRosterPccMatchResult>(
      routes.confirmPccMatch(facilityId, partnerId, mrn, emrPatientId),
      null,
      {
        params: new HttpParams({
          fromObject: {
            matchType,
          },
        }),
      }
    );
  }

  getSuggestions(event: ListLoadEvent<FacilityFilter>): Observable<ListLoadFunctionResult<FacilitySuggestion>>;
  getSuggestions(config: Partial<IFacilityListConfig>): Observable<Page<FacilitySuggestion>>;
  getSuggestions(
    event: Partial<IFacilityListConfig> | ListLoadEvent<FacilityFilter>
  ): Observable<Page<FacilitySuggestion>> | Observable<ListLoadFunctionResult<FacilitySuggestion>> {
    if ('page' in event && typeof event.page === 'object') {
      return this.httpClient.get<Page<FacilitySuggestion>>(routes.autocomplete(), {
        params: {
          ...writeApiPage(event.page),
          ...writeApiSort(event.sort),
          corpId: writeApiInteger(event.filter.data.corpId),
          metaType: writeApiString(event.filter.data.metaType),
          onlyDoctorApproved: writeApiBoolean(event.filter.data.onlyDoctorApproved),
        },
      });
    } else {
      const config = event as Partial<IFacilityListConfig>;
      config.search = encodeURIComponent(config.search ?? '');
      return this.httpClient.get<Page<FacilitySuggestion>>(routes.suggestion(config));
    }
  }

  getById(facilityId: number): Observable<Facility> {
    return this.httpClient.get<IFacility>(routes.get(facilityId)).pipe(map(body => new Facility(body)));
  }

  getSimpleFacilityById(facilityId: number): Observable<FacilityNurseInfo> {
    return this.httpClient
      .get<ValueResponse<FacilityNurseInfo>>(routes.getSimpleFacility(facilityId))
      .pipe(map(body => new FacilityNurseInfo(body.value)));
  }

  connectAvailable(facilityId: number, time?: DateTime): Observable<boolean> {
    return this.httpClient
      .get<IFacilityConnectAvailaibleResponse>(routes.connectAvailable(facilityId), {
        params: new HttpParams({
          fromObject: {
            effectiveTime: writeApiDateTime(time),
          },
        }),
      })
      .pipe(map(body => body.value));
  }

  getUserFacilities(): Observable<IProfileFacility[]> {
    return this.httpClient
      .get<{ value: IProfileFacility[] }>(routes.getProfileFacilities())
      .pipe(map(data => data.value));
  }

  getInfoForNurse(): Observable<FacilityNurseInfo> {
    return this.httpClient
      .get<ValueResponse<FacilityNurseInfo[]>>(routes.getProfileFacilities())
      .pipe(map(body => new FacilityNurseInfo(body.value[0])));
  }

  create(facility: FacilityCreate): Observable<IFacility | string> {
    return this.httpClient.post<IFacility>(routes.create(), facility);
  }

  update(facility: FacilityCreate): Observable<Facility> {
    return this.httpClient.put<IFacility>(routes.update(facility.id), facility).pipe(map(body => new Facility(body)));
  }

  getTimeZones(): Observable<Array<FacilityTimezoneModel>> {
    return this.httpClient
      .get<IFacilityTimeZonesResponse>(routes.getTimeZones())
      .pipe(map(body => body.value.map((timezone: IFacilityTimezone) => new FacilityTimezoneModel(timezone))));
  }

  createFacilitySchedule(facilitySchedule: FacilityScheduleRawModel, facilityId: number): Observable<number> {
    return this.httpClient
      .post<IFacilityScheduleCreateResponse>(routes.schedule(facilityId), facilitySchedule)
      .pipe(map(body => body.value));
  }

  updateFacilitySchedule(facilitySchedule: FacilityScheduleRawModel): Observable<number> {
    return this.httpClient
      .put(routes.scheduleId(facilitySchedule.id), facilitySchedule)
      .pipe(map(() => facilitySchedule.id));
  }

  deleteFacilitySchedule(scheduleId: number): Observable<boolean> {
    return this.httpClient.delete(routes.scheduleId(scheduleId)).pipe(map(() => true));
  }

  getFacilityScheduleList(facilityId: number): Observable<Array<FacilityScheduleModel>> {
    return this.httpClient
      .get<IFacilityScheduleListResponse>(routes.schedule(facilityId))
      .pipe(map(body => body.value.map((rawSchedule: IFacilitySchedule) => new FacilityScheduleModel(rawSchedule))));
  }

  getBannedDoctors(facilityId: number): Observable<Array<DoctorShort>> {
    return this.httpClient
      .get<IValueResponse<DoctorShort>>(routes.getBannedDoctors(facilityId))
      .pipe(map(response => response.value));
  }

  banDoctor(facilityId: number, doctorId: number): Observable<void> {
    return this.httpClient.put<void>(routes.banDoctor(facilityId, doctorId), {}).pipe();
  }

  unbanDoctor(facilityId: number, doctorId: number): Observable<void> {
    return this.httpClient.delete<void>(routes.banDoctor(facilityId, doctorId), {}).pipe();
  }

  getPcps(facilityId: number, config: SearchConfig): Observable<Page<HasName>> {
    return this.httpClient.get<Page<FullNamedModel>>(routes.pcp(facilityId, config)).pipe(
      map(value => ({
        ...value,
        items: value.items.map(item => ({
          id: item.id,
          name: transformFullName(item),
        })),
      }))
    );
  }

  getInsurancePartners(facilityId: number): Observable<ActiveInsurancePartnerView[]> {
    return this.httpClient
      .get<CollectionResponse<ActiveInsurancePartnerView>>(routes.insurancePartners(facilityId))
      .pipe(map(value => value.items));
  }

  getActiveFacilityPartners(facilityId: number): Observable<InsurancePartnerView[]> {
    return this.httpClient
      .get<CollectionResponse<ActiveInsurancePartnerView>>(routes.insurancePartners(facilityId))
      .pipe(map(value => value.items.filter(p => p.active).map(partner => ({ id: partner.id, name: partner.name }))));
  }

  getExternalPcpSuggestions(
    event: ListLoadEvent<Filter>,
    facilityId: number
  ): Observable<ListLoadFunctionResult<FullNamedModel>> {
    return this.httpClient.get<Page<FullNamedModel>>(routes.getExternalPcpSuggestions(facilityId), {
      params: new HttpParams({
        fromObject: {
          ...writeApiPage(event.page),
          ...writeApiSort(event.sort),
        },
      }),
    });
  }
}
