import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CurrentUserService } from '@app/core/services/authentication/current-user.service';
import { map, take } from 'rxjs/operators';
import { newLogger } from '@app/core/services/logger/logger.service';

const logger = newLogger('AuthResolveGuard');

@Injectable({
  providedIn: 'root',
})
export class AuthResolveGuard implements CanActivate {
  constructor(private currentUserService: CurrentUserService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    logger.info('Check User is present');
    return this.currentUserService.user$.pipe(
      take(1),
      map(user => {
        if (user != undefined) {
          logger.info('Have User, allow navigation');
          return true;
        }
        logger.info('Have NO User, redirect to login page');
        return this.router.createUrlTree(['/auth/login'], { queryParams: { return: state.url } });
      })
    );
  }
}
