import { INameItem } from '@app/shell/models/common/name-item';

// eslint-disable-next-line
export interface IDay extends INameItem {}

/**
 * Facility Schedule Days Names
 */
export namespace FacilityScheduleDays {
  export const SUNDAY: IDay = { id: 7, name: 'Sunday' };
  export const MONDAY: IDay = { id: 1, name: 'Monday' };
  export const TUESDAY: IDay = { id: 2, name: 'Tuesday' };
  export const WEDNESDAY: IDay = { id: 3, name: 'Wednesday' };
  export const THURSDAY: IDay = { id: 4, name: 'Thursday' };
  export const FRIDAY: IDay = { id: 5, name: 'Friday' };
  export const SATURDAY: IDay = { id: 6, name: 'Saturday' };

  export const mappedObject = {
    7: FacilityScheduleDays.SUNDAY,
    1: FacilityScheduleDays.MONDAY,
    2: FacilityScheduleDays.TUESDAY,
    3: FacilityScheduleDays.WEDNESDAY,
    4: FacilityScheduleDays.THURSDAY,
    5: FacilityScheduleDays.FRIDAY,
    6: FacilityScheduleDays.SATURDAY,
  };
}

export const facilityScheduleDaysList = [
  FacilityScheduleDays.SUNDAY,
  FacilityScheduleDays.MONDAY,
  FacilityScheduleDays.TUESDAY,
  FacilityScheduleDays.WEDNESDAY,
  FacilityScheduleDays.THURSDAY,
  FacilityScheduleDays.FRIDAY,
  FacilityScheduleDays.SATURDAY,
];
