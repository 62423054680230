export enum DoctorTitle {
  MD = 'MD',
  PA = 'PA',
  NP = 'NP',
}

export const DOCTOR_TITLE_BY_ID = new Map([
  [13, DoctorTitle.MD],
  [17, DoctorTitle.PA],
  [18, DoctorTitle.NP],
]);

export enum DoctorTitleId {
  /**
   * @deprecated Use string version only
   */
  MD_ID = 13,
}
