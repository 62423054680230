<mat-card>
  <h1 mat-card-title>Getting application ready</h1>
  <mat-card-content>
    <p *ngIf="message != undefined">{{message}}</p>
    <p>
      Please wait a few seconds, this page will be refreshed automatically
    </p>
    <mat-spinner *ngIf="!errored" color="primary"></mat-spinner>
    <p *ngIf="errored">
      Cannot await application to be ready, you can try to return manually
    </p>
  </mat-card-content>
  <mat-card-actions *ngIf="errored" align="end">
    <a mat-flat-button color="primary" [href]="target">
      Try to return to the App
    </a>
  </mat-card-actions>
</mat-card>
