import { Helpers } from '@app/shared/utils/helpers';
import { legacyDefined } from '@app/cdk/angular/utils/nullable.utils';

export interface IPhone {
  extension?: string | null;
  number?: string;
}

export class Phone implements IPhone {
  extension: string | null = null;
  number?: string;

  constructor(values: Partial<IPhone> = {}) {
    this.extension = legacyDefined(values.extension) ? values.extension! : this.extension;
    this.number = legacyDefined(values.number) ? Helpers.getRawPhoneNumber(values.number) : this.number;
  }

  get fullNumber(): string {
    return (this.extension ?? '') + (this.number ?? '');
  }
}
