import { Pipe, PipeTransform } from '@angular/core';
import { FullNamedModel } from '@app/cdk/models/full-named-model';

function initials(value: string | undefined): string {
  if (value == undefined) {
    return '';
  }
  return value.charAt(0) + '.';
}

export function transformInitials(value: Omit<FullNamedModel, 'id'>): string;
export function transformInitials(value: Omit<FullNamedModel, 'id'> | undefined): string | undefined;
export function transformInitials(value: Omit<FullNamedModel, 'id'> | undefined): string | undefined {
  if (value == undefined) {
    return undefined;
  }

  return `${initials(value.firstName)} ${initials(value.lastName)}`.trim();
}

@Pipe({
  name: 'initials',
})
export class InitialsPipe implements PipeTransform {
  transform(value: Omit<FullNamedModel, 'id'> | undefined): string | undefined {
    return transformInitials(value);
  }
}

export function transformFullName(
  value: Omit<FullNamedModel, 'id'>,
  reverseOrder?: boolean,
  separator?: string
): string;
export function transformFullName(
  value: Omit<FullNamedModel, 'id'> | undefined,
  reverseOrder?: boolean,
  separator?: string
): string | undefined;
export function transformFullName(
  value: Omit<FullNamedModel, 'id'> | undefined,
  reverseOrder?: boolean,
  separator?: string
): string | undefined {
  if (value == undefined) {
    return undefined;
  }

  const first = reverseOrder === true ? value.lastName : value.firstName;
  const second = reverseOrder === true ? value.firstName : value.lastName;
  return `${first ?? ''}${separator ?? ''} ${second ?? ''}`.trim();
}

@Pipe({
  name: 'fullName',
})
export class FullNamePipe implements PipeTransform {
  transform(
    value: Omit<FullNamedModel, 'id'> | undefined,
    reverseOrder?: boolean,
    separator?: string
  ): string | undefined {
    return transformFullName(value, reverseOrder, separator);
  }
}
