import { CanActivate, Router, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { CurrentUserService } from '@app/core/services/authentication/current-user.service';
import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { newLogger } from '@app/core/services/logger/logger.service';

const logger = newLogger('AuthGuard');

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private currentUser: CurrentUserService, private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> {
    logger.info('Check User is absent');
    return this.currentUser.user$.pipe(
      take(1),
      map(user => {
        if (user != undefined) {
          logger.info('User FOUND, redirect to App');
          return this.router.createUrlTree(['/']);
        }

        logger.info('User NOT found, proceed auth');
        return true;
      }),
      catchError(err => {
        logger.error(err);
        return of(false);
      })
    );
  }
}
