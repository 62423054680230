<p>
  Newest Webside Connect version #{{ data.current.appData.version }} was loaded in your browser tab.
</p>
<div class="actions">
  <button class="action qa-got-it" mat-flat-button (click)="handleGotItClick()">Got it!</button>
  <button
    *ngIf="showChangelog$ | async"
    class="action qa-whats-new"
    mat-flat-button
    (click)="handleWhatsNewClick()"
  >
    Changelog
  </button>
</div>
