<mat-card>
  <mat-card-content class="content text-center">
    <app-logo class="logo"></app-logo>

    <div *ngIf="hasError">
      <h3>An error occurred, here are the&nbsp;details:</h3>
    </div>
    <h3 class="success-header" [class.error-message]="hasError">{{ message }}</h3>

    <ng-container *ngIf="hasError; else redirect">
      <h3>
        Please try to&nbsp;log in to&nbsp;PointClickCare again. If the&nbsp;issue repeats, please
        contact the&nbsp;technical support and&nbsp;share the&nbsp;error message you are getting
        and&nbsp;the&nbsp;time when it has&nbsp;occurred.
      </h3>
      <button class="force-navigate" mat-button (click)="handleReturnManual()">
        Click here for return to Webside
      </button>
    </ng-container>

    <ng-template #redirect>
      <ng-container *ngIf="timer$ | async as timeout; else redirecting">
        <h3 class="redirect-header">Redirecting to the Webside in {{ timeout }}</h3>
      </ng-container>
      <ng-template #redirecting>
        <h3 class="redirect-header">
          If nothing happened, please close this tab manually, or click "Navigate to Webside" to
          open Webside App.
        </h3>
        <a class="force-navigate" mat-button routerLink="/"> Navigate to Webside </a>
      </ng-template>
    </ng-template>
  </mat-card-content>
</mat-card>
