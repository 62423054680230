import * as moment from 'moment';
import { isMoment } from 'moment';
import { IFacilityRawInterval } from '@app/modules/admin/models/facility-schedule/facility-schedule-raw.model';

export interface IIntervalElement {
  startTime: moment.Moment;
  endTime: moment.Moment;
}

export const defaultIntervals: Array<IFacilityRawInterval> = [
  { startTime: { hours: 0, minutes: 0 }, endTime: { hours: 6, minutes: 59 } },
  { startTime: { hours: 19, minutes: 0 }, endTime: { hours: 23, minutes: 59 } },
];

export class FacilityScheduleIntervalModel implements IIntervalElement {
  startTime: moment.Moment = moment().startOf('day');
  endTime: moment.Moment = moment().startOf('day');

  constructor(values: Partial<IFacilityRawInterval | IIntervalElement> = {}) {
    if (values.startTime) {
      if (isMoment(values.startTime)) {
        this.startTime = values.startTime;
      } else {
        this.startTime.hours(values.startTime.hours);
        this.startTime.minutes(values.startTime.minutes);
      }
    }
    if (values.endTime) {
      if (isMoment(values.endTime)) {
        this.endTime = values.endTime;
      } else {
        this.endTime.hours(values.endTime.hours);
        this.endTime.minutes(values.endTime.minutes);
      }
    }
  }
}
