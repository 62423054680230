import { Role } from '@app/cdk/permission/models/permission';
import { DOCTOR_TITLE_BY_ID, DoctorTitle } from '@app/features/employee/doctor-title.model';
import { CorporationTeamView } from '@app/features/corporation/models/corporation-team.model';
import { ProfileInfo } from '@app/shell/models/employee/profile-info-impl';
import { ITitle } from '@app/shell/models/employee/title';
import {
  ContextualPermission,
  ContextualPermissionMode,
  PermissionResolution,
} from '@app/core/services/authentication/contextual-permission.model';
import * as _ from 'lodash';
import { FacilityMetaType } from '@app/shell/models/facility/facility-meta-type.enum';
import { HasName } from '@app/cdk/models/has-name';
import { UserSettingType } from '@app/cdk/permission/models/user-setting';
import { UserCorpAgreement } from '@app/core/services/authentication/user-corp-agreement.model';
import { Feature } from '@app/cdk/permission/models/feature';

export const FACILITY_AND_CORPORATION_MANAGERS = new Set([
  Role.FACILITY_DOCTOR,
  Role.FACILITY_MASTER_ADMIN,
  Role.FACILITY_ADMIN,
  Role.CORPORATION_ADMIN,
  Role.CORP_MASTER_ADMIN,
]);

function transformRoles(roles: HasName<Role>[] | undefined): readonly Role[] {
  if (roles == undefined) {
    return [];
  }
  return roles.map(role => role.id);
}

function transformTitle(title: ITitle | undefined): DoctorTitle | undefined {
  if (title == undefined) {
    return undefined;
  }
  return DOCTOR_TITLE_BY_ID.get(title.id);
}

/* eslint-disable no-shadow */
/**
 * Login source for user session. Used for separate profile loading right after login
 * and profile loading during application load from existing session
 */
export enum LoginSource {
  LOGIN = 'LOGIN',
  SESSION = 'SESSION',
}
/* eslint-enable no-shadow */

export interface CurrentUser {
  readonly id: number;
  readonly firstName: string;
  readonly lastName: string;
  readonly username: string;
  readonly email: string;
  readonly roles: readonly Role[];
  readonly features: readonly Feature[];
  readonly doctorTitle?: DoctorTitle;
  readonly calculatedCorporations: number[];
  readonly calculatedFacilities: number[];
  readonly contextualPermissions: ContextualPermission[];
  readonly availableMetaTypes: ReadonlySet<FacilityMetaType>;
  readonly settings: ReadonlySet<UserSettingType>;
  readonly source: LoginSource;
  readonly corpAgreement?: UserCorpAgreement;

  /**
   * NOT MD, ONLY CORPORATION MEMBERS
   */
  readonly primaryCarePhysician: boolean;
  readonly team?: CorporationTeamView;

  readonly facilityDoctor: boolean;
  readonly externalHomeUrl: string;

  hasRole(role: Role): boolean;

  hasAnyRole(...role: Role[]): boolean;

  resolvePermission<T extends ContextualPermission>(permission: T): PermissionResolution<T>;

  hasDoctorTitle(title: DoctorTitle): boolean;

  hasAnyDoctorTitle(...titles: DoctorTitle[]): boolean;

  hasSetting(setting: UserSettingType): boolean;

  hasAnySetting(...setting: UserSettingType[]): boolean;
}

export class CurrentUserImpl implements CurrentUser {
  readonly id: number = this.profileInfo.id;

  readonly email: string = this.profileInfo.email;
  readonly username: string = this.profileInfo.username;
  readonly firstName: string = this.profileInfo.firstName;
  readonly lastName: string = this.profileInfo.lastName;

  readonly roles: readonly Role[] = transformRoles(this.profileInfo.roles);
  readonly features: readonly Feature[] = this.profileInfo.features;
  readonly settings: ReadonlySet<UserSettingType> = new Set(this.profileInfo.derivedSettings ?? []);
  readonly contextualPermissions: ContextualPermission[] = this.profileInfo.contextualPermissions ?? [];
  readonly permissionModes: Partial<Record<ContextualPermission, ContextualPermissionMode[]>> =
    this.profileInfo.permissionModes ?? {};

  readonly calculatedCorporations: number[] = this.profileInfo.calculatedCorporations;
  readonly calculatedFacilities: number[] = this.profileInfo.calculatedFacilities;
  readonly availableMetaTypes: ReadonlySet<FacilityMetaType> = new Set(this.profileInfo.availableMetaTypes);

  readonly primaryCarePhysician: boolean = this.profileInfo.primaryCarePhysician;
  readonly team?: CorporationTeamView = this.profileInfo.team;
  readonly doctorTitle?: DoctorTitle = transformTitle(this.profileInfo.title);
  readonly externalHomeUrl: string = this.profileInfo.externalHomeUrl;

  readonly corpAgreement?: UserCorpAgreement = this.profileInfo.corpAgreement;

  private readonly roleSet = new Set(this.roles);
  private readonly contextualPermissionSet = new Set(this.contextualPermissions);
  private readonly contextualPermissionModes = new Map<ContextualPermission, ReadonlySet<ContextualPermissionMode>>(
    _.toPairs(this.permissionModes).map(pair => [pair[0] as ContextualPermission, new Set(pair[1] ?? [])])
  );

  constructor(private readonly profileInfo: ProfileInfo, readonly source: LoginSource) {}

  hasAnyRole(...roles: Role[]): boolean {
    return roles.some(role => this.hasRole(role));
  }

  hasRole(role: Role): boolean {
    return this.roleSet.has(role);
  }

  resolvePermission<T extends ContextualPermission>(permission: T): PermissionResolution<T> {
    if (!this.contextualPermissionSet.has(permission)) {
      return {
        granted: false,
      };
    }

    return {
      granted: true,
      modes: this.contextualPermissionModes.get(permission) ?? new Set(),
    };
  }

  get facilityDoctor(): boolean {
    return this.hasAnyRole(...FACILITY_AND_CORPORATION_MANAGERS);
  }

  hasDoctorTitle(title: DoctorTitle): boolean {
    return this.hasRole(Role.INTERNAL_DOCTOR) && this.doctorTitle === title;
  }

  hasAnyDoctorTitle(...titles: DoctorTitle[]): boolean {
    if (!this.hasRole(Role.INTERNAL_DOCTOR) || this.doctorTitle == undefined) {
      return false;
    }
    const titleSet = new Set(titles);
    return titleSet.has(this.doctorTitle);
  }

  hasAnySetting(...settings: UserSettingType[]): boolean {
    return settings.some(setting => this.hasSetting(setting));
  }

  hasSetting(setting: UserSettingType): boolean {
    return this.settings.has(setting);
  }
}
