import { ServerViolations } from '@app/cdk/tlh-forms/validation-errors/validation-error.pipe';
import * as _ from 'lodash';

export interface ConstraintViolation {
  key: string;
  message: string;
}

export interface ViolationBinding {
  path: string;
  violation: ConstraintViolation;
}

export class PropertyBinding<F> {
  constructor(public readonly path: string, private type: TypeBinding<F>) {}

  violation(...violation: ConstraintViolation[]): TypeBinding<F> {
    violation.forEach(v => {
      this.type.violations.set(v.key, {
        path: this.path,
        violation: v,
      });
    });

    return this.type;
  }
}

export class TypeBinding<F> {
  public readonly violations = new Map<string, ViolationBinding>();

  property<K extends keyof F>(key: K): PropertyBinding<F>;
  property<K1 extends keyof F, K2 extends keyof Required<F>[K1]>(k1: K1, k2: K2): PropertyBinding<F>;
  property<K1 extends keyof F, K2 extends keyof Required<F>[K1], K3 extends keyof Required<Required<F>[K1]>[K2]>(
    k1: K1,
    k2: K2,
    k3: K3
  ): PropertyBinding<F>;
  property(...path: string[]): PropertyBinding<F> {
    return new PropertyBinding<F>(path.join('.'), this);
  }

  self(): PropertyBinding<F> {
    return new PropertyBinding<F>('$self', this);
  }
}

export function bind<F>(): TypeBinding<F> {
  return new TypeBinding<F>();
}

export interface ViolationsResponse {
  violations?: {
    error: string;
    errorCode: string;
    description: string;
  }[];
}

export class ViolationError extends Error {
  constructor(public readonly response: ViolationsResponse) {
    super();
  }
}

export function transformViolations<F>(
  error: Error | undefined,
  binding: TypeBinding<F>,
  fallback: (e: Error | undefined) => void
): ServerViolations | undefined {
  if (!(error instanceof ViolationError)) {
    fallback(error);
  }
  const response = (error as ViolationError).response;
  if (response == undefined || response.violations == undefined || response.violations.length === 0) {
    return {};
  }

  return (
    _.chain(response.violations)
      .map(violation => {
        const bindInfo = binding.violations.get(violation.error);
        if (bindInfo == undefined) {
          return undefined;
        }
        return bindInfo;
      })
      .filter(value => value != undefined)
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .groupBy(violation => violation!.path)
      .toPairs()
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .map(([key, violations]) => [key, { violations: violations.map(v => v!.violation.message) }])
      .fromPairs()
      .value()
  );
}
