import { EhrProvider } from '@app/features/ehr-commons/models/ehr-provider.model';

export enum AccessDeniedReason {
  CSRF = 'CSRF',
  BAD_CREDENTIALS = 'BAD_CREDENTIALS',
  OTHER = 'OTHER',
}

export interface AccessDeniedResponse {
  reason: AccessDeniedReason;
  message: string;
}

export interface EmrSystemErrorResponse {
  statusCode: number;
  emrSystem: EhrProvider;
}
