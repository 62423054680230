import { Injectable } from '@angular/core';
import { ActivationStart, Router } from '@angular/router';
import { CheckPoint } from '@app/shell/constants/check-point';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RouteCheckPointService {
  // TODO: use constants for checkpoints, not routes. Routes MUST be calculated dynamically.
  private _checkPoint?: CheckPoint;

  constructor(private router: Router) {
    this.router.events
      .pipe(
        filter(event => event instanceof ActivationStart),
        map(event => event as ActivationStart)
      )
      .subscribe((event: ActivationStart) => {
        const { originCheckPoint, destinationCheckPoint } = event.snapshot.data as {
          originCheckPoint?: CheckPoint;
          destinationCheckPoint?: CheckPoint;
        };
        if (originCheckPoint != undefined) {
          this._checkPoint = originCheckPoint;
          return;
        }

        if (destinationCheckPoint != undefined) {
          this._checkPoint = this._checkPoint === destinationCheckPoint ? this._checkPoint : undefined;
          return;
        }

        this._checkPoint = undefined;
      });
  }

  getCheckPoint(): CheckPoint | undefined {
    return this._checkPoint;
  }

  navigateToCheckPoint(): void {
    if (this._checkPoint != undefined) {
      void this.router.navigate([this._checkPoint]);
    }
  }
}
