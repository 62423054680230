import { Pipe, PipeTransform } from '@angular/core';
import { ChangelogType } from '@app/shell/models/version/changelog';

@Pipe({
  name: 'componentName',
})
export class ComponentNamePipe implements PipeTransform {
  transform(value: ChangelogType): unknown {
    switch (value) {
      case 'frontend':
        return 'Web Application';
      case 'api-server':
        return 'API Services';
    }
  }
}
