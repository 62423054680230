import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AccessDeniedReason, AccessDeniedResponse } from './access-denied-response.model';

/**
 * This interceptor automatically repeat request if server returned CSRF error with new cookie.
 */
@Injectable()
export class CsrfAutoRepeatInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(error => {
        const body: unknown = error instanceof HttpErrorResponse ? error.error : error;
        if (typeof body === 'object' && body != undefined && 'reason' in body) {
          if ((body as AccessDeniedResponse).reason === AccessDeniedReason.CSRF) {
            return next.handle(request); // just repeat csrf error once
          }
        }
        return throwError(error);
      })
    );
  }
}
