import { Component, OnInit } from '@angular/core';
import { Logger } from '@app/core/services/logger/logger.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MOBILE_MAX_WIDTH_MEDIA } from '@app/cdk/layout/model/breakpoints.model';
import { map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';

const log = new Logger('App');

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isMobile$: Observable<boolean>;
  notMobile$: Observable<boolean>;

  constructor(private breakpoint: BreakpointObserver) {
    this.isMobile$ = breakpoint.observe(MOBILE_MAX_WIDTH_MEDIA).pipe(
      map(b => b.matches),
      shareReplay(1)
    );
    this.notMobile$ = this.isMobile$.pipe(
      map(m => !m),
      shareReplay(1)
    );
  }

  ngOnInit(): void {
    log.debug('init');
  }
}
