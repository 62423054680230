<div class="d-flex justify-content-between align-items-center mb-3">
  <h2 class="mb-0" mat-dialog-title>About Webside Connect</h2>
  <button
    mat-button
    matSuffix
    mat-icon-button
    [mat-dialog-close]="true"
    aria-label="Clear"
    class="close-button"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content class="dialog-content">
  <app-logo class="logo"></app-logo>

  <mat-list class="versions">
    <mat-list-item
      *ngFor="let changelog of changelogs$ | async; trackBy: changelogTrack"
      class="version"
      [class.selected]="changelog.type === selected?.type"
      (click)="selected = changelog"
    >
      <div mat-line>
        {{ changelog.type | componentName }}: v.{{
          changelog.versionExtended == undefined ? changelog.version : changelog.versionExtended
        }}
      </div>
      <div mat-line>Build time: {{ changelog.date | shortDateTime }} (local)</div>
    </mat-list-item>
  </mat-list>
  <div class="changelog-container">
    <p class="changelog">
      <ng-container *ngIf="selected != undefined">{{ selected?.text }}</ng-container>
    </p>
  </div>
</mat-dialog-content>
