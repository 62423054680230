import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListDatasourceDirective } from './datasource/list-datasource.directive';
import { MatPaginationDriverDirective } from './datasource/pagination/mat-pagination-driver.directive';
import { MatSortDriverDirective } from './datasource/sort/mat-sort-driver.directive';
import { RouterParametersStoreDirective } from './datasource/store/router-parameters-store.directive';
import { FiltrationDriverDirective } from './datasource/filtration/filtration-driver.directive';
import { FilterAdapterDirective } from './datasource/filtration/filter-adapter.directive';
import { MatPaginatorAdapterDirective } from './datasource/pagination/mat-paginator-adapter.directive';
import { LoadMoreAdapterDirective } from './datasource/pagination/load-more-adapter.directive';
import { LoadPrevAdapterDirective } from '@app/cdk/table/datasource/datasource/pagination/load-prev-adapter.directive';
import { MatSortAdapterDirective } from './datasource/sort/mat-sort-adapter.directive';
import { ClearSuffixComponent } from './datasource/filtration/filters/clear-suffix/clear-suffix.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [
    ListDatasourceDirective,
    MatPaginationDriverDirective,
    MatSortDriverDirective,
    RouterParametersStoreDirective,
    FiltrationDriverDirective,
    FilterAdapterDirective,
    MatPaginatorAdapterDirective,
    LoadMoreAdapterDirective,
    LoadPrevAdapterDirective,
    MatSortAdapterDirective,
    ClearSuffixComponent,
  ],
  exports: [
    ListDatasourceDirective,
    MatPaginationDriverDirective,
    MatSortDriverDirective,
    RouterParametersStoreDirective,
    FilterAdapterDirective,
    FiltrationDriverDirective,
    MatPaginatorAdapterDirective,
    LoadPrevAdapterDirective,
    LoadMoreAdapterDirective,
    MatSortAdapterDirective,
    ClearSuffixComponent,
  ],
  imports: [CommonModule, MatIconModule, MatButtonModule],
})
export class DatasourceModule {}
