import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StringPipe } from './pipes/string.pipe';
import { ImgFallbackDirective } from './directives/img-fallback.directive';
import { LinkButtonDirective } from './directives/link-button.directive';
import { ShortDatePipe } from './pipes/short-date.pipe';
import { EmptyPlaceholderPipe } from './pipes/empty-placeholder.pipe';
import { IfEmptyPipe } from '@app/cdk/angular/pipes/if-empty';
import { TimeOnlyPipe } from './pipes/time-only.pipe';
import { YOrEmptyPipe } from './pipes/y-or-empty.pipe';
import { FullNamePipe, InitialsPipe } from './pipes/initials.pipe';
import { DevDirective } from './dev/dev.directive';
import { GenderPipe } from './pipes/gender.pipe';
import { DurationPipe } from './pipes/duration.pipe';
import { FormattedJsonPipe } from './pipes/formatted-json.pipe';
import { PhoneNumberPipe } from './pipes/phone-number.pipe';
import { MatDialogActionsAlignDirective } from './directives/mat-dialog-actions-align.directive';
import { AgePipe } from './pipes/age.pipe';
import { UrlPipe } from '@app/cdk/angular/pipes/url.pipe';
import { ShortDateTimePipe } from '@app/cdk/angular/pipes/short-date-time.pipe';
import { ElapsedTimePipe } from '@app/cdk/angular/pipes/elapsed-time.pipe';
import { MultiTzInfoComponent } from './components/multi-tz-info/multi-tz-info.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MultiTzPanelComponent } from './components/multi-tz-info/multi-tz-panel/multi-tz-panel.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatListModule } from '@angular/material/list';
import { MultiTzCustomValueDirective } from './components/multi-tz-info/multi-tz-custom-value.directive';
import { MiddleMonthDayPipe } from '@app/cdk/angular/pipes/middle-month-day.pipe';
import { PrefixedPipe } from './pipes/prefixed.pipe';
import { AdaptiveButtonComponent } from './components/adaptive-button/adaptive-button.component';
import { WithTitlePipe } from '@app/cdk/angular/pipes/with-title.pipe';
import { CenteredMatTabsDirective } from './components/tabs/centered-mat-tabs.directive';
import { ConvertTimezonePipe } from './pipes/convert-timezone.pipe';
import { FileSizePipe } from '@app/cdk/angular/pipes/file-size.pipe';

@NgModule({
  declarations: [
    StringPipe,
    ImgFallbackDirective,
    LinkButtonDirective,
    ShortDatePipe,
    EmptyPlaceholderPipe,
    IfEmptyPipe,
    TimeOnlyPipe,
    YOrEmptyPipe,
    InitialsPipe,
    FullNamePipe,
    DevDirective,
    GenderPipe,
    ElapsedTimePipe,
    FormattedJsonPipe,
    PhoneNumberPipe,
    MatDialogActionsAlignDirective,
    AgePipe,
    UrlPipe,
    ShortDateTimePipe,
    DurationPipe,
    MultiTzInfoComponent,
    MultiTzPanelComponent,
    MultiTzCustomValueDirective,
    MiddleMonthDayPipe,
    PrefixedPipe,
    AdaptiveButtonComponent,
    WithTitlePipe,
    CenteredMatTabsDirective,
    ConvertTimezonePipe,
    FileSizePipe,
  ],
  imports: [CommonModule, MatIconModule, MatTooltipModule, MatButtonModule, MatBottomSheetModule, MatListModule],
  exports: [
    StringPipe,
    ImgFallbackDirective,
    ImgFallbackDirective,
    LinkButtonDirective,
    ShortDatePipe,
    EmptyPlaceholderPipe,
    IfEmptyPipe,
    TimeOnlyPipe,
    YOrEmptyPipe,
    InitialsPipe,
    FullNamePipe,
    DevDirective,
    GenderPipe,
    ElapsedTimePipe,
    FormattedJsonPipe,
    PhoneNumberPipe,
    MatDialogActionsAlignDirective,
    AgePipe,
    UrlPipe,
    ShortDateTimePipe,
    DurationPipe,
    MultiTzInfoComponent,
    MultiTzCustomValueDirective,
    MiddleMonthDayPipe,
    PrefixedPipe,
    AdaptiveButtonComponent,
    WithTitlePipe,
    CenteredMatTabsDirective,
    ConvertTimezonePipe,
    FileSizePipe,
  ],
  entryComponents: [MultiTzPanelComponent],
})
export class AngularCdkModule {}
