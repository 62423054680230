import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { EntityName } from '@app/core/services/global-info-modal/entity-name.enum';
import { ViolationError, ViolationsResponse } from '@app/cdk/http/errors';
import { legacyDefined } from '@app/cdk/angular/utils/nullable.utils';
import { ContextHttpParams } from '@app/cdk/http/context-http-params';

export interface IErrorResponseElement {
  /**
   * @deprecated Use more informative 'error' property
   */
  errorCode: string;
  error?: string;
  description: string;
}

const errors: Array<IErrorResponseElement> = [
  // Custom captions, not like in confluence
  {
    errorCode: '0303',
    description:
      'The password must contain at least 8 characters, including lowercase letters, uppercase letters, numbers and symbols',
  },
  {
    errorCode: '0303',
    description:
      'The password must contain at least 8 characters, including lowercase letters, uppercase letters, numbers and symbols',
  },
  { errorCode: '0206', description: 'Email not found' },
  { errorCode: '0310', description: 'Wrong old password' },
  { errorCode: '1627', error: 'CURRENT_USER_CANNOT_SET_VIDEO', description: 'Access denied to perform Telemedicine' },
  {
    errorCode: '5404',
    description:
      '1) Incorrectly set the start or end date of the license (expected time in milliseconds, the conversion failed).' +
      ' 2) the start date is later than the end date. 3) The start date is earlier than tomorrow in the state time zone.',
  },
  { errorCode: '8911', description: 'A holiday set up on a past date cannot be deleted' },
  { errorCode: '7905', description: "Cannot set intervals for '24H' and 'No Coverage' types" },
  { errorCode: '7901', description: 'Time interval is not specified' },
  { errorCode: '8104', description: `${EntityName.FACILITY} schedules should not overlap` },
  { errorCode: '9912', description: 'Request cannot be created at this time.' },
  { errorCode: '1302', description: 'User with entered Amion ID already exists.' },
  { errorCode: '4110', description: `${EntityName.ENCOUNTER} has been already assigned to MD` },
  { errorCode: '1414', description: 'User is not locked' },
  { errorCode: '13102', description: 'Medication Name already exists in the system' },
  { errorCode: '12502', description: 'Lab Option Name already exists in the system' },
  { errorCode: '9906', description: 'The object with the specified ID was not found' },

  { errorCode: '0101', description: 'Username not set' },
  { errorCode: '0102', description: 'User already exists in the system.' },
  { errorCode: '0201', description: 'Email not set' },
  { errorCode: '0202', description: 'User with this e-mail is already registered in the system' },
  {
    errorCode: '0203',
    description:
      'Email has an incorrect format (there must be a @ symbol and at least one non-blank symbol on both sides of it)',
  },
  { errorCode: '0205', description: 'Email should not be specified for the user with the selected role' },
  { errorCode: '0301', description: 'Password not set' },
  { errorCode: '0303', description: 'Wrong password format' },
  { errorCode: '0401', description: 'User roles not set.' },
  { errorCode: '0501', description: 'Phone number not specified' },
  { errorCode: '0503', description: 'The phone number is incorrect (Expected 1 to 15 digits)' },
  { errorCode: '0603', description: 'The extension number is incorrect (Expected nothing or up to 5 digits)' },
  { errorCode: '0701', description: 'Username not set' },
  { errorCode: '0703', description: 'Invalid username format (expected up to 50 characters, got more)' },
  { errorCode: '0801', description: 'User surname not set' },
  { errorCode: '0803', description: 'Invalid user surname format (expected up to 50 characters, received more)' },
  { errorCode: '0903', description: 'Invalid user patronymic format (expected up to 50 characters, received more)' },
  { errorCode: '1006', description: 'User status (when updating) is not set' },
  {
    errorCode: '2402',
    description: 'Facility external ID is not unique',
  },
  {
    errorCode: '2001',
    description: `${EntityName.CORPORATION} must be asked (at the facility and users other than Telehealth Admin and doctor)`,
  },
  { errorCode: '2005', description: `${EntityName.CORPORATION} is assigned to TLH Admin or doctor` },
  { errorCode: '2101', description: `${EntityName.CORPORATION} name not set` },
  { errorCode: '2102', description: `${EntityName.CORPORATION} with the same name already exists` },
  {
    errorCode: '2103',
    description: `${EntityName.CORPORATION} name cannot exceed 128 characters and contain special symbols except .,&+-`,
  },
  { errorCode: '2601', description: `${EntityName.FACILITY} must be specified (for Facility Admin and Nurse)` },
  { errorCode: '2605', description: `${EntityName.FACILITY} must not be present (For the remaining roles)` },
  { errorCode: '2701', description: `${EntityName.FACILITY} type not set'` },
  { errorCode: '2704', description: `Unknown ${EntityName.FACILITY.toLowerCase()} type` },
  {
    errorCode: '2705',
    description: `Transfer between acute and post acute ${EntityName.FACILITY.toLowerCase()} types prohibited`,
  },
  { errorCode: '2801', description: `${EntityName.FACILITY} name not set` },
  { errorCode: '2802', description: `${EntityName.FACILITY} with the same name already exists in the system` },
  { errorCode: '2803', description: `${EntityName.FACILITY} name too long (must not exceed 100 characters)` },
  { errorCode: '2901', description: `${EntityName.FACILITY} address not set` },
  { errorCode: '2903', description: `${EntityName.FACILITY} address is not valid (must be up to 255 characters)` },
  { errorCode: '3001', description: `${EntityName.FACILITY} city is not set` },
  {
    errorCode: '3003',
    description: `The length of the ${EntityName.FACILITY.toLowerCase()} is exceeded (more than 30 characters)`,
  },
  { errorCode: '3101', description: `${EntityName.FACILITY} ZIP code not set` },
  { errorCode: '3103', description: 'Incorrect ZIP code format (must be ZIP or ZIP + 4)' },
  { errorCode: '3301', description: `${EntityName.FACILITY} staff not set` },
  { errorCode: '3304', description: 'Unknown Facility State Code' },
  { errorCode: '4101', description: 'Doctor’s title not assigned (for doctor)' },
  { errorCode: '4105', description: "Doctor's title is given (for the remaining roles)" },
  { errorCode: '4201', description: 'Types of facility (categories) with which the doctor works are not set' },
  { errorCode: '4205', description: 'Categories are given for roles other than doctor.' },
  { errorCode: '4204', description: 'Unknown category facility (similar to 2704 but for doctors)' },
  { errorCode: '5204', description: "Unknown State for Doctor's License" },
  { errorCode: '5301', description: 'License status not set' },
  { errorCode: '5304', description: 'Unknown License Status' },
  { errorCode: '5401', description: 'Period of active license is not set.' },
  { errorCode: '5505', description: "Start date is set for the status 'Without license' or 'Active'" },
  { errorCode: '5501', description: 'Start date not set for PENDING license' },
  { errorCode: '5605', description: "End date set for the status 'Without a license'" },
  { errorCode: '5601', description: 'End date not set for PENDING or ACTIVE license' },
  { errorCode: '5604', description: 'End date before tomorrow with an active license' },
  {
    errorCode: '6101',
    description: `Author not listed ${EntityName.ENCOUNTER.toLowerCase()} (Patient's Nurse / Medical Team Member field)`,
  },
  {
    errorCode: '6026',
    error: 'CONNECT_CANNOT_BE_ASSIGNED',
    description: `${EntityName.ENCOUNTER} cannot be assigned`,
  },
  {
    errorCode: '6103',
    description: `Author's Name ${EntityName.ENCOUNTER.toLowerCase()} (Patient's Nurse / Medical Team Member field) must not exceed 100 characters`,
  },
  { errorCode: '6201', description: 'Not indicated the name of the patient' },
  { errorCode: '6203', description: 'Patient name must not exceed 100 characters.' },
  { errorCode: '6304', description: 'The list of selected symptoms contains an unknown value.' },
  { errorCode: '6307', description: 'Selected symptom list contains duplicates' },
  {
    errorCode: '6403',
    description: 'The length of the text EMR (Electronic Medical Record) should not exceed 1000 characters',
  },
  {
    errorCode: '6503',
    description:
      'The length of the text describing the symptoms not listed in the provided list should not exceed 300 characters.',
  },
  { errorCode: '6604', description: `${EntityName.ENCOUNTER} type not specified or incorrect value passed.` },
  { errorCode: '6704', description: 'Incorrect patient birth date: must not be later than the current date' },
  {
    errorCode: '6801',
    description:
      `When creating ${EntityName.ENCOUNTER} types PA / NP or Doctor, at least one symptom from the list provided in advance ` +
      'must be selected or other symptoms indicated in the Other field',
  },
  {
    errorCode: '6805',
    description: `When creating a ${EntityName.ENCOUNTER.toLowerCase()} of type 'Notification, PA / NP' it is forbidden to indicate any symptoms`,
  },
  { errorCode: '7101', description: 'Schedule start date is not specified or incorrect.' },
  { errorCode: '7201', description: 'Schedule expiration date not specified or incorrect' },
  { errorCode: '7404', description: 'Schedule type not specified or incorrect' },
  {
    errorCode: '7501',
    description:
      'For the schedule of the institution is not specified a single day shift or some of the shifts passed empty (null)',
  },
  { errorCode: '7601', description: 'No start time indicated.' },
  { errorCode: '7701', description: 'No end time indicated.' },
  {
    errorCode: '7801',
    description: 'Day of week for the shift is not specified or is not included in the interval from 1 to 7',
  },
  { errorCode: '7807', description: 'Multiple shifts for the same day of the week.' },
  { errorCode: '7904', description: 'Start Time cannot be after or equal to End Time' },
  {
    errorCode: '8112',
    error: 'FACILITY_SCHEDULE_WRONG_TIME',
    description:
      "You are attempting to page TeleHealth Solution outside of your facility's contracted service hours. " +
      'Please reference your Password Poster or contact your DON for the hours of service.',
  },
  { errorCode: '9004', description: 'Offset negative' },
  { errorCode: '9104', description: 'Limit is less than one' },
  { errorCode: '9205', description: 'You cannot sort by column' },
  { errorCode: '9304', description: 'Sort non asc / desc' },
  { errorCode: '9401', description: 'Hours for the start or end of a shift are not specified.' },
  { errorCode: '9404', description: 'Hours for the start or end of a shift are not in the range from 0 to 23' },
  { errorCode: '9501', description: 'Minutes are not indicated for the start or end of a shift' },
  { errorCode: '9504', description: 'Minutes for the start or end of a shift is not in the range from 0 to 59' },
  { errorCode: '9906', description: 'The object with the specified ID was not found (for getById, Remove)' },
  { errorCode: '8802', description: "There's already set up holiday on this date" },

  { errorCode: '10101', description: `Id not specified ${EntityName.ENCOUNTER}` },
  { errorCode: '10201', description: 'The value of the callStarted parameter is not specified in the request body.' },
  { errorCode: '10310', description: 'The current encounter status does not allow performing this operation.' },
  {
    errorCode: '10403',
    description:
      'The value of the Notes between Telehealth clinicians field has an incorrect format ' +
      '(empty, consists of spaces, or is longer than 1000 characters)',
  },
  { errorCode: '10501', description: 'Conditions must be set' },
  {
    errorCode: '10503',
    description:
      'The value of the Conditions field has an incorrect format ' +
      '(empty, consists of spaces, or is longer than 1000 characters',
  },
  { errorCode: '10601', description: 'The value of the noReturn parameter is not specified in the request body.' },
  { errorCode: '10701', description: 'The value of the isCompleted parameter is not specified in the request body.' },
  {
    errorCode: '10803',
    description:
      'The value of the ER note field has an incorrect format (empty, consists of spaces, or is longer than 1000 characters',
  },

  { errorCode: '11101', description: 'Diagnosis Notes must be populated' },

  {
    errorCode: '11204',
    error: 'ENCOUNTER_OUTCOME_OUT_OF_BOUNDS',
    description: `${EntityName.ENCOUNTER} cannot be completed with selected outcome`,
  },

  { errorCode: '13601', description: 'Schedule group name not set' },
  { errorCode: '13602', description: 'Schedule group already exists in the system' },
  {
    errorCode: '13603',
    description: 'The name of the schedule group must not be empty or consist of unprintable characters.',
  },
  { errorCode: '13604', description: 'The name of the group of schedules should not exceed 100 characters.' },
  { errorCode: '13701', description: 'Inclusive field values not set' },

  { errorCode: '14101', description: 'The list of states for the group schedule version should not be empty.' },
  { errorCode: '14104', description: 'The list of states for the group schedule version contains incorrect values.' },
  {
    errorCode: '14204',
    description: 'The list of facility types for the group schedule version contains incorrect values.',
  },
  {
    errorCode: '14215',
    description:
      'The list of facility types for the group schedule version must be empty if the version is intended for CCNC, ' +
      'and non-empty otherwise',
  },
  { errorCode: '14301', description: 'Effective date version of the group schedule is not set up' },
  { errorCode: '14304', description: 'Effective date cannot be before or equal to current date' },
  { errorCode: '14404', description: 'Expiration date cannot be before or equal to current date' },
  {
    errorCode: '14504',
    description: 'Effective date cannot be equal to or after expiration date and schedules should not overlap',
  },
  { errorCode: '14701', description: 'Version type of group schedule (Physician, CCNC, etc.) is not set' },
  { errorCode: '14704', description: 'Version type of group schedule is incorrect' },
  {
    errorCode: '14801',
    description: 'The facility ID for serviced facilities of the group schedule is not specified.',
  },
  {
    errorCode: '14901',
    description: 'The value of the included field for serviced facilities of group schedule is not specified',
  },

  { errorCode: '15001', description: 'The value of the serviced object is not specified' },
  { errorCode: '15101', description: 'Start time is required' },
  { errorCode: '15201', description: 'End time is required' },
  { errorCode: '15301', description: 'Shift name is required' },
  { errorCode: '15302', description: 'Shift name already existsin the group schedule' },
  { errorCode: '15303', description: 'Shift name cannot be empty or populated with spaces' },
  { errorCode: '15304', description: 'Shift name length cannot exceed 50 symbols' },
  { errorCode: '15401', description: 'Group schedule version is undefined' },
  { errorCode: '15406', description: 'Group schedule version shift is not found' },
  { errorCode: '15501', description: 'Shift list in schedule group version is undefined' },
  { errorCode: '15502', description: 'Shifts in group schedule version have the same id' },
  { errorCode: '15510', description: 'Shift does not relate to the group schedule version' },

  { errorCode: '16106', description: 'Shift is not found' },
  { errorCode: '16111', description: 'Shift set up on a past date cannot be edited' },
  {
    errorCode: '16112',
    description:
      'Shift set up on a past date or a date different from the relating group schedule period cannot be created',
  },
  { errorCode: '16116', description: 'Shift intersects with another one set up on the same date' },
  { errorCode: '16201', description: 'Start time is required' },
  { errorCode: '16301', description: 'End time is required' },
  { errorCode: '16401', description: 'isBackup value is not set' },
  { errorCode: '16501', description: 'States are required' },
  {
    errorCode: '16504',
    description:
      'Current physician is not licensed in the shift state or the state is not serviced in the shift schedule version',
  },
  { errorCode: '16601', description: 'Physician name is required' },
  { errorCode: '16604', description: 'Physician is not found' },
  { errorCode: '16701', description: 'Group schedule shift is required' },
  { errorCode: '16704', description: 'Group schedule shift is not found' },
  { errorCode: '16801', description: 'Shift date is required' },
  {
    errorCode: '16904',
    description: 'Shift set up on a date different from the relating group schedule period cannot be created',
  },
  { errorCode: '16917', description: 'Shift start time cannot be equal to or after its end time' },

  {
    errorCode: '17004',
    description: " Physician's categories need to include all categories of the relating group schedule version",
  },
  { errorCode: '18416', description: 'Time off schedules should not overlap' },
  { errorCode: '4319', description: 'Physician has a time off scheduled on this day' },
  { errorCode: '18104', description: 'Start Time cannot be before or equal to current time' },
  { errorCode: '18411', description: 'A time-off request set on a past time cannot be edited' },
  { errorCode: '16204', description: 'Shift start time cannot be in the past' },
  { errorCode: '15918', description: 'There are Clinician shifts scheduled after the expiration date' },
  {
    errorCode: '3705',
    description: `Code Blue ${EntityName.ENCOUNTER.toLowerCase()} cannot be created for this facility`,
  },
  {
    errorCode: '6605',
    error: 'CONNECT_TYPE_NOT_ALLOWED',
    description: `${EntityName.ENCOUNTER} type is not allowed to change in this facility`,
  },
  { errorCode: '16304', description: "End of doctor's shift should be after current time" },
  { errorCode: '19120', description: 'Time Zone cannot be edited after creating group schedule version' },
  {
    errorCode: '16522',
    description: `Current physician is not licensed in the shift state. The states in question: {{name}}`,
  },
  {
    errorCode: '16523',
    description: `Some state is not serviced in the shift schedule version. The states in question: {{name}}`,
  },
  {
    errorCode: '4325',
    description: `Clinician has been banned in all included facilities in states: {{}}`,
  },
  { errorCode: '22702', description: 'Template with that name already exists' },
  {
    errorCode: '11405',
    description: `Video cannot be checked because no call has been performed for this ${EntityName.ENCOUNTER}`,
  },
  { errorCode: '25401', description: 'Uploaded attachment is empty' },
  {
    errorCode: '25310',
    description: `Cannot add attachment to a ${EntityName.ENCOUNTER.toLowerCase()} in that status`,
  },
  {
    errorCode: '25306',
    description: `Cannot add attachment to ${EntityName.ENCOUNTER.toLowerCase()} that does not exists`,
  },
  { errorCode: '25101', description: 'Cannot determine file type' },
  { errorCode: '25103', description: 'Cannot determine file type' },
  { errorCode: '25210', description: 'File type does not match MIME-type' },
  { errorCode: '25506', error: 'CONNECT_ATTACHMENT_NOT_FOUND', description: 'Attachment data is missing' },
  { errorCode: '25508', error: 'CONNECT_ATTACHMENT_ACCESS_DENIED', description: 'Attachment data access denied' },

  {
    errorCode: '36001',
    description: `Cannot update ${EntityName.ENCOUNTER.toLowerCase()} status, without confirmed diagnosis codes`,
  },
  { errorCode: '40002', description: 'Diagnosis with same ICD code already exists' },
  { errorCode: '42105', description: 'This ICD code cannot be applied to a specified date' },

  { errorCode: '55202', description: 'Corporation Team with same Name already exists' },
  {
    errorCode: '35703',
    error: 'CONNECT_ASSESSMENT_PLAN_NOTES_INVALID_FORMAT',
    description: 'Assessment Plan Notes or Diagnosis Notes value has invalid format',
  },
  {
    errorCode: '59102',
    error: 'INSURANCE_PARTNER_NAME_NOT_UNIQUE',
    description: 'Insurance Plan Name already exists in the system',
  },
  {
    errorCode: '60101',
    error: 'LEAST_WORKED_DOCTOR_POLICY_TYPE_NOT_SET',
    description: 'Least worked doctor policy type is not set',
  },
  {
    errorCode: '60105',
    error: 'LEAST_WORKED_DOCTOR_POLICY_TYPE_NOT_ALLOWED',
    description: 'Least worked doctor policy type is not allowed',
  },
  {
    errorCode: '60201',
    error: 'RATIO_POLICY_PARAMS_NOT_SET',
    description: 'Ratio values are not set or both equal to 0',
  },
  {
    errorCode: '60301',
    error: 'RATIO_POLICY_ACTIVE_CALLS_NOT_SET',
    description: 'Active calls ratio value is not set',
  },
  {
    errorCode: '60304',
    error: 'RATIO_POLICY_ACTIVE_CALLS_OUT_OF_BOUNDS',
    description: 'Active calls ratio value is out of bounds',
  },
  {
    errorCode: '60401',
    error: 'RATIO_POLICY_TOTAL_CALLS_NOT_SET',
    description: 'Total calls ratio value is not set',
  },
  {
    errorCode: '60404',
    error: 'RATIO_POLICY_TOTAL_CALLS_OUT_OF_BOUNDS',
    description: 'Total calls ratio value is out of bounds',
  },
  {
    errorCode: '59324',
    error: 'INSURANCE_PARTNER_CANNOT_BE_REMOVED',
    description: 'Insurance partner cannot be removed because it is active in a corporation',
  },
  {
    errorCode: '59330',
    error: 'INSURANCE_PARTNER_NOT_ACTIVE_IN_CORPORATION',
    description: 'Insurance partners cannot be activated because they are inactive in corporation',
  },
  {
    errorCode: '59420',
    error: 'CORPORATION_INSURANCE_PARTNER_CANNOT_BE_UPDATED',
    description: 'Insurance partner activity cannot be turned off because it is enabled in a facility',
  },
  {
    errorCode: '4002',
    error: 'FACILITY_EMR_ID_NOT_UNIQUE',
    description: `A ${EntityName.FACILITY} with the same ${EntityName.FACILITY} EMR ID already exists`,
  },
  {
    errorCode: '10306',
    error: 'ENCOUNTER_STATUS_NOT_FOUND',
    description: `${EntityName.ENCOUNTER} does not went through required status`,
  },
  {
    error: 'CONNECT_PATIENT_LINK_NOT_SET',
    description: `${EntityName.ENCOUNTER} is not linked with any patient`,
    errorCode: '37001',
  },
  {
    errorCode: '61606',
    error: 'EMR_PATIENT_NOT_FOUND',
    description: `${EntityName.PATIENT} not found in EMR system`,
  },
  {
    errorCode: '14224',
    error: 'GROUP_SCHEDULE_VERSION_FACILITY_TYPES_CANNOT_BE_REMOVED',
    description: 'Category cannot be removed',
  },
  {
    errorCode: '92024',
    error: 'ROUNDING_CONNECT_VIDEO_STATUS_CANNOT_BE_REMOVED',
    description: `Rounding visits cannot be downgraded to phone calls from video ${EntityName.ENCOUNTER.toLowerCase()}s`,
  },
  {
    errorCode: '6007',
    error: 'CONNECT_DUPLICATES',
    description: `${EntityName.ENCOUNTER} already exists for this patient`,
  },
  {
    errorCode: '90210',
    error: 'ROUNDING_PATIENT_STATUS_NOT_MATCH',
    description: `Status of ${EntityName.PATIENT.toLowerCase()} does not allow to perform requested operation`,
  },
  {
    errorCode: '88510',
    error: 'ROUNDING_LIST_STATUS_NOT_MATCH',
    description: `Status of ${EntityName.ROUNDING.toLowerCase()} does not allow to perform requested operation`,
  },
  {
    errorCode: '88404',
    error: 'ROUNDING_LIST_DATE_OUT_OF_BOUNDS',
    description: `Date of ${EntityName.ROUNDING.toLowerCase()} does not allow to perform requested operation`,
  },
  {
    errorCode: '88628',
    error: 'ROUNDING_LIST_PATIENTS_INVALID_VALUE',
    description: `Status of ${EntityName.ROUNDING.toLowerCase()} ${EntityName.PATIENT.toLowerCase()}s does not allow to perform requested operation`,
  },
  {
    errorCode: '88601',
    error: 'ROUNDING_LIST_PATIENTS_NOT_SET',
    description: `Empty ${EntityName.PATIENT.toLowerCase()} list does not allow to perform requested operation`,
  },
  {
    errorCode: '88801',
    error: 'ROUNDING_LIST_CONTACT_NOT_SET',
    description: `Empty ${EntityName.ROUNDING.toLowerCase()} contact does not allow to perform requested operation`,
  },
  {
    errorCode: '88901',
    error: 'ROUNDING_LIST_BEST_TIME_NOT_SET',
    description: `Empty best time to be reached does not allow to perform requested operation`,
  },
  {
    errorCode: '93003',
    error: 'FACILITY_NOTES_INVALID_FORMAT',
    description: `Facility Notes value has invalid format`,
  },
  {
    errorCode: '37801',
    error: 'CONNECT_ESIGN_LOCATION_NOT_SET',
    description: `E-Signature location is not set`,
  },
  {
    errorCode: '95233',
    error: 'CORPORATION_FACILITIES_IS_EMPTY',
    description: 'The selected corporation does not contain facilities available to the user',
  },
  {
    errorCode: '64203',
    error: 'NPI_INVALID_FORMAT',
    description: `NPI must contain only 10 digits`,
  },
  {
    errorCode: '2613',
    error: 'FACILITY_NOT_BELONGS_TO_CORPORATION',
    description: `Selected facilities don't belong to corporation`,
  },
  {
    errorCode: '38305',
    error: 'ENCOUNTER_EXTERNAL_PCP_ID_NOT_ALLOWED',
    description: `Encounter cannot be assigned to Patient Care Provider (External)`,
  },
  {
    errorCode: '38405',
    error: 'EXTERNAL_PCP_ENCOUNTER_NOT_ALLOWED',
    description: `This action is prohibited for encounter which was assigned to Patient Care Provider (External)`,
  },
  {
    errorCode: '6905',
    error: 'CONNECT_PCP_NAME_NOT_ALLOWED',
    description: `Primary Care Physician cannot be selected for this encounter`,
  },
  {
    errorCode: '38505',
    error: 'OUTCOME_HOSPITALIZATION_QUESTION_NOT_ALLOWED',
    description: `Outcome hospitalization question cannot be selected for this encounter`,
  },
  {
    errorCode: '38501',
    error: 'OUTCOME_HOSPITALIZATION_QUESTION_NOT_SET',
    description: `Outcome hospitalization question is not set`,
  },
  {
    errorCode: '38601',
    error: 'SUB_OUTCOME_NOT_SET',
    description: `Most Application Outcome is not set`,
  },
  {
    errorCode: '38605',
    error: 'SUB_OUTCOME_NOT_ALLOWED',
    description: `Most Application Outcome cannot be selected for this encounter`,
  },
  {
    errorCode: '98328',
    error: 'CORPORATION_AGREEMENT_SIGNATURE_INVALID_VALUE',
    description: `This agreement has already been accepted`,
  },
  {
    errorCode: '98428',
    error: 'CORPORATION_AGREEMENT_VERSION_INVALID_VALUE',
    description: `The agreement version is not active`,
  },
];

const ACCESS_DENIED_ERRORS: readonly IErrorResponseElement[] = [
  { errorCode: '0000', error: 'PERMISSION_DENIED', description: 'Access denied' },
  {
    errorCode: '4115',
    error: 'DOCTOR_TITLE_CANNOT_BE_SET_TOGETHER',
    description: 'The action is forbidden for doctor with this title',
  },
  {
    errorCode: '6008',
    error: 'CONNECT_ACCESS_DENIED',
    description: `The user does not have access to this ${EntityName.ENCOUNTER.toLowerCase()}`,
  },
  {
    errorCode: '2608',
    error: 'FACILITY_ACCESS_DENIED',
    description: `The user does not have access to this ${EntityName.FACILITY.toLowerCase()}`,
  },
  {
    errorCode: '93108',
    error: 'FACILITIES_ACCESS_DENIED',
    description: `The user does not have access to these ${EntityName.FACILITIES.toLowerCase()}`,
  },
  {
    errorCode: '10108',
    error: 'ENCOUNTER_ID_ACCESS_DENIED',
    description: `The user does not have access to this ${EntityName.ENCOUNTER.toLowerCase()}`,
  },
  {
    errorCode: '1408',
    error: 'USER_ACCESS_DENIED',
    description: `You don't have access to this ${EntityName.USER.toLowerCase()}`,
  },
  {
    errorCode: '90008',
    error: 'ROUNDING_PATIENT_ACCESS_DENIED',
    description:
      `You are not eligible to interact with pages from this location due to role, doctor title, ` +
      `license status or credentials at this ${EntityName.FACILITY.toLowerCase()}`,
  },
  {
    errorCode: '88008',
    error: 'ROUNDING_LIST_ACCESS_DENIED',
    description:
      `You are not eligible to interact with pages from this location due to role, doctor title, ` +
      `license status or credentials at this ${EntityName.FACILITY.toLowerCase()}`,
  },
  {
    errorCode: '88710',
    error: 'ROUNDING_OWNER_NOT_MATCH',
    description: `This doctor is not eligible to be owner due to license status, role or credentials at this ${EntityName.FACILITY.toLowerCase()}`,
  },
  {
    errorCode: '88006',
    error: 'ROUNDING_LIST_NOT_FOUND',
    description: `The ${EntityName.ROUNDING.toLowerCase()} is not found`,
  },
  {
    errorCode: '90006',
    error: 'ROUNDING_PATIENT_NOT_FOUND',
    description: `The ${EntityName.PATIENT.toLowerCase()} is not found`,
  },
  {
    errorCode: '9806',
    error: 'CONTEXTUAL_PERMISSION_NOT_FOUND',
    description: `You are not eligible to interact with pages from this location due to role permissions and personal settings`,
  },
  {
    errorCode: '98536',
    error: 'CORPORATION_AGREEMENT_NOT_MATCH_BY_CORPORATION',
    description: `You do not have access to the corporation to which the agreement belongs`,
  },
  {
    errorCode: '59329',
    error: 'INSURANCE_PARTNER_NOT_AVAILABLE',
    description: `Cannot choose insurance because partner is not active in this facility`,
  },
  {
    errorCode: '37601',
    error: 'CONSULTATION_TYPE_NOT_SET',
    description: `Consultation Type must be specified`,
  },
  {
    errorCode: '36101',
    error: 'BILLING_CODE_NOT_SET',
    description: `Billing Code must be specified`,
  },
  {
    errorCode: '35801',
    error: 'CONNECT_DURATION_NOT_SET',
    description: `Duration must be specified`,
  },
  {
    errorCode: '38701',
    error: 'CONNECT_LOCATION_TYPE_NOT_SET',
    description: `Location Type must be specified`,
  },
  {
    errorCode: 'ts100101',
    error: 'TELEMEDICINE_ID_NOT_SET',
    description: `ID must be specified for ${EntityName.TELEMEDICINE.toLowerCase()}`,
  },
  {
    errorCode: 'ts100106',
    error: 'TELEMEDICINE_NOT_FOUND',
    description: `You are not eligible to start session for the encounter`,
  },
  {
    errorCode: 'ts100208',
    error: 'TELEMEDICINE_ACCESS_DENIED',
    description:
      `You are not eligible to interact with call due to role permissions, personal settings,` +
      `encounter status  or call at this moment is active other users in ${EntityName.TELEMEDICINE.toLowerCase()}`,
  },
  {
    errorCode: 'ts1004',
    error: 'FACILITY_DOES_NOT_HAVE_TELEMEDICINE_ENABLED',
    description: `ID must be specified for ${EntityName.TELEMEDICINE.toLowerCase()}`,
  },
  {
    errorCode: 'ts1005',
    error: 'TELEMEDICINE_SESSION_IS_ALREADY_ACTIVE_FOR_ENCOUNTER',
    description:
      `You are not eligible to interact with call due to role permissions, personal settings,` +
      `encounter status  or call at this moment is active other users in ${EntityName.TELEMEDICINE.toLowerCase()}`,
  },
  {
    errorCode: 'ts1006',
    error: 'TELEMEDICINE_SESSION_NOT_ACTIVE',
    description: `You are not eligible to start session for the encounter`,
  },
  {
    errorCode: 'prt1007',
    error: 'ENCOUNTER_ID_FOR_EXAM_SHEET_REPORT',
    description: `You are not eligible to start session for the encounter`,
  },
  {
    errorCode: 'prt1008',
    error: 'FACILITIES_FOR_EXAM_SHEET_REPORT',
    description: `You are not eligible to generate exam sheet report for this facility(-ies)`,
  },
  {
    errorCode: 'prt1009',
    error: 'CORPORATIONS_FOR_EXAM_SHEET_REPORT',
    description: `You are not eligible to generate exam sheet report for this corporation(s)`,
  },
  {
    errorCode: '88412',
    error: 'ROUNDING_LIST_DATE_DISALLOWED_TIME',
    description: 'It is not possible to duplicate a rounding that is older than two days',
  },
  {
    errorCode: 'rcm0211',
    error: 'Rcm version_CANNOT_BE_CHANGED',
    description: 'Cannot update the encounter. Another user made changes to it while you were auditing.',
  },
  {
    errorCode: '2502',
    error: 'FACILITY_EXTERNAL_ID_NOT_UNIQUE',
    description: 'EMR ID is not unique to the corporation',
  },
];

const INTERNAL_API_ERRORS: readonly IErrorResponseElement[] = [
  {
    description: 'Pagination parameter "limit" is required',
    error: 'LIMIT_NOT_SET',
    errorCode: '9101',
  },
  {
    description: 'Pagination parameter "offset" is required',
    error: 'OFFSET_NOT_SET',
    errorCode: '9001',
  },
];

export const GLOBAL_ERRORS_REGISTRY = [...errors, ...INTERNAL_API_ERRORS, ...ACCESS_DENIED_ERRORS];

@Injectable({
  providedIn: 'root',
})
export class ErrorFormatterService {
  private modern: string[] = [];

  constructor() {}

  addModernPrefix(value: string): void {
    this.modern.push(value);
  }

  deleteModernPrefix(value: string): void {
    this.modern = this.modern.filter(p => p !== value);
  }

  getErrorByCode(code: string): string {
    const error = GLOBAL_ERRORS_REGISTRY.find(e => e.errorCode === code);
    return error !== undefined ? error.description : code;
  }

  getFormattedErrors(errorResponse: HttpErrorResponse): string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getFormattedErrors(errorResponse: HttpErrorResponse, req?: HttpRequest<any>): string[] | ViolationError;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getFormattedErrors(errorResponse: HttpErrorResponse, req?: HttpRequest<any>): string[] | ViolationError {
    const formattedErrors: string[] = [];

    if (errorResponse.status === 400) {
      if (typeof (errorResponse.error as ViolationsResponse).violations !== 'undefined') {
        if (req != undefined) {
          const hasModernPrefix = this.modern.find(p => req.url.includes(p)) != undefined;
          const needToConvert =
            req.params instanceof ContextHttpParams && req.params.context?.convertErrorToViolation === true;
          if (hasModernPrefix || needToConvert) {
            return new ViolationError(errorResponse.error);
          }
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (errorResponse.error as ViolationsResponse).violations?.forEach((element: any) => {
          // TODO: reimplement with map instead
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const found: any = GLOBAL_ERRORS_REGISTRY.find(this.getViolationComparator(element));
          if (legacyDefined(found)) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
            let errorMessage = found.description;
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions,@typescript-eslint/no-unsafe-member-access
            if (element.payload) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              errorMessage = this.createErrorMessageFromTemplate(found.description, element.payload);
            }
            formattedErrors.push(errorMessage);
          } else {
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
            const code = element.error ? element.error : element.errorCode;
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            formattedErrors.push(`Unknown Error: ${code}`);
          }
        });
      }
    }

    return formattedErrors;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createErrorMessageFromTemplate(template: string, payload: Array<any>): string {
    // Search for variables in the template
    const templateFields = template.match(/{{.*}}/g);
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition,@typescript-eslint/strict-boolean-expressions
    if (payload && templateFields && templateFields.length > 0) {
      let errorMessage = template;

      // Get payload object key to display
      const fieldNames = templateFields.map(templateField => templateField.substring(2, templateField.length - 2));

      fieldNames.forEach(fieldName => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/strict-boolean-expressions,@typescript-eslint/no-unsafe-member-access
        const payloadStrings = payload.map(element => (fieldName ? element[fieldName] : element));
        errorMessage = errorMessage.replace(`{{${fieldName}}}`, payloadStrings.join(', '));
      });
      return errorMessage;
    }
    return template;
  }

  private getViolationComparator(violation: IErrorResponseElement): (el: IErrorResponseElement) => boolean {
    return item => {
      return item.errorCode === violation.errorCode || item.error === violation.error;
    };
  }
}
