import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  HostBinding,
  HostListener,
  Injector,
  NgZone,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { IndividualConfig, ToastPackage, ToastrService } from 'ngx-toastr';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Observable, Subscription } from 'rxjs';
import { ComponentType } from '@angular/cdk/overlay';
import {
  NOTIFICATION_DATA,
  NotificationInstanceRef,
  NotificationRef,
} from '@app/core/services/global-info-modal/notification-container/notification-container.model';

function normalize(number: number): number {
  const round = Math.round(number);
  if (round > 100) {
    return 100;
  }
  if (round < 0) {
    return 0;
  }
  return round;
}

@Component({
  templateUrl: './notification-container.component.html',
  styleUrls: ['notification-container.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('flyInOut', [
      state('inactive', style({ opacity: 0 })),
      state('active', style({ opacity: 1 })),
      state('removed', style({ opacity: 0 })),
      transition('inactive => active', animate('{{ easeTime }}ms {{ easing }}')),
      transition('active => removed', animate('{{ easeTime }}ms {{ easing }}')),
    ]),
  ],
  preserveWhitespaces: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationContainerComponent<A, D> extends NotificationRef<A> implements OnDestroy {
  message?: string | null;
  title?: string;
  options: Partial<IndividualConfig>;
  duplicatesCount!: number;
  originalTimeout?: number;
  /** width of progress bar */
  width = 100;
  /** a combination of toast type and options.toastClass */
  @HostBinding('class') toastClasses = '';
  /** controls animation */
  @HostBinding('@flyInOut')
  state = {
    value: 'inactive',
    params: {
      easeTime: this.toastPackage.config.easeTime,
      easing: 'ease-in',
    },
  };

  @ViewChild('outlet', { read: ViewContainerRef })
  outlet?: ViewContainerRef;

  /** hides component when waiting to be displayed */
  @HostBinding('style.display')
  get displayStyle(): string | undefined {
    if (this.state.value === 'inactive') {
      return 'none';
    }

    return;
  }

  private timeout?: number;
  private intervalId?: number;
  private hideTime!: number;
  private sub: Subscription;
  private sub1: Subscription;
  private sub2: Subscription;
  private sub3: Subscription;

  private component?: ComponentRef<unknown>;

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
    private cd: ChangeDetectorRef,
    private injector: Injector,
    private cfr: ComponentFactoryResolver,
    protected ngZone?: NgZone
  ) {
    super();
    this.message = toastPackage.message;
    this.title = toastPackage.title;
    this.options = toastPackage.config;
    this.originalTimeout = toastPackage.config.timeOut;
    this.toastClasses = `${toastPackage.toastType} ${toastPackage.config.toastClass}`;

    this.sub = toastPackage.toastRef.afterActivate().subscribe(() => {
      this.activateToast();
      this.cd.markForCheck();
    });
    this.sub1 = toastPackage.toastRef.manualClosed().subscribe(() => {
      this.remove();
      this.cd.markForCheck();
    });
    this.sub2 = toastPackage.toastRef.timeoutReset().subscribe(() => {
      this.resetTimeout();
      this.cd.markForCheck();
    });
    this.sub3 = toastPackage.toastRef.countDuplicate().subscribe(count => {
      this.duplicatesCount = count;
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    this.sub1.unsubscribe();
    this.sub2.unsubscribe();
    this.sub3.unsubscribe();
    clearInterval(this.intervalId);
    clearTimeout(this.timeout);
    this.component?.destroy();
    this.outlet?.clear();
  }

  /**
   * activates toast and sets timeout
   */
  activateToast(): void {
    this.state = { ...this.state, value: 'active' };
    if (
      !(this.options.disableTimeOut === true || this.options.disableTimeOut === 'timeOut') &&
      this.options.timeOut != undefined
    ) {
      this.outsideTimeout(() => this.remove(), this.options.timeOut);
      this.hideTime = new Date().getTime() + this.options.timeOut;
      if (this.options.progressBar === true) {
        this.outsideInterval(() => this.updateProgress(), 10);
      }
    }
  }

  /**
   * updates progress bar width
   */
  updateProgress(): void {
    if (this.options.timeOut == undefined) {
      return;
    }
    const now = new Date().getTime();
    const remaining = this.hideTime - now;
    const width = normalize((remaining / this.options.timeOut) * 100);

    if (this.width !== width) {
      this.runInsideAngular(() => {
        this.width = width;
        this.cd.markForCheck();
      });
    }
  }

  resetTimeout(): void {
    clearTimeout(this.timeout);
    clearInterval(this.intervalId);
    this.state = { ...this.state, value: 'active' };

    this.outsideTimeout(() => this.remove(), this.originalTimeout ?? 0);
    this.options.timeOut = this.originalTimeout;
    this.hideTime = new Date().getTime() + (this.options.timeOut ?? 0);
    this.width = 100;
    if (this.options.progressBar === true) {
      this.outsideInterval(() => this.updateProgress(), 10);
    }
  }

  /**
   * tells toastrService to remove this toast after animation time
   */
  remove(): void {
    if (this.state.value === 'removed') {
      return;
    }
    clearTimeout(this.timeout);

    this.runInsideAngular(() => {
      this.state = { ...this.state, value: 'removed' };
      setTimeout(() => this.toastrService.remove(this.toastPackage.toastId), +this.toastPackage.config.easeTime);
    });
  }

  @HostListener('click')
  tapToast(): void {
    if (this.state.value === 'removed') {
      return;
    }
    this.toastPackage.triggerTap();
    if (this.options.tapToDismiss === true) {
      this.remove();
    }
  }

  @HostListener('mouseenter')
  stickAround(): void {
    if (this.state.value === 'removed') {
      return;
    }
    clearTimeout(this.timeout);
    this.options.timeOut = 0;
    this.hideTime = 0;

    // disable progressBar
    clearInterval(this.intervalId);
    this.width = 0;
  }

  @HostListener('mouseleave')
  delayedHideToast(): void {
    if (
      this.options.disableTimeOut === true ||
      this.options.disableTimeOut === 'extendedTimeOut' ||
      this.options.extendedTimeOut === 0 ||
      this.state.value === 'removed'
    ) {
      return;
    }
    this.outsideTimeout(() => this.remove(), this.options.extendedTimeOut ?? 0);
    this.options.timeOut = this.options.extendedTimeOut;
    this.hideTime = new Date().getTime() + (this.options.timeOut ?? 0);
  }

  private outsideTimeout(func: () => void, timeout: number): void {
    if (this.ngZone != undefined) {
      this.ngZone.runOutsideAngular(() => (this.timeout = (setTimeout(func, timeout) as unknown) as number));
    } else {
      this.timeout = (setTimeout(func, timeout) as unknown) as number;
    }
  }

  private outsideInterval(func: () => void, timeout: number): void {
    if (this.ngZone != undefined) {
      this.ngZone.runOutsideAngular(() => (this.intervalId = (setInterval(func, timeout) as unknown) as number));
    } else {
      this.intervalId = (setInterval(func, timeout) as unknown) as number;
    }
  }

  private runInsideAngular(func: () => void): void {
    if (this.ngZone != undefined) {
      this.ngZone.run(func);
    } else {
      func();
    }
  }

  render<T extends NotificationInstanceRef<A>>(component: ComponentType<T>, data?: D): void {
    this.cd.detectChanges();

    if (this.outlet == undefined) {
      return;
    }

    if (this.component != undefined) {
      this.component.destroy();
      this.component = undefined;
      this.outlet.clear();
    }

    const factory = this.cfr.resolveComponentFactory(component);
    const injector = Injector.create({
      providers: [
        {
          provide: NotificationRef,
          useValue: this,
        },
        {
          provide: NOTIFICATION_DATA,
          useValue: data,
        },
      ],
      parent: this.injector,
    });
    this.component = this.outlet.createComponent(factory, 0, injector);

    this.cd.detectChanges();
  }

  get actions$(): Observable<A> {
    return this.toastPackage.onAction() as Observable<A>;
  }

  action(action: A, close = false): void {
    this.toastPackage.triggerAction(action);
    if (close) {
      this.remove();
    }
  }
}
