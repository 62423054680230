export enum Role {
  TELEHEALTH_ADMIN = 1,
  CORPORATION_ADMIN = 2,
  FACILITY_ADMIN = 3,
  MD = 4,
  NURSE = 5,
  ENCOUNTER_MANAGER = 6,
  SUB_ADMIN = 7,
  CC_REP = 8,
  CORP_MASTER_ADMIN = 9,
  FACILITY_MASTER_ADMIN = 10,
  TECH_SUPPORT = 11,
  FACILITY_DOCTOR = 12,
  PARTNER = 13,
  INTERNAL_DOCTOR = 14,
  EXTERNAL_DOCTOR = 15,
  FACILITY_NURSE = 16,
}

export const ROLE_NAME: Record<Role, string> = {
  [Role.TELEHEALTH_ADMIN]: 'TELEHEALTH_ADMIN',
  [Role.CORPORATION_ADMIN]: 'CORP_ADMIN',
  [Role.FACILITY_ADMIN]: 'FACILITY_ADMIN',
  [Role.MD]: 'DOCTOR',
  [Role.NURSE]: 'NURSE',
  [Role.ENCOUNTER_MANAGER]: 'NURSE_CARE_COORDINATOR',
  [Role.SUB_ADMIN]: 'SUB_ADMIN',
  [Role.CC_REP]: 'CC_REP',
  [Role.CORP_MASTER_ADMIN]: 'CORP_MASTER_ADMIN',
  [Role.FACILITY_MASTER_ADMIN]: 'FACILITY_MASTER_ADMIN',
  [Role.TECH_SUPPORT]: 'TECH_SUPPORT',
  [Role.FACILITY_DOCTOR]: 'FACILITY_DOCTOR',
  [Role.PARTNER]: 'PARTNER',
  [Role.INTERNAL_DOCTOR]: 'INTERNAL_DOCTOR',
  [Role.EXTERNAL_DOCTOR]: 'EXTERNAL_DOCTOR',
  [Role.FACILITY_NURSE]: 'FACILITY_NURSE',
};

export enum Permission {
  CONNECT_ACCESS_DETAILS,
  CONNECT_CHANGE,
  CONNECT_ASSIGNMENT_CHANGE,
  CONNECT_ALL_STATUSES_CHANGE,
  CONNECT_CBNN_ASSIGNMENT_CHANGE,
  CONNECT_ASSIGN_TO_SELF,
  CONNECT_VIEW_VIDEO_CHECKBOX,
  CONNECT_VIDEO_CHANGE,
  CONNECT_FOLLOW_UP_VIDEO_CHANGE,
  CONNECT_PATIENT_CHANGE,
  CONNECT_CALL_REASON_CHANGE,
  CONNECT_CLINICIAN_NOTES_VIEW,
  CONNECT_CLINICIAN_NOTES_CHANGE,
  CONNECT_CCNC_NOTES_CHANGE,
  CONNECT_INSURANCE_PARTNERS_CHANGE,
  CONNECT_PROGRESS_NOTE_CHANGE,
  CONNECT_DIAGNOSIS_VIEW,
  CONNECT_DIAGNOSIS_CHANGE,
  CONNECT_IMPORT_PROGRESS_NOTE,
  CONNECT_EXAM_VIEW,
  CONNECT_EXAM_CHANGE,
  CONNECT_CONSULTATION_TYPE_CHANGE,
  CONNECT_DURATION_CHANGE,
  CONNECT_BILLING_CODE_CHANGE,
  CONNECT_LOCATION_TYPE_CHANGE,
  CONNECT_PCC_DATA_VIEW,
  ENCOUNTER_ATTACHMENT_DOWNLOAD,

  // TODO: consider reolution modes aside with simple permissions.
  //  Should be smth like permission request is CONNECT_FILTER_STATUS_PRESELECT
  //  and permission request resolution should be one of [SOME, ALL, ALL_IF_VIDEO, DENIED] instead of just boolean
  CONNECT_FILTER_STATUS_PRESELECT_SOME,
  CONNECT_FILTER_STATUS_PRESELECT_ALL,

  NOTIFICATION_SETTINGS_VIEW,

  CORPORATION_TEAMS_READ,
  CORPORATION_TEAMS_WRITE,
  CORPORATION_TEAM_LOCATIONS_READ,
  CORPORATION_TEAM_LOCATIONS_WRITE,

  MESSAGING_SETTINGS_VIEW,
  CALL_DISTRIBUTION_SETTINGS_VIEW,

  INSURANCE_PARTNERS_VIEW,
  CORP_INSURANCE_PARTNERS_VIEW,
  EXTERNAL_PROVIDER_TEAMS_VIEW,
  CORP_INSURANCE_PARTNERS_SET_ACTIVE,

  CROSS_CORP_GROUPS_VIEW,

  PROFILE_SET_SIGNATURE,

  PATIENT_MANAGER_ACCESS,
  PATIENT_CREATE,
  PATIENT_UPDATE,
  PATIENT_LINK_TO_CONNECT,

  PATIENT_INFO_VIEW,

  PATIENT_INSURANCE_CREATE,
  PATIENT_INSURANCE_UPDATE,
  PATIENT_INSURANCE_DELETE,
  PATIENT_INSURANCE_SYNC,
  PATIENT_INSURANCE_PROPS_SYNC,

  CORP_ADMIN_SET,
  NOT_EXISTS,

  EMR_SYNC_VIEW,
  EMPLOYEE_LAST_LOGIN_VIEW,

  CONNECT_EMR_DATA_SYBNC_CHANGE,

  GROUPING_SETTINGS_MANAGE,
  PERIODICAL_FACILITY_REPORTS_MANAGE,
  PERIODICAL_CORPORATION_REPORTS_MANAGE,
  EMR_LOGIN,

  CREATE_ROUNDING_LIST,
  DELETE_ROUNDING_LIST,
  UPDATE_ROUNDING_LIST,
  DUPLICATE_ROUNDING_LIST,
  GET_ROUNDING_LIST,
  CAN_SEND_ROUNDING_ENCOUNTERS,
  DUPLICATE_AND_PUBLISH_ROUNDING_LIST,
  MANUAL_MAKE_ROUNDING_LIST_ACTIVE,
  FINALIZE_ROUNDING_LIST,
  MANUAL_COMPLETE_ROUNDING_LIST,
  REOPEN_ROUNDING,

  CREATE_UPDATE_ROUNDING_PATIENT,
  DELETE_ROUNDING_PATIENT,
  CAN_MANAGE_ROUNDING_PATIENTS,
  UPDATE_ROUNDING_OWNERSHIP,

  GET_ROUNDING_CONNECT,
  CHANGE_USER_CCG,
  GET_AGREEMENT_ACCEPTANCE_HISTORY,
}

export const PARTIAL_ACCESS_TO_ROUNDING: Role[] = [
  Role.CORPORATION_ADMIN,
  Role.FACILITY_ADMIN,
  Role.CORP_MASTER_ADMIN,
  Role.FACILITY_MASTER_ADMIN,
  Role.FACILITY_DOCTOR,
];

export const TELEHEALTH_EMPLOYEE: Role[] = [
  Role.TELEHEALTH_ADMIN,
  Role.TECH_SUPPORT,
  Role.SUB_ADMIN,
  Role.ENCOUNTER_MANAGER,
  Role.CC_REP,
];

export const CORPORATION_EMPLOYEE: Role[] = [Role.CORPORATION_ADMIN, Role.CORP_MASTER_ADMIN];

export const FACILITY_EMPLOYEE: Role[] = [
  Role.NURSE,
  Role.FACILITY_ADMIN,
  Role.FACILITY_MASTER_ADMIN,
  Role.FACILITY_DOCTOR,
];

export const permissionMap: Record<Permission, Set<Role>> = {
  [Permission.CONNECT_ACCESS_DETAILS]: new Set([
    Role.TELEHEALTH_ADMIN,
    Role.TECH_SUPPORT,
    Role.SUB_ADMIN,
    Role.MD,
    Role.ENCOUNTER_MANAGER,
  ]),
  [Permission.CONNECT_CHANGE]: new Set([Role.MD, Role.ENCOUNTER_MANAGER]),
  [Permission.CONNECT_ASSIGNMENT_CHANGE]: new Set([
    Role.TELEHEALTH_ADMIN,
    Role.TECH_SUPPORT,
    Role.SUB_ADMIN,
    Role.ENCOUNTER_MANAGER,
    Role.INTERNAL_DOCTOR,
  ]),
  [Permission.CONNECT_ASSIGN_TO_SELF]: new Set([Role.INTERNAL_DOCTOR]),
  [Permission.CONNECT_VIEW_VIDEO_CHECKBOX]: new Set([
    Role.TELEHEALTH_ADMIN,
    Role.TECH_SUPPORT,
    Role.SUB_ADMIN,
    Role.ENCOUNTER_MANAGER,
  ]),
  [Permission.CONNECT_FOLLOW_UP_VIDEO_CHANGE]: new Set([Role.MD]),
  [Permission.CONNECT_VIDEO_CHANGE]: new Set([Role.MD]),
  [Permission.CONNECT_PATIENT_CHANGE]: new Set([Role.MD]),
  [Permission.CONNECT_CALL_REASON_CHANGE]: new Set([Role.MD, Role.ENCOUNTER_MANAGER]),
  [Permission.CONNECT_CLINICIAN_NOTES_VIEW]: new Set([
    Role.INTERNAL_DOCTOR,
    Role.ENCOUNTER_MANAGER,
    Role.EXTERNAL_DOCTOR,
  ]),
  [Permission.CONNECT_CLINICIAN_NOTES_CHANGE]: new Set([
    Role.INTERNAL_DOCTOR,
    Role.ENCOUNTER_MANAGER,
    Role.EXTERNAL_DOCTOR,
  ]),
  [Permission.CONNECT_CCNC_NOTES_CHANGE]: new Set([Role.INTERNAL_DOCTOR, Role.ENCOUNTER_MANAGER]),
  [Permission.CONNECT_INSURANCE_PARTNERS_CHANGE]: new Set([Role.MD, Role.ENCOUNTER_MANAGER]),
  [Permission.CONNECT_PROGRESS_NOTE_CHANGE]: new Set([Role.MD, Role.EXTERNAL_DOCTOR]),
  [Permission.CONNECT_DIAGNOSIS_VIEW]: new Set([
    Role.INTERNAL_DOCTOR,
    Role.ENCOUNTER_MANAGER,
    Role.TELEHEALTH_ADMIN,
    Role.SUB_ADMIN,
    Role.TECH_SUPPORT,
  ]),
  [Permission.CONNECT_DIAGNOSIS_CHANGE]: new Set([Role.INTERNAL_DOCTOR, Role.EXTERNAL_DOCTOR]),
  [Permission.CONNECT_IMPORT_PROGRESS_NOTE]: new Set([Role.MD, Role.EXTERNAL_DOCTOR]),
  [Permission.CONNECT_EXAM_VIEW]: new Set([Role.MD]),
  [Permission.CONNECT_EXAM_CHANGE]: new Set([Role.MD]),
  [Permission.CONNECT_CONSULTATION_TYPE_CHANGE]: new Set([Role.INTERNAL_DOCTOR]),
  [Permission.CONNECT_DURATION_CHANGE]: new Set([Role.INTERNAL_DOCTOR]),
  [Permission.CONNECT_BILLING_CODE_CHANGE]: new Set([Role.INTERNAL_DOCTOR, Role.ENCOUNTER_MANAGER]),
  [Permission.CONNECT_LOCATION_TYPE_CHANGE]: new Set([Role.INTERNAL_DOCTOR, Role.ENCOUNTER_MANAGER]),
  [Permission.CONNECT_PCC_DATA_VIEW]: new Set([Role.MD]),
  [Permission.ENCOUNTER_ATTACHMENT_DOWNLOAD]: new Set([
    Role.MD,
    Role.TELEHEALTH_ADMIN,
    Role.SUB_ADMIN,
    Role.ENCOUNTER_MANAGER,
  ]),

  [Permission.CONNECT_FILTER_STATUS_PRESELECT_SOME]: new Set([Role.ENCOUNTER_MANAGER]),
  [Permission.CONNECT_FILTER_STATUS_PRESELECT_ALL]: new Set([Role.MD]),
  [Permission.NOTIFICATION_SETTINGS_VIEW]: new Set<Role>([Role.INTERNAL_DOCTOR]),
  [Permission.CORPORATION_TEAMS_READ]: new Set([
    Role.CORPORATION_ADMIN,
    Role.CORP_MASTER_ADMIN,
    Role.SUB_ADMIN,
    Role.TELEHEALTH_ADMIN,
    Role.TECH_SUPPORT,
  ]),
  [Permission.CORPORATION_TEAMS_WRITE]: new Set([
    Role.CORPORATION_ADMIN,
    Role.CORP_MASTER_ADMIN,
    Role.SUB_ADMIN,
    Role.TELEHEALTH_ADMIN,
    Role.TECH_SUPPORT,
  ]),
  [Permission.CORPORATION_TEAM_LOCATIONS_READ]: new Set([
    Role.CORPORATION_ADMIN,
    Role.CORP_MASTER_ADMIN,
    Role.SUB_ADMIN,
    Role.TELEHEALTH_ADMIN,
    Role.TECH_SUPPORT,
  ]),
  [Permission.CORPORATION_TEAM_LOCATIONS_WRITE]: new Set([
    Role.CORPORATION_ADMIN,
    Role.CORP_MASTER_ADMIN,
    Role.SUB_ADMIN,
    Role.TELEHEALTH_ADMIN,
    Role.TECH_SUPPORT,
  ]),
  [Permission.CONNECT_CBNN_ASSIGNMENT_CHANGE]: new Set([Role.INTERNAL_DOCTOR]),
  [Permission.CONNECT_ALL_STATUSES_CHANGE]: new Set([Role.TELEHEALTH_ADMIN, Role.SUB_ADMIN, Role.TECH_SUPPORT]),
  [Permission.MESSAGING_SETTINGS_VIEW]: new Set([Role.TECH_SUPPORT]),
  [Permission.CALL_DISTRIBUTION_SETTINGS_VIEW]: new Set([Role.TELEHEALTH_ADMIN, Role.TECH_SUPPORT]),
  [Permission.INSURANCE_PARTNERS_VIEW]: new Set([Role.TELEHEALTH_ADMIN, Role.SUB_ADMIN, Role.TECH_SUPPORT]),
  [Permission.CROSS_CORP_GROUPS_VIEW]: new Set([Role.TELEHEALTH_ADMIN, Role.SUB_ADMIN, Role.TECH_SUPPORT]),
  [Permission.CORP_INSURANCE_PARTNERS_VIEW]: new Set([Role.TELEHEALTH_ADMIN, Role.SUB_ADMIN, Role.TECH_SUPPORT]),
  [Permission.EXTERNAL_PROVIDER_TEAMS_VIEW]: new Set([Role.TELEHEALTH_ADMIN, Role.SUB_ADMIN, Role.TECH_SUPPORT]),
  [Permission.CORP_INSURANCE_PARTNERS_SET_ACTIVE]: new Set([Role.TELEHEALTH_ADMIN, Role.SUB_ADMIN, Role.TECH_SUPPORT]),
  [Permission.PROFILE_SET_SIGNATURE]: new Set([Role.INTERNAL_DOCTOR]),
  [Permission.PATIENT_MANAGER_ACCESS]: new Set([
    Role.INTERNAL_DOCTOR,
    Role.ENCOUNTER_MANAGER,
    Role.TELEHEALTH_ADMIN,
    Role.SUB_ADMIN,
    Role.TECH_SUPPORT,
  ]),
  [Permission.PATIENT_CREATE]: new Set([
    Role.INTERNAL_DOCTOR,
    Role.ENCOUNTER_MANAGER,
    Role.TELEHEALTH_ADMIN,
    Role.SUB_ADMIN,
  ]),
  [Permission.PATIENT_UPDATE]: new Set([
    Role.INTERNAL_DOCTOR,
    Role.ENCOUNTER_MANAGER,
    Role.TELEHEALTH_ADMIN,
    Role.SUB_ADMIN,
  ]),
  [Permission.PATIENT_LINK_TO_CONNECT]: new Set([
    Role.MD,
    Role.ENCOUNTER_MANAGER,
    Role.TELEHEALTH_ADMIN,
    Role.SUB_ADMIN,
  ]),
  [Permission.PATIENT_INFO_VIEW]: new Set([
    Role.INTERNAL_DOCTOR,
    Role.ENCOUNTER_MANAGER,
    Role.TELEHEALTH_ADMIN,
    Role.SUB_ADMIN,
  ]),
  [Permission.PATIENT_INSURANCE_CREATE]: new Set([
    Role.INTERNAL_DOCTOR,
    Role.ENCOUNTER_MANAGER,
    Role.TELEHEALTH_ADMIN,
    Role.SUB_ADMIN,
  ]),
  [Permission.PATIENT_INSURANCE_UPDATE]: new Set([
    Role.INTERNAL_DOCTOR,
    Role.ENCOUNTER_MANAGER,
    Role.TELEHEALTH_ADMIN,
    Role.SUB_ADMIN,
  ]),
  [Permission.PATIENT_INSURANCE_DELETE]: new Set([
    Role.INTERNAL_DOCTOR,
    Role.ENCOUNTER_MANAGER,
    Role.TELEHEALTH_ADMIN,
    Role.SUB_ADMIN,
  ]),
  [Permission.PATIENT_INSURANCE_SYNC]: new Set([Role.INTERNAL_DOCTOR, Role.TELEHEALTH_ADMIN, Role.SUB_ADMIN]),
  [Permission.PATIENT_INSURANCE_PROPS_SYNC]: new Set([Role.INTERNAL_DOCTOR, Role.TELEHEALTH_ADMIN, Role.SUB_ADMIN]),
  [Permission.NOT_EXISTS]: new Set(),
  [Permission.CORP_ADMIN_SET]: new Set([
    Role.TELEHEALTH_ADMIN,
    Role.SUB_ADMIN,
    Role.TECH_SUPPORT,
    Role.CORP_MASTER_ADMIN,
  ]),
  [Permission.EMR_SYNC_VIEW]: new Set([Role.TELEHEALTH_ADMIN, Role.SUB_ADMIN, Role.TECH_SUPPORT]),
  [Permission.EMPLOYEE_LAST_LOGIN_VIEW]: new Set([Role.TELEHEALTH_ADMIN, Role.SUB_ADMIN, Role.TECH_SUPPORT]),
  [Permission.CONNECT_EMR_DATA_SYBNC_CHANGE]: new Set([Role.MD]),
  [Permission.EMR_LOGIN]: new Set([Role.MD]),
  [Permission.GROUPING_SETTINGS_MANAGE]: new Set([
    Role.FACILITY_ADMIN,
    Role.FACILITY_MASTER_ADMIN,
    Role.FACILITY_DOCTOR,
  ]),
  [Permission.PERIODICAL_FACILITY_REPORTS_MANAGE]: new Set([
    Role.FACILITY_ADMIN,
    Role.FACILITY_MASTER_ADMIN,
    Role.FACILITY_DOCTOR,
  ]),
  [Permission.PERIODICAL_CORPORATION_REPORTS_MANAGE]: new Set([Role.CORP_MASTER_ADMIN, Role.CORPORATION_ADMIN]),
  // Rounding list
  [Permission.CREATE_ROUNDING_LIST]: new Set([
    Role.FACILITY_ADMIN,
    Role.FACILITY_MASTER_ADMIN,
    Role.CORPORATION_ADMIN,
    Role.CORP_MASTER_ADMIN,
    Role.TELEHEALTH_ADMIN,
    Role.ENCOUNTER_MANAGER,
    Role.MD,
  ]),
  [Permission.DELETE_ROUNDING_LIST]: new Set([
    Role.FACILITY_ADMIN,
    Role.FACILITY_MASTER_ADMIN,
    Role.CORPORATION_ADMIN,
    Role.CORP_MASTER_ADMIN,
    Role.TELEHEALTH_ADMIN,
    Role.ENCOUNTER_MANAGER,
    Role.MD,
  ]),
  [Permission.UPDATE_ROUNDING_LIST]: new Set([
    Role.FACILITY_ADMIN,
    Role.FACILITY_MASTER_ADMIN,
    Role.CORPORATION_ADMIN,
    Role.CORP_MASTER_ADMIN,
    Role.TELEHEALTH_ADMIN,
    Role.ENCOUNTER_MANAGER,
    Role.MD,
  ]),
  [Permission.DUPLICATE_ROUNDING_LIST]: new Set([
    Role.FACILITY_ADMIN,
    Role.FACILITY_MASTER_ADMIN,
    Role.CORPORATION_ADMIN,
    Role.CORP_MASTER_ADMIN,
    Role.TELEHEALTH_ADMIN,
    Role.ENCOUNTER_MANAGER,
    Role.MD,
  ]),
  [Permission.CAN_SEND_ROUNDING_ENCOUNTERS]: new Set([
    Role.FACILITY_ADMIN,
    Role.FACILITY_MASTER_ADMIN,
    Role.CORPORATION_ADMIN,
    Role.CORP_MASTER_ADMIN,
    Role.TELEHEALTH_ADMIN,
    Role.ENCOUNTER_MANAGER,
    Role.MD,
  ]),
  [Permission.DUPLICATE_AND_PUBLISH_ROUNDING_LIST]: new Set([
    Role.FACILITY_ADMIN,
    Role.FACILITY_MASTER_ADMIN,
    Role.CORPORATION_ADMIN,
    Role.CORP_MASTER_ADMIN,
    Role.TELEHEALTH_ADMIN,
    Role.ENCOUNTER_MANAGER,
    Role.MD,
  ]),
  [Permission.UPDATE_ROUNDING_OWNERSHIP]: new Set([
    Role.INTERNAL_DOCTOR,
    Role.TELEHEALTH_ADMIN,
    Role.ENCOUNTER_MANAGER,
  ]),
  [Permission.GET_ROUNDING_LIST]: new Set([
    Role.TELEHEALTH_ADMIN,
    Role.SUB_ADMIN,
    Role.TECH_SUPPORT,
    Role.FACILITY_ADMIN,
    Role.FACILITY_MASTER_ADMIN,
    Role.CORPORATION_ADMIN,
    Role.CORP_MASTER_ADMIN,
    Role.MD,
    Role.ENCOUNTER_MANAGER,
  ]),
  // Rounding patients
  [Permission.CREATE_UPDATE_ROUNDING_PATIENT]: new Set([
    Role.FACILITY_ADMIN,
    Role.FACILITY_MASTER_ADMIN,
    Role.CORPORATION_ADMIN,
    Role.CORP_MASTER_ADMIN,
    Role.MD,
    Role.TELEHEALTH_ADMIN,
    Role.ENCOUNTER_MANAGER,
    Role.EXTERNAL_DOCTOR,
  ]),
  [Permission.DELETE_ROUNDING_PATIENT]: new Set([
    Role.FACILITY_ADMIN,
    Role.FACILITY_MASTER_ADMIN,
    Role.CORPORATION_ADMIN,
    Role.CORP_MASTER_ADMIN,
    Role.MD,
    Role.TELEHEALTH_ADMIN,
    Role.ENCOUNTER_MANAGER,
  ]),
  [Permission.CAN_MANAGE_ROUNDING_PATIENTS]: new Set([
    Role.TELEHEALTH_ADMIN,
    Role.SUB_ADMIN,
    Role.TECH_SUPPORT,
    Role.FACILITY_ADMIN,
    Role.FACILITY_MASTER_ADMIN,
    Role.CORPORATION_ADMIN,
    Role.CORP_MASTER_ADMIN,
    Role.ENCOUNTER_MANAGER,
    Role.MD,
  ]),
  [Permission.MANUAL_MAKE_ROUNDING_LIST_ACTIVE]: new Set([Role.INTERNAL_DOCTOR, Role.EXTERNAL_DOCTOR]),
  [Permission.FINALIZE_ROUNDING_LIST]: new Set([Role.INTERNAL_DOCTOR, Role.EXTERNAL_DOCTOR]),
  [Permission.MANUAL_COMPLETE_ROUNDING_LIST]: new Set([Role.INTERNAL_DOCTOR, Role.EXTERNAL_DOCTOR]),
  [Permission.GET_ROUNDING_CONNECT]: new Set([
    Role.TELEHEALTH_ADMIN,
    Role.SUB_ADMIN,
    Role.TECH_SUPPORT,
    Role.ENCOUNTER_MANAGER,
    Role.INTERNAL_DOCTOR,
    Role.EXTERNAL_DOCTOR,
  ]),
  [Permission.REOPEN_ROUNDING]: new Set([Role.INTERNAL_DOCTOR, Role.EXTERNAL_DOCTOR]),
  [Permission.CHANGE_USER_CCG]: new Set([Role.TELEHEALTH_ADMIN, Role.TECH_SUPPORT, Role.SUB_ADMIN]),
  [Permission.GET_AGREEMENT_ACCEPTANCE_HISTORY]: new Set([Role.EXTERNAL_DOCTOR]),
};
