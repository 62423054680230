import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForbiddenComponent {
  constructor(private router: Router) {}

  back(): void {
    // Case: user currently on page `page-user-are-currently-on` and want to navigate to `page-user-want-to-navigate`
    // but guard handle navigation and redirect to `forbidden-page`.
    // In angular before 11 was an option `replace-url` which we was set during construction router tree in guard and actual history looked like:
    //  * page-user-are-currently-on
    //  * page-user-want-to-navigate --- replaced with : forbidden-page
    // But in angular 11 this option was removed and left only in navigation method (not just url creation), so now actual history looks like:
    //  * page-user-are-currently-on
    //  * page-user-want-to-navigate
    //  * forbidden-page
    // According to it we need go back with delta = 2 here.
    if (history.length > 2) {
      history.go(-2);
    } else {
      void this.router.navigate(['/'], {
        skipLocationChange: true,
      });
    }
  }
}
