import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProtectedValuePipe } from '@app/features/protected-value/pipes/protected-value.pipe';
import { ProtectedValueDirective } from './directives/protected-value.directive';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { HidePatientComponent } from '@app/features/protected-value/components/hide-patient/hide-patient.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [ProtectedValuePipe, ProtectedValueDirective, HidePatientComponent],
  imports: [CommonModule, MatSlideToggleModule, MatIconModule, MatTooltipModule],
  exports: [ProtectedValuePipe, ProtectedValueDirective, HidePatientComponent],
})
export class ProtectedValueModule {}
