<div class="not-found-container">
  <div class="not-found-box">
    <div class="container">
      <mat-card class="col-6 mx-auto">
        <mat-card-header>
          <h2>Page not found</h2>
        </mat-card-header>
        <mat-card-content>
          <p>The requested page does not exist.</p>
        </mat-card-content>
        <mat-card-actions>
          <button mat-button (click)="back()">Go back</button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</div>
