import { FacilityScheduleDays, IDay } from '@app/shell/constants/facility-schedule-days';
import { legacyDefined } from '@app/cdk/angular/utils/nullable.utils';

export class FacilityScheduleDayModel implements IDay {
  id!: number;
  name!: string;

  constructor(values: Partial<FacilityScheduleDayModel>) {
    this.id = legacyDefined(values.id) ? values.id! : this.id;
    this.name = this.getDayName(this.id);
  }

  getDayName(id: number): string {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return typeof FacilityScheduleDays.mappedObject[id] !== 'undefined'
      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        FacilityScheduleDays.mappedObject[id].name
      : '';
  }
}
