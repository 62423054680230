export enum ConnectStatusColorTypes {
  UNASSIGNED = 'UNASSIGNED',
  NS = 'NS',
  PNC = 'PNC',
  CIP = 'CIP',
  TIP = 'TIP',
  ER = 'ER',
  FOLLOW_UP = 'FOLLOW_UP',
  COMPLETED = 'COMPLETED',
  ARCHIVED = 'ARCHIVED',
  URGENT = 'URGENT',
  EHR = 'EHR',
  WFT = 'WFT',
  IN_TRIAGE = 'IN_TRIAGE',
  CBNN = 'CBNN',
  EDITING = 'EDITING',
}

export enum ConnectStatusTypeColorCssVariable {
  UNASSIGNED = 'unassigned',
  NS = 'not-started',
  PNC = 'patient-not-called',
  CIP = 'call-in-progress',
  TIP = 'telemedicine-in-progress',
  ER = 'patient-er-follow-up-needed',
  FOLLOW_UP = 'patient-follow-up-needed',
  COMPLETED = 'completed',
  ARCHIVED = 'archived',
  URGENT = 'urgent-follow-up-needed',
  EHR = 'ehr-documentation',
  WFT = 'waiting-for-triage',
  IN_TRIAGE = 'triage',
  CBNN = 'no-callback',
  EDITING = 'editing',
}

export const CONNECT_STATUS_TYPE_COLOR_CSS_ENCOUNTER_MANAGER_OPACITY: Record<ConnectStatusColorTypes, number> = {
  [ConnectStatusColorTypes.UNASSIGNED]: 0.1,
  [ConnectStatusColorTypes.NS]: 0.14,
  [ConnectStatusColorTypes.PNC]: 0.14,
  [ConnectStatusColorTypes.CIP]: 0.16,
  [ConnectStatusColorTypes.TIP]: 0.2,
  [ConnectStatusColorTypes.ER]: 0.2,
  [ConnectStatusColorTypes.FOLLOW_UP]: 0.22,
  [ConnectStatusColorTypes.COMPLETED]: 0.25,
  [ConnectStatusColorTypes.ARCHIVED]: 0.24,
  [ConnectStatusColorTypes.URGENT]: 0.2,
  [ConnectStatusColorTypes.EHR]: 0.5,
  [ConnectStatusColorTypes.WFT]: 0.5,
  [ConnectStatusColorTypes.IN_TRIAGE]: 0.5,
  [ConnectStatusColorTypes.CBNN]: 0.39,
  [ConnectStatusColorTypes.EDITING]: 0.5,
};

export const CONNECT_STATUS_TYPE_COLOR_CSS_CALL_REQUESTS_OPACITY: Record<ConnectStatusColorTypes, number> = {
  [ConnectStatusColorTypes.UNASSIGNED]: 0.1,
  [ConnectStatusColorTypes.NS]: 1,
  [ConnectStatusColorTypes.PNC]: 1,
  [ConnectStatusColorTypes.CIP]: 1,
  [ConnectStatusColorTypes.TIP]: 1,
  [ConnectStatusColorTypes.ER]: 1,
  [ConnectStatusColorTypes.FOLLOW_UP]: 1,
  [ConnectStatusColorTypes.COMPLETED]: 1,
  [ConnectStatusColorTypes.ARCHIVED]: 0.8,
  [ConnectStatusColorTypes.URGENT]: 1,
  [ConnectStatusColorTypes.EHR]: 1,
  [ConnectStatusColorTypes.WFT]: 1,
  [ConnectStatusColorTypes.IN_TRIAGE]: 1,
  [ConnectStatusColorTypes.CBNN]: 1,
  [ConnectStatusColorTypes.EDITING]: 1,
};
