<p>
  Hello there! Webside Connect got updated to the new version #{{ data.available.appData.version }}
  while you had this tab opened. We recommend refreshing this page to the latest version to ensure a
  seamless experience with Webside Connect.
</p>
<div class="actions">
  <button class="action qa-activate" mat-flat-button (click)="handleActivateClick()">
    Refresh
  </button>
  <button
    class="action qa-dismiss"
    mat-flat-button
    (click)="handlePostponeClick()"
    matTooltip="New version will be installed in background and will be available after nearest page refresh."
  >
    Postpone
  </button>
</div>
