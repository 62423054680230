import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, OperatorFunction } from 'rxjs';
import { map, skip, switchMap, take } from 'rxjs/operators';
import { Role } from '@app/cdk/permission/models/permission';
import { ProfileService } from '@app/features/profile/service/profile.service';
import { CurrentUser, CurrentUserImpl, LoginSource } from '@app/core/services/authentication/current-user';
import { newLogger } from '@app/core/services/logger/logger.service';
import { Platform } from '@angular/cdk/platform';
import { filterDefined } from '@app/cdk/angular/directives/observable.utils';
import { AuthStorageKeys } from '@app/core/model/auth';

const logger = newLogger('CurrentUserService');

export function hasRole(role: Role): OperatorFunction<CurrentUser | undefined, boolean> {
  return map(u => u?.hasRole(role) ?? false);
}

export function hasAnyRole(...role: Role[]): OperatorFunction<CurrentUser | undefined, boolean> {
  return map(u => u?.hasAnyRole(...role) ?? false);
}

interface ResolvedUserInternal {
  user?: CurrentUser;
}

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  readonly user$: Observable<CurrentUser | undefined>;
  externalHomeUrl = '';
  private readonly _currentUserInternal$ = new BehaviorSubject<ResolvedUserInternal | undefined>(undefined);

  constructor(private readonly profileService: ProfileService, private readonly platform: Platform) {
    this.user$ = this._currentUserInternal$.pipe(
      filterDefined(),
      map(resolved => resolved.user)
    );
  }

  getCurrentUser(): Observable<CurrentUser | undefined> {
    return this.user$;
  }

  _refresh(source: LoginSource): Observable<CurrentUser | undefined> {
    logger.info('Refresh current user');
    if (!this.platform.isBrowser) {
      // in case of real SSR adjust to use real session and bypass cookies to backend
      logger.info('Running on server, skip current user loading');
      // using timeout for send user after returning observable from this method
      setTimeout(() =>
        this._currentUserInternal$.next({
          user: undefined,
        })
      );
    } else {
      this.refreshCurrentUserInternal(source);
    }

    // using internal model for skip initial (not resolved) value as well as already resolved during subsequent resolve
    return this._currentUserInternal$.pipe(
      skip(1),
      take(1),
      map(r => r?.user)
    );
  }

  _cleanup(): void {
    logger.info('Cleanup current user state');
    this._currentUserInternal$.next({
      user: undefined,
    });
    localStorage.removeItem(AuthStorageKeys.AUTH_TOKEN);
  }

  /**
   * @deprecated use direct call of user$.pipe(map(user => user.hasRole(...)))
   */
  hasRole(...role: Role[]): Observable<boolean> {
    return this.user$.pipe(map(user => user?.hasAnyRole(...role) ?? false));
  }

  /**
   * @deprecated should be private. DO NOT USE IT. Made it public ONLY FOR COMPATIBILITY WITH LEGACY CODE CAN NOT TRANSFORMED INTO REACTIVE IN EASY WAY.
   */
  get _currentUser$(): BehaviorSubject<{ user?: CurrentUser } | undefined> {
    return this._currentUserInternal$;
  }

  private refreshCurrentUserInternal(source: LoginSource): void {
    this.profileService
      .getProfile()
      .pipe(
        switchMap(profile => {
          this.externalHomeUrl = profile.externalHomeUrl ?? '';
          return of(new CurrentUserImpl(profile, source));
        })
      )
      .subscribe(
        profile => {
          logger.info('Broadcast received profile', profile);
          this._currentUserInternal$.next({
            user: profile,
          });
        },
        e => {
          logger.error('Failed to load current user profile', e);
          this._currentUserInternal$.next({
            user: undefined,
          });
        }
      );
  }
}
