export namespace MomentFormats {
  export const ISO_DATE = 'YYYY-MM-DD';
  export const MOMENT_DATE = 'MM/DD/YYYY';
  export const MOMENT_MONTH_YEAR_LABEL = 'MMM YYYY';
  export const MOMENT_DATE_ALLY_LABEL = 'LL';
  export const MOMENT_MONTH_YEAR_ALLY_LABEL = 'MMMM YYYY';
  export const MOMENT_TIME_AM_PM = 'hh:mm A';
  export const MOMENT_TIME_AM_PM_FULL = 'hh:mm a';
  export const MOMENT_SHORT_TIME = 'HHmm';
  export const MOMENT_FULL_DATE = 'hh:mm A MM/DD/YYYY Z';
  export const MOMENT_FULL_DATE_UTC = 'hh:mm A MM/DD/YYYY (UTC Z)';
  export const MOMENT_FULL_DATE_WITHOUT_TZ = 'hh:mm A MM/DD/YYYY';
  export const MOMENT_HOURS_MINUTES_AMPM = 'hh:mm A';
  export const MOMENT_HOURS_MINUTES_EST = 'hh:mm z';
  export const MOMENT_ENCOUNTERS_FULL_DATE = 'MMMM DD, YYYY';
  export const MOMENT_MONTH_AND_DAY_OF_WEEK = 'MMMM, DD (dddd)';
  export const MOMENT_SHORT_MONTH_AND_DAY = 'MMM, DD';
  export const MOMENT_ISO_DATE = 'YYYY-MM-DD';
  export const MOMENT_ISO_SHORT_DATE = 'YYYYMMDD';
  export const MOMENT_ISO_SHORT_MONTH_YEAR = 'YYYYMM';
}
