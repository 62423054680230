import { Phone } from '@app/shell/models/phone/phone';
import { FacilityEmrInfo } from '@app/shell/models/facility/facility-common.model';
import { FacilityMetaType } from '@app/shell/models/facility/facility-meta-type.enum';

export interface IProfileFacility {
  facilityId: number;
  name: string;
  phoneNumber: Phone;
  codeBlueEnabled: boolean;
  corpEmrEnabled: boolean;
  externalProvidersEnabled: boolean;
  emrInfo?: FacilityEmrInfo;
  metaType: FacilityMetaType;
}

export class FacilityNurseInfo implements IProfileFacility {
  facilityId!: number;
  name = '';
  phoneNumber: Phone;
  codeBlueEnabled: boolean;
  corpEmrEnabled: boolean;
  externalProvidersEnabled: boolean;
  emrInfo?: FacilityEmrInfo;
  metaType: FacilityMetaType = FacilityMetaType.POST_ACUTE;

  constructor(values: Partial<FacilityNurseInfo> = {}) {
    this.facilityId = values.facilityId != undefined ? values.facilityId : this.facilityId;
    this.name = values.name != undefined ? values.name : this.name;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.phoneNumber = values.phoneNumber != undefined ? new Phone(values.phoneNumber) : new Phone(this.phoneNumber);
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    this.codeBlueEnabled = !!values.codeBlueEnabled;
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    this.corpEmrEnabled = !!values.corpEmrEnabled;
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    this.metaType = values.metaType ?? FacilityMetaType.POST_ACUTE;
    this.emrInfo = values.emrInfo;
    this.externalProvidersEnabled = values.externalProvidersEnabled ?? false;
  }
}
