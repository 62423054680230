import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogSettings } from '@app/shell/models/confirmation/confirm-dialog-settings';

@Component({
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalConfirmComponent {
  approved = this.data.checkText == undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogSettings,
    public dialogRef: MatDialogRef<ModalConfirmComponent>
  ) {}

  /**
   * Close modal
   */
  closeDialog(isConfirmed = false): void {
    this.dialogRef.close(isConfirmed);
  }
}
