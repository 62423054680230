export enum EntityName {
  ENCOUNTER = 'Encounter',
  PATIENT = 'Patient',
  ROUNDING = 'Rounding List',
  FACILITY = 'Facility',
  FACILITIES = 'Facilities',
  CORPORATION = 'Corporation',
  USER = 'User',
  USER_CCG = 'User Cross Corporation Group',
  ASSESSMENT = 'Assessment',
  ASSESSMENT_DIAGNOSIS = 'Diagnosis',
  SERVICE_CODES = 'Service Codes',
  DURATION = 'Duration',
  BILLING_CODE = 'Billing Code',
  EXTERNAL_PROVIDER_TEAM = 'External Provider Team',
  PASSWORD = 'Password',
  CORPORATION_AGREEMENT = 'Corporation Agreement',
  TELEMEDICINE = 'Telemedicine',
}
