export interface IMode {
  id: number;
  name: string;
}

/**
 * Facility Schedule Shift Mode
 */
export class FacilityScheduleShiftMode {
  public static readonly MODE_24H: IMode = { id: 1, name: '24H' };
  public static readonly MODE_SELECTED_HOURS: IMode = {
    id: 2,
    name: 'Selected Hours',
  };
  public static readonly MODE_NO_COVERAGE: IMode = {
    id: 3,
    name: 'No Coverage',
  };
}
