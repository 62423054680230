import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  NOTIFICATION_DATA,
  NotificationInstanceRef,
  NotificationRef,
} from '@app/core/services/global-info-modal/notification-container/notification-container.model';
import { UpdateActivated } from '@app/shell/components/version-update-checker/version-update.model';
import { CurrentUserService } from '@app/core/services/authentication/current-user.service';
import { map, shareReplay } from 'rxjs/operators';
import { Role } from '@app/cdk/permission/models/permission';

export interface ApplicationUpdatedAction {
  type: 'dismiss' | 'show-changelog';
}

@Component({
  selector: 'app-application-updated-toast',
  templateUrl: './application-updated-toast.component.html',
  styleUrls: ['./application-updated-toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationUpdatedToastComponent implements NotificationInstanceRef<ApplicationUpdatedAction> {
  showChangelog$ = this.currentUser.user$.pipe(
    map(user => user?.hasRole(Role.TECH_SUPPORT)),
    shareReplay(1)
  );

  constructor(
    readonly ref: NotificationRef<ApplicationUpdatedAction>,
    @Inject(NOTIFICATION_DATA) readonly data: UpdateActivated,
    private currentUser: CurrentUserService
  ) {}

  handleGotItClick(): void {
    this.ref.action({ type: 'dismiss' }, true);
  }

  handleWhatsNewClick(): void {
    this.ref.action({ type: 'show-changelog' }, true);
  }
}
