import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, Observable, Subject } from 'rxjs';
import { filter, first, map, shareReplay, startWith, switchMap, take } from 'rxjs/operators';

export function formatOauthKey(userId: number | string | null, corpId: number | string | null): string {
  if (userId === null || corpId === null) {
    return '';
  }

  return `${userId}-${corpId}`;
}

function formatOAuthError(code: string, description: string | undefined): string {
  let result = code;
  if (description != undefined) {
    result += `: ${description}`;
  }

  return result;
}

@Component({
  selector: 'app-oauth-result',
  templateUrl: './oauth-result.component.html',
  styleUrls: ['./oauth-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OauthResultComponent implements OnInit, AfterViewInit {
  timer$?: Observable<number>;
  message?: string;
  hasError = false;

  private start$ = new Subject<void>();

  constructor(private activatedRoute: ActivatedRoute, private readonly router: Router) {}

  ngOnInit(): void {
    const params = this.activatedRoute.snapshot.queryParamMap;

    const reason = params.get('reason') as string | undefined;
    const error = params.get('error') as string | undefined;
    const errorDesc = params.get('error_description') as string | undefined;

    if (error != undefined && error.trim().length > 0) {
      // not assigning expression from 'if' explicitly for 'error' type inference to work
      this.hasError = true;
      this.message = formatOAuthError(error, errorDesc);
    } else {
      this.hasError = false;
      if (reason === 'login') {
        this.message = 'Successfully Logged In';
      } else {
        // If logic will be more complex please split component onto login and logout pages.
        this.message = 'Successfully Logged Out';
      }
    }

    if (!this.hasError) {
      const userId: string | null = params.get('user');
      const corpId: string | null = params.get('corp');

      localStorage.setItem(formatOauthKey(userId, corpId), Date.now().toString(10));

      this.timer$ = this.start$.pipe(
        switchMap(() => interval(1000).pipe(take(3))),
        map(value => 2 - value),
        startWith(3),
        shareReplay(1)
      );

      this.timer$
        .pipe(
          filter(value => value === 0),
          first()
        )
        .subscribe(() => {
          if (params.get('close') === 'true') {
            this.close();
          }
        });
    }
  }

  ngAfterViewInit(): void {
    this.start$.next();
  }

  handleReturnManual(): void {
    this.close();
  }

  private close(): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const parent = window.opener ?? window.parent;
    if (parent == undefined) {
      void this.router.navigateByUrl('/');
    } else {
      window.close();
    }
  }
}
