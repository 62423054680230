import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, TrackByFunction } from '@angular/core';
import { VersionService } from '@app/shell/services/version/version.service';
import { shareReplay } from 'rxjs/operators';
import { Changelog } from '@app/shell/models/version/changelog';

@Component({
  selector: 'app-changelog-modal',
  templateUrl: './changelog-modal.component.html',
  styleUrls: ['./changelog-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeLogModalComponent implements OnInit {
  readonly changelogs$ = this.versionService.getChangeLog().pipe(shareReplay(1));

  selected?: Changelog;

  readonly changelogTrack: TrackByFunction<Changelog> = (index, item) => item.type;

  constructor(private readonly versionService: VersionService, private readonly cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.changelogs$.subscribe(changelog => {
      this.selected = changelog[0];
      this.cd.markForCheck();
    });
  }
}
