import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { AngularCdkModule } from '@app/cdk/angular/angular.module';
import { LayoutModule } from '@app/cdk/layout/layout.module';
import { LockerModule } from '@app/cdk/locker/locker.module';
import { PermissionModule } from '@app/cdk/permission/permission.module';
import { AppPortalsModule } from '@app/cdk/portals/portals.module';
import { SectionsModule } from '@app/cdk/sections/sections.module';
import { TlhFormsModule } from '@app/cdk/tlh-forms/tlh-forms.module';
import { BrandingModule } from '@app/features/branding/branding.module';
import { ContactsModule } from '@app/features/contacts/contacts.module';
import { EncounterModule } from '@app/features/encounter/encounter.module';
import { IcdModule } from '@app/features/icd/icd.module';
import { ProtectedValueModule } from '@app/features/protected-value/protected-value.module';
import { SoapModule } from '@app/features/soap/soap.module';
import { AgreementModule } from '@app/modules/agreement/agreement.module';
import { ChangeLogModalComponent } from '@app/shared/components/changelog-modal/changelog-modal.component';
import { DataTableComponent } from '@app/shared/components/data-table/data-table.component';
import { DateSelectDialogComponent } from '@app/shared/components/date-select-dialog/date-select-dialog.component';
import { EncountersDataTableComponent } from '@app/shared/components/encounters-data-table/encounters-data-table.component';
import { ProviderGroupFilterComponent } from '@app/shared/components/reporting/controls/provider-group-filter/provider-group-filter.component';
import { EncounterReportFormComponent } from '@app/shared/components/reporting/forms/encounter-report-form/encounter-report-form.component';
import { SchedulingComponent } from '@app/shared/components/scheduling/scheduling.component';
import { PhonePipe } from '@app/shared/pipes/phone/phone.pipe';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { NgSelectModule } from '@ng-select/ng-select';
import { TextMaskModule } from 'angular2-text-mask';
import { NouisliderModule } from 'ng2-nouislider';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { ComponentNamePipe } from './components/changelog-modal/component-name.pipe';
import { EncounterRequestMdListElementComponent } from './components/encounter-request-md-list-element/encounter-request-md-list-element.component';
import { FacilityReportsSettingsComponent } from './components/facility-reports-settings-modal/facility-reports-settings.component';
import { FacilityComponent } from './components/facility/facility.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { GlobalInfoModalComponent } from './components/global-info-modal/global-info-modal.component';
import { InputDialogComponent } from './components/input-dialog/input-dialog.component';
import { NurseDashboardDistributeModalComponent } from './components/nurse-dashboard-distribute-modal/nurse-dashboard-distribute-modal.component';
import { NurseDashboardStatusImageComponent } from './components/nurse-dashboard-status-image/nurse-dashboard-status-image.component';
import { ReportGeneratingNotificationComponent } from './components/report-generating-notification/report-generating-notification.component';
import { DateRangeComponent } from './components/reporting/controls/date-range/date-range.component';
import { EncounterFilterComponent } from './components/reporting/controls/encounter-filter/encounter-filter.component';
import { FacilitySelectComponent } from './components/reporting/controls/facility-select/facility-select.component';
import { CustomReportComponent } from './components/reporting/features/custom-report/custom-report.component';
import { ProviderReportComponent } from './components/reporting/features/provider-report/provider-report.component';
import { RollUpReportFormComponent } from './components/reporting/forms/roll-up-report-form/roll-up-report-form.component';
import { CustomReportModalComponent } from './components/reporting/modals/custom-report-modal/custom-report-modal.component';
import { RequestCreateModalComponent } from './components/time-off-requests/request-create-modal/request-create-modal.component';
import { RequestProcessModalComponent } from './components/time-off-requests/request-process-modal/request-process-modal.component';
import { TimeOffRequestsComponent } from './components/time-off-requests/time-off-requests.component';
import { VersionInfoComponent } from './components/version-info/version-info.component';
import { BlinkActiveCallDirective } from './directives/blink-acvite-call/blink-active-call.directive';
import { NotificationTargetLabelPipe } from './pipes/notification-target-label.pipe';
import { NurseConnectStatusLabelPipe } from './pipes/nurse-connect-status-label.pipe';
import { StringFileSizePipe } from './pipes/string-file-size.pipe';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatCheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    MatDialogModule,
    MatSortModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatSelectModule,
    MatAutocompleteModule,
    TextMaskModule,
    MatRadioModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatTooltipModule,
    MatDividerModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatExpansionModule,
    MatBadgeModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatExpansionModule,
    InfiniteScrollModule,
    NouisliderModule,
    PerfectScrollbarModule,
    RouterModule,
    NgSelectModule,
    SoapModule,
    IcdModule,
    LockerModule,
    TlhFormsModule,
    SectionsModule,
    LayoutModule,
    EncounterModule,
    AngularCdkModule,
    ContactsModule,
    ProtectedValueModule,
    BrandingModule,
    MatListModule,
    AppPortalsModule,
    MatMenuModule,
    AgreementModule,
    PermissionModule,
  ],
  declarations: [
    GlobalInfoModalComponent,
    EncountersDataTableComponent,
    EncounterRequestMdListElementComponent,
    PhonePipe,
    EncounterReportFormComponent,
    DataTableComponent,
    ChangeLogModalComponent,
    SchedulingComponent,
    TimeOffRequestsComponent,
    RequestProcessModalComponent,
    RequestCreateModalComponent,
    FacilityComponent,
    BreadcrumbsComponent,
    CustomReportModalComponent,
    CustomReportComponent,
    ProviderReportComponent,
    NurseDashboardDistributeModalComponent,
    FacilityReportsSettingsComponent,
    NurseDashboardStatusImageComponent,
    NurseConnectStatusLabelPipe,
    NotificationTargetLabelPipe,
    FileUploadComponent,
    RollUpReportFormComponent,
    DateRangeComponent,
    FacilitySelectComponent,
    EncounterFilterComponent,
    ProviderGroupFilterComponent,
    StringFileSizePipe,
    ReportGeneratingNotificationComponent,
    ComponentNamePipe,
    VersionInfoComponent,
    DateSelectDialogComponent,
    BlinkActiveCallDirective,
    InputDialogComponent,
  ],
  providers: [PhonePipe],
  entryComponents: [
    GlobalInfoModalComponent,
    ChangeLogModalComponent,
    RequestProcessModalComponent,
    RequestCreateModalComponent,
    CustomReportModalComponent,
    NurseDashboardDistributeModalComponent,
    FacilityReportsSettingsComponent,
    ReportGeneratingNotificationComponent,
  ],
  exports: [
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatCheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    MatDialogModule,
    MatSortModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatSelectModule,
    MatAutocompleteModule,
    TextMaskModule,
    MatRadioModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatTooltipModule,
    MatDividerModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    EncountersDataTableComponent,
    MatExpansionModule,
    EncounterRequestMdListElementComponent,
    PhonePipe,
    EncounterReportFormComponent,
    MatBadgeModule,
    DataTableComponent,
    MatSlideToggleModule,
    MatSnackBarModule,
    InfiniteScrollModule,
    NouisliderModule,
    PerfectScrollbarModule,
    SchedulingComponent,
    BreadcrumbsComponent,
    FacilityComponent,
    FacilityReportsSettingsComponent,
    NurseDashboardStatusImageComponent,
    NurseConnectStatusLabelPipe,
    NotificationTargetLabelPipe,
    FileUploadComponent,
    RollUpReportFormComponent,
    StringFileSizePipe,
    VersionInfoComponent,
    BlinkActiveCallDirective,
    PermissionModule,
    InputDialogComponent,
  ],
})
export class SharedModule {}
