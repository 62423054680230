export function checkDefined<T>(value: T | undefined | null, name?: string): void {
  if (value == undefined) {
    throw Error(`${name ?? ''} must be defined`);
  }
}

export function mustDefined<T>(value: T | undefined | null, name?: string): T {
  if (value == undefined) {
    throw Error(`${name ?? ''} must be defined`);
  }
  return value;
}

/**
 * Hides old (type unsafe) null/undefined checks Still does work in unsafe way but hides lint warnings
 */
export function legacyDefined<T>(value: T | null | undefined): value is T {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  return !!value;
}

export function mustDefinedLegacy<T>(value: T | undefined | null, name?: string): T {
  if (legacyDefined(value)) {
    return value;
  }
  throw Error(`${name ?? ''} must be defined`);
}

export function checkSet<T>(values: T[]): ReadonlySet<T | undefined> {
  return new Set(values);
}

export function onlyDefined<T>(array: (T | undefined | null)[]): T[] {
  return array.filter(e => e != undefined) as T[]; // nosonar
}
