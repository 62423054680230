import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionComponent } from './section/section.component';
import { SectionHeaderComponent } from './section-header/section-header.component';
import { SectionBlockComponent } from './section-block/section-block.component';
import { SectionBlockHeaderComponent } from './section-block-header/section-block-header.component';
import { MatCardModule } from '@angular/material/card';
import { SectionActionsComponent } from './section-actions/section-actions.component';
import { SectionBlockActionsComponent } from './section-block-actions/section-block-actions.component';
import { LayoutModule } from '@app/cdk/layout/layout.module';
import { WarnActionsComponent, WarnComponent } from './warn/warn.component';
import { NoticeActionsComponent, NoticeComponent } from '@app/cdk/sections/notice/notice.component';

@NgModule({
  declarations: [
    SectionComponent,
    SectionHeaderComponent,
    SectionBlockComponent,
    SectionBlockHeaderComponent,
    SectionActionsComponent,
    SectionBlockActionsComponent,
    WarnComponent,
    WarnActionsComponent,
    NoticeComponent,
    NoticeActionsComponent,
  ],
  exports: [
    SectionComponent,
    SectionHeaderComponent,
    SectionBlockComponent,
    SectionActionsComponent,
    SectionBlockHeaderComponent,
    SectionBlockActionsComponent,
    WarnComponent,
    WarnActionsComponent,
    NoticeComponent,
    NoticeActionsComponent,
  ],
  imports: [CommonModule, MatCardModule, LayoutModule],
})
export class SectionsModule {}
