import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export interface NotificationInstanceRef<T> {
  readonly ref: NotificationRef<T>;
}

export abstract class NotificationRef<T> {
  abstract readonly actions$: Observable<T>;

  abstract action(action: T, close?: boolean): void;
}

export const NOTIFICATION_DATA = new InjectionToken('NOTIFICATION_DATA');
