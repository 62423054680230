<div class="forbidden-container">
  <div class="forbidden-box">
    <div class="container">
      <mat-card class="col-6 mx-auto">
        <mat-card-header>
          <h2>Page forbidden</h2>
        </mat-card-header>
        <mat-card-content>
          <p>
            The requested page you are looking for is forbidden for your role.
          </p>
        </mat-card-content>
        <mat-card-actions>
          <button mat-button (click)="back()">Go back</button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</div>
