import { legacyDefined } from '@app/cdk/angular/utils/nullable.utils';

export interface ICorporationShort {
  id: number;
  name: string;
}

export class CorporationShort implements ICorporationShort {
  id!: number;
  name = '';

  constructor(values: Partial<ICorporationShort> = {}) {
    this.id = legacyDefined(values.id) ? values.id! : this.id;
    this.name = legacyDefined(values.name) ? values.name! : this.name;
  }
}
