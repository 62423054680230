import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { CustomizationService } from '@app/shell/services/customization/customization.service';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent {
  @Output() loaded = new EventEmitter<void>();

  constructor(private readonly customizationService: CustomizationService) {}

  get logoUrl(): string {
    return this.customizationService.getLogoUrl();
  }
}
