import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SystemWideNavigationService {
  constructor(private readonly router: Router) {}

  createRootTree(): UrlTree {
    return this.createRootTreeInternal();
  }

  createForbiddenTree(): UrlTree {
    return this.createForbiddenTreeInternal();
  }

  navigateToNotFound(): void {
    const urlTree = this.router.createUrlTree(['not-found']);
    void this.router.navigateByUrl(urlTree, { replaceUrl: true });
  }

  navigateToForbidden(): void {
    const urlTree = this.createForbiddenTreeInternal();
    void this.router.navigateByUrl(urlTree, { replaceUrl: true });
  }

  navigateToRoot(): void {
    const urlTree = this.createRootTree();
    void this.router.navigateByUrl(urlTree, { replaceUrl: true });
  }

  private createRootTreeInternal(): UrlTree {
    return this.router.createUrlTree(['/']);
  }

  private createForbiddenTreeInternal(): UrlTree {
    return this.router.createUrlTree(['/forbidden']);
  }
}
