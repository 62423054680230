import { UpdateActivatedEvent, UpdateAvailableEvent } from '@angular/service-worker';

export interface ApplicationData {
  version: string;
}

export interface UpdateAvailable {
  current: {
    hash: string;
    appData?: ApplicationData;
  };
  available: {
    hash: string;
    appData: ApplicationData;
  };
}

export interface UpdateActivated {
  previous?: {
    hash: string;
    appData?: ApplicationData;
  };
  current: {
    hash: string;
    appData: ApplicationData;
  };
}

export function updateAvailableFromEvent(event: UpdateAvailableEvent): UpdateAvailable {
  return {
    ...event,
    current: {
      ...event.current,
      appData: event.current.appData as ApplicationData,
    },
    available: {
      ...event.available,
      appData: event.available.appData as ApplicationData,
    },
  };
}

export function updateActivatedFromEvent(event: UpdateActivatedEvent): UpdateActivated {
  return {
    ...event,
    current: {
      ...event.current,
      appData: event.current.appData as ApplicationData,
    },
    previous:
      event.previous == undefined
        ? undefined
        : {
            ...event.previous,
            appData: event.previous.appData as ApplicationData,
          },
  };
}
