import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutosizeFixDirective } from '@app/cdk/tlh-forms/autosize-fix/autosize-fix.directive';
import { ValidationErrorsDirective } from '@app/cdk/tlh-forms/validation-errors/validation-errors.directive';
import { AutocompleteLazyLoadingDirective } from '@app/cdk/tlh-forms/autocomplete-lazy-loading/autocomplete-lazy-loading.directive';
import { RequiredMarkerComponent } from '@app/cdk/tlh-forms/required-marker/required-marker.component';
import { ValidationSpyDirective } from '@app/cdk/tlh-forms/nested-form/validation-spy.directive';
import { ValidationErrorPipe } from './validation-errors/validation-error.pipe';
import { DenseSelectListDirective } from '@app/cdk/tlh-forms/directives/dense-select-list.directive';
import { LazySelectFieldComponent } from '@app/cdk/tlh-forms/_form-fields/lazy-select-field/lazy-select-field.component';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePickerFieldComponent } from '@app/cdk/tlh-forms/_form-fields/date-picker-field/date-picker-field.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TextMaskModule } from 'angular2-text-mask';
import { AngularCdkModule } from '@app/cdk/angular/angular.module';
import { TextFieldComponent } from '@app/cdk/tlh-forms/_form-fields/text-field/text-field.component';
import { ProtectedValueModule } from '@app/features/protected-value/protected-value.module';
import { IconCheckComponent } from '@app/cdk/tlh-forms/icon-check/icon-check.component';
import { IconCheckOrUnkComponent } from '@app/cdk/tlh-forms/icon-check-or-unk/icon-check-or-unk.component';
import { MatSelectModule } from '@angular/material/select';
import { SimpleSelectFieldComponent } from '@app/cdk/tlh-forms/_form-fields/simple-select-field/simple-select-field.component';
import { TextareaFieldComponent } from '@app/cdk/tlh-forms/_form-fields/textarea-field/textarea-field.component';
import { FieldErrorTranslatorDirective } from '@app/cdk/tlh-forms/validation-errors/field-error-translator.directive';
import { FieldPrefixComponent } from '@app/cdk/tlh-forms/_form-fields/field-projections/field-prefix/field-prefix.component';
import { BorderedTextfieldDirective } from '@app/cdk/tlh-forms/directives/bordered-textfield.directive';
import { ErrorRadioButtonDirective } from '@app/cdk/tlh-forms/directives/error-radio-button.directive';
import { TemplatedSelectFieldComponent } from '@app/cdk/tlh-forms/_form-fields/templated-select-field/templated-select-field.component';
import { TemplatedSelectOptionComponent } from '@app/cdk/tlh-forms/_form-fields/templated-select-field/app-templated-select-option/templated-select-option.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ValidatorFnDirective } from '@app/cdk/tlh-forms/validators/validator-directive';
import { FieldSuffixComponent } from '@app/cdk/tlh-forms/_form-fields/field-projections/field-suffix/field-suffix.component';
import { UnknownValueRendererKeyDirective } from '@app/cdk/tlh-forms/select-unknown-renderer/unknown-value-renderer-key.directive';
import { UnknownValueRendererFnDirective } from '@app/cdk/tlh-forms/select-unknown-renderer/unknown-value-renderer-fn.directive';
import { DatasourceModule } from '@app/cdk/table/datasource/datasource.module';
import { SelectLazyLoadingDirective } from '@app/cdk/tlh-forms/select-lazy-loading/select-lazy-loading.directive';
import { HideExportCsvApexChartsDirective } from '@app/cdk/tlh-forms/directives/hide-export-csv-apex-charts.directive';
import { DatePickerPrevNextFieldComponent } from '@app/cdk/tlh-forms/_form-fields/date-picker-prev-next-field/date-picker-prev-next-field.component';
import { ClearIconButtonComponent } from '@app/cdk/tlh-forms/clear-icon-button/clear-icon-button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TimePickerFieldComponent } from '@app/cdk/tlh-forms/_form-fields/time-picker-field/time-picker-field.component';
import { OwlDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { FieldHintComponent } from '@app/cdk/tlh-forms/_form-fields/simple-select-field/field-hint/field-hint.component';
import { FileSelectFieldComponent } from './_form-fields/file-select-field/file-select-field.component';

@NgModule({
  declarations: [
    AutosizeFixDirective,
    ValidationErrorsDirective,
    AutocompleteLazyLoadingDirective,
    RequiredMarkerComponent,
    ValidationSpyDirective,
    ValidationErrorPipe,
    LazySelectFieldComponent,
    DatePickerFieldComponent,
    DenseSelectListDirective,
    TextFieldComponent,
    IconCheckComponent,
    IconCheckOrUnkComponent,
    SimpleSelectFieldComponent,
    FieldErrorTranslatorDirective,
    TextareaFieldComponent,
    FieldPrefixComponent,
    BorderedTextfieldDirective,
    ErrorRadioButtonDirective,
    TemplatedSelectFieldComponent,
    TemplatedSelectOptionComponent,
    ValidatorFnDirective,
    FieldSuffixComponent,
    UnknownValueRendererKeyDirective,
    UnknownValueRendererFnDirective,
    SelectLazyLoadingDirective,
    HideExportCsvApexChartsDirective,
    DatePickerPrevNextFieldComponent,
    ClearIconButtonComponent,
    TimePickerFieldComponent,
    FieldHintComponent,
    FileSelectFieldComponent,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    AutosizeFixDirective,
    ValidationErrorsDirective,
    AutocompleteLazyLoadingDirective,
    RequiredMarkerComponent,
    ValidationSpyDirective,
    ValidationErrorPipe,
    LazySelectFieldComponent,
    DatePickerFieldComponent,
    DenseSelectListDirective,
    TextFieldComponent,
    IconCheckComponent,
    IconCheckOrUnkComponent,
    SimpleSelectFieldComponent,
    FieldErrorTranslatorDirective,
    TextareaFieldComponent,
    FieldPrefixComponent,
    BorderedTextfieldDirective,
    ErrorRadioButtonDirective,
    TemplatedSelectFieldComponent,
    TemplatedSelectOptionComponent,
    ValidatorFnDirective,
    FieldSuffixComponent,
    UnknownValueRendererKeyDirective,
    UnknownValueRendererFnDirective,
    SelectLazyLoadingDirective,
    HideExportCsvApexChartsDirective,
    DatePickerPrevNextFieldComponent,
    ClearIconButtonComponent,
    TimePickerFieldComponent,
    FieldHintComponent,
    FileSelectFieldComponent,
  ],
  imports: [
    CommonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatIconModule,
    FormsModule,
    MatDatepickerModule,
    TextMaskModule,
    AngularCdkModule,
    ProtectedValueModule,
    MatSelectModule,
    MatTooltipModule,
    DatasourceModule,
    MatProgressBarModule,
    OwlDateTimeModule,
  ],
})
export class TlhFormsModule {}
