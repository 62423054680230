import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone',
})
export class PhonePipe implements PipeTransform {
  transform(value: string): string {
    const viewVal = value ? value : '';

    return viewVal.length === 10 ? viewVal.replace(/([\d]{3})([\d]{3})([\d]{4})/, '($1) $2-$3') : viewVal;
  }
}
