import { Injectable, NgZone, Provider } from '@angular/core';
import { webSocket, WebSocketSubject, WebSocketSubjectConfig } from 'rxjs/webSocket';
import { EMPTY, interval, Observable, Subscription } from 'rxjs';
import { delay, map, publish, retryWhen } from 'rxjs/operators';
import {
  ConnectAssignedView,
  EmrFacilitySyncView,
  NurseDashboardUpdateView,
  PatientEmrDetailsSyncStatusView,
  ReportGeneratedView,
  RoundingShortUpdateView,
  RoundingUpdateView,
  WsMessage,
  WsRequestMessageType,
  WsResponseMessageType,
} from '@app/core/services/websocket/models';
import { newLogger } from '@app/core/services/logger/logger.service';
import { ConnectEmrSyncStatus } from '@app/features/encounter/models/connect-emr-sync';
import { delayedRefCount } from '@app/cdk/angular/dev/delayedRefCount.operator';
import { Platform } from '@angular/cdk/platform';
import { TelemedicineSession } from '@app/features/telemedicine/models/telemedicine-session';

const PING_INTERVAL_MILLIS = 30000;
const RETRY_DELAY_MILLIS = 10000;

const logger = newLogger('WebsocketService');

export abstract class WebsocketService {
  abstract getDashboardObservable(): Observable<NurseDashboardUpdateView>;
  abstract getConnetsAssignmentObservable(): Observable<ConnectAssignedView>;
  abstract getNewVacationRequestObservable(): Observable<boolean>;
  abstract getReportObservable(): Observable<ReportGeneratedView>;
  abstract getPatientEmrDetailsSyncStatusObservable(): Observable<PatientEmrDetailsSyncStatusView>;
  abstract getConnectEmrDetailsSyncStatusObservable(): Observable<ConnectEmrSyncStatus>;
  abstract getEmrFacilitySyncObservable(): Observable<EmrFacilitySyncView>;
  abstract getRoundingUpdatedObservable(): Observable<RoundingShortUpdateView>;
  abstract closeSession(): void;
  abstract getActiveTelemedicineInfo(): Observable<TelemedicineSession>;
}

@Injectable()
export class WebsocketServiceImpl extends WebsocketService {
  private session?: WebSocketSubject<WsMessage<unknown>>;

  private nurseDashboard$?: Observable<NurseDashboardUpdateView>;
  private connectAssignment$?: Observable<ConnectAssignedView>;
  private newVacationRequest$?: Observable<boolean>;
  private report$?: Observable<ReportGeneratedView>;
  private patientEmrDetailsSyncStatusView$?: Observable<PatientEmrDetailsSyncStatusView>;
  private connectEmrDetailsSyncStatusView$?: Observable<ConnectEmrSyncStatus>;
  private emrFacilitySync$?: Observable<EmrFacilitySyncView>;
  private roundingUpdated$?: Observable<RoundingUpdateView>;
  private pingSub?: Subscription;
  private telemedicineSession$?: Observable<TelemedicineSession>;

  private readonly websocketConfig: WebSocketSubjectConfig<WsMessage<unknown>>;

  constructor(private zone: NgZone) {
    super();
    const proto = window.location.protocol.startsWith('https') ? 'wss' : 'ws';
    this.websocketConfig = {
      url: `${proto}://${window.location.host}/ws`,
      openObserver: {
        next: () => {
          this._startPing();
        },
      },
      closeObserver: {
        next: (evt: CloseEvent) => {
          logger.info('WS close', evt);
          this._stopPing();
        },
      },
    };
  }

  getDashboardObservable(): Observable<NurseDashboardUpdateView> {
    if (this.nurseDashboard$ == undefined) {
      this.nurseDashboard$ = this._multiplex(
        WsResponseMessageType.DASHBOARD_STATUS_CHANGE
      ) as Observable<NurseDashboardUpdateView>;
    }

    return this.nurseDashboard$;
  }

  getActiveTelemedicineInfo(): Observable<TelemedicineSession> {
    if (this.telemedicineSession$ == undefined) {
      this.telemedicineSession$ = this._multiplex(
        WsResponseMessageType.TELEMEDICINE_STATE_UPDATED
      ) as Observable<TelemedicineSession>;
    }

    return this.telemedicineSession$;
  }

  public getConnetsAssignmentObservable(): Observable<ConnectAssignedView> {
    if (this.connectAssignment$ == undefined) {
      this.connectAssignment$ = this._multiplex(
        WsResponseMessageType.CONNECT_ASSIGNMENT
      ) as Observable<ConnectAssignedView>;
    }

    return this.connectAssignment$;
  }

  public getNewVacationRequestObservable(): Observable<boolean> {
    if (this.newVacationRequest$ == undefined) {
      this.newVacationRequest$ = this._multiplex(WsResponseMessageType.NEW_VACATION_REQUEST) as Observable<boolean>;
    }

    return this.newVacationRequest$;
  }

  public getReportObservable(): Observable<ReportGeneratedView> {
    if (this.report$ == undefined) {
      this.report$ = this._multiplex(WsResponseMessageType.REPORT_GENERATED) as Observable<ReportGeneratedView>;
    }

    return this.report$;
  }

  public getPatientEmrDetailsSyncStatusObservable(): Observable<PatientEmrDetailsSyncStatusView> {
    if (this.patientEmrDetailsSyncStatusView$ == undefined) {
      this.patientEmrDetailsSyncStatusView$ = this._multiplex(
        WsResponseMessageType.PATIENT_DETAILS_SYNCED
      ) as Observable<PatientEmrDetailsSyncStatusView>;
    }
    return this.patientEmrDetailsSyncStatusView$;
  }

  public getConnectEmrDetailsSyncStatusObservable(): Observable<ConnectEmrSyncStatus> {
    if (this.connectEmrDetailsSyncStatusView$ == undefined) {
      this.connectEmrDetailsSyncStatusView$ = this._multiplex(
        WsResponseMessageType.PATIENT_CONTACT_DETAILS_SYNCED
      ) as Observable<ConnectEmrSyncStatus>;
    }
    return this.connectEmrDetailsSyncStatusView$;
  }

  public getEmrFacilitySyncObservable(): Observable<EmrFacilitySyncView> {
    if (this.emrFacilitySync$ == undefined) {
      this.emrFacilitySync$ = this._multiplex(
        WsResponseMessageType.EMR_FACILITY_SYNCED
      ) as Observable<EmrFacilitySyncView>;
    }
    return this.emrFacilitySync$;
  }

  public getRoundingUpdatedObservable(): Observable<RoundingUpdateView> {
    if (this.roundingUpdated$ == undefined) {
      this.roundingUpdated$ = this._multiplex(WsResponseMessageType.ROUNDING_UPDATED).pipe(
        publish(),
        delayedRefCount()
      );
    }
    return this.roundingUpdated$;
  }

  private _multiplex(type: WsResponseMessageType): Observable<unknown> {
    return this.tryOpenConnection()
      .multiplex(
        () => ({
          type: WsRequestMessageType.Subscribe,
          payload: type,
        }),
        () => ({
          type: WsRequestMessageType.Unsubscribe,
          payload: type,
        }),
        message => message.type === type
      )
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
        map(value => value.payload),
        retryWhen(errors => errors.pipe(delay(RETRY_DELAY_MILLIS)))
      );
  }

  private _startPing(): void {
    this._stopPing();
    this.zone.runOutsideAngular(() => {
      this.pingSub = interval(PING_INTERVAL_MILLIS).subscribe(() =>
        this.session?.next({
          type: WsRequestMessageType.KeepALive,
        })
      );
    });
  }

  private _stopPing(): void {
    if (this.pingSub !== undefined) {
      this.pingSub.unsubscribe();
      this.pingSub = undefined;
    }
  }

  public closeSession(): void {
    this._stopPing();
    this.connectAssignment$ = undefined;
    this.report$ = undefined;
    this.nurseDashboard$ = undefined;
    this.newVacationRequest$ = undefined;
    this.patientEmrDetailsSyncStatusView$ = undefined;
    this.connectEmrDetailsSyncStatusView$ = undefined;
    this.emrFacilitySync$ = undefined;
    this.roundingUpdated$ = undefined;
    this.session?.complete();
    this.session = undefined;
    this.telemedicineSession$ = undefined;
  }

  private tryOpenConnection(): WebSocketSubject<WsMessage<unknown>> {
    if (this.session == undefined) {
      this.session = webSocket(this.websocketConfig);
    }
    return this.session;
  }
}

export class FakeWebsocketService extends WebsocketService {
  closeRoundingUpdatedObservable(): void {}

  closeSession(): void {}

  getConnectEmrDetailsSyncStatusObservable(): Observable<ConnectEmrSyncStatus> {
    return EMPTY;
  }

  getConnetsAssignmentObservable(): Observable<ConnectAssignedView> {
    return EMPTY;
  }

  getDashboardObservable(): Observable<NurseDashboardUpdateView> {
    return EMPTY;
  }

  getEmrFacilitySyncObservable(): Observable<EmrFacilitySyncView> {
    return EMPTY;
  }

  getNewVacationRequestObservable(): Observable<boolean> {
    return EMPTY;
  }

  getPatientEmrDetailsSyncStatusObservable(): Observable<PatientEmrDetailsSyncStatusView> {
    return EMPTY;
  }

  getReportObservable(): Observable<ReportGeneratedView> {
    return EMPTY;
  }

  getRoundingUpdatedObservable(): Observable<RoundingUpdateView> {
    return EMPTY;
  }

  getActiveTelemedicineInfo(): Observable<TelemedicineSession> {
    return EMPTY;
  }
}

export function websocketServiceFactory(platform: Platform, zone: NgZone): WebsocketService {
  return platform.isBrowser ? new WebsocketServiceImpl(zone) : new FakeWebsocketService();
}

export const WEBSOCKET_SERVICE_PROVIDER: Provider = {
  provide: WebsocketService,
  useFactory: websocketServiceFactory,
  deps: [Platform, NgZone],
};
