import { FacilityScheduleDayModel } from '@app/modules/admin/models/facility-schedule/facility-schedule-day.model';
import {
  FacilityScheduleIntervalModel,
  IIntervalElement,
} from '@app/modules/admin/models/facility-schedule/facility-schedule-interval.model';
import { FacilityScheduleShiftMode } from '@app/shell/constants/facility-schedule-shift-mode';
import { IFacilityRawInterval } from '@app/modules/admin/models/facility-schedule/facility-schedule-raw.model';
import { legacyDefined } from '@app/cdk/angular/utils/nullable.utils';

export interface IFacilityShift {
  dayOfWeek: { id: number; name: string };
  type: number;
  intervals: Array<IIntervalElement | IFacilityRawInterval | undefined>;
}

export class FacilityScheduleShiftModel implements IFacilityShift {
  dayOfWeek: FacilityScheduleDayModel;
  type: number = FacilityScheduleShiftMode.MODE_SELECTED_HOURS.id;
  intervals: Array<FacilityScheduleIntervalModel | undefined> = [];

  constructor(values: Partial<IFacilityShift> = {}) {
    this.dayOfWeek = new FacilityScheduleDayModel(values.dayOfWeek!);
    this.type = legacyDefined(values.type) ? values.type! : this.type;
    this.intervals =
      Array.isArray(values.intervals) && values.intervals.length > 0
        ? values.intervals.map(interval => new FacilityScheduleIntervalModel(interval))
        : [];
  }
}
